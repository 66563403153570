import { purple } from '@ant-design/colors'
import { Button, Flex } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import fimg from 'images/404/layer01.png'
import simg from 'images/404/layer02.png'
import timg from 'images/404/layer03.png'
import './NotFoundPage.scss'
import Title from 'antd/es/typography/Title'
import { HomeOutlined } from '@ant-design/icons'
import { Outlet, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const NotFoundPage: FC = () => {
  const navigate = useNavigate()
  const [x, setX] = useState<number>(0)
  const [y, setY] = useState<number>(0)
  
  function parallax(event?: { pageX: number; pageY: number; }) {
    if(event) {
      setX((window.innerWidth - event.pageX) / 90)
      setY((window.innerHeight - event.pageY) / 90)
    } else {
      setY((window.innerHeight - window.scrollY) / 90)
    }
  }

  useEffect(() => {
    document.addEventListener("mousemove", parallax)
    return () => {
        document.removeEventListener("mousemove", parallax)
      }
  }, [])
  return (
    <Flex
      style={{width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0, zIndex: 3000, background: `linear-gradient(315deg, ${purple[8]} 0%, #0c0f19 30%)`, padding: '0 10px'}}
      align='center'
      justify='center'
      vertical
    >
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <div className="parallax-wrap-404">
        <img src={simg} alt="" className='img-404' style={{transform: `translateX(${x * 0.2}px) translateY(${y * 0.2}px)`, zIndex: 20}}/>
        <img src={fimg} alt="" className='img-404' style={{transform: `translateX(${x * -0.2}px) translateY(${y * -0.2}px)`, zIndex: 10}}/>
        <img src={timg} alt="" className='img-404' style={{transform: `translateX(${x * -0.2}px) translateY(${y * -0.2}px)`, zIndex: 30}}/>
      </div>
      <Title style={{color: '#fff', textAlign: 'center'}} level={4}>Sorry! The page you're looking for cannot be found.</Title>
      <Button 
        size='large'
        type='primary'
        style={{width: 300, marginTop: 30}}
        onClick={() => navigate('/')}
      >
        <HomeOutlined /> Go to Homepage
      </Button>
      <Outlet />
    </Flex>
  )
}

export default NotFoundPage