const styles = {
  converterBox: {
    maxWidth: 500, 
    padding: '30px 10px'
  },
  inputContainer: {
    border: '1px solid #f0f0f0', 
    padding: 5, 
    borderRadius: 10, 
    minWidth: '100%',
    backgroundColor: '#fff'
  },
  caption: {
    fontSize: 12,
    marginLeft: 11,
    color: '#00000040',
  },
  input: {
    width: '100%',
    fontSize: 20
  },
  fullWidth: {width: '100%'}
}

export default styles