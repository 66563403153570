import React, { FC, useEffect, useState } from 'react'
import TransactionsPopupProps from './TransactionsPopupProps'
import { Alert, Modal, message } from 'antd'
import { transactionI } from 'types/types'
import { api } from 'utilits/api'
import TransactionsTable from 'components/Reusable/TransactionsTable/TransactionsTable'

const TransactionsPopup: FC<TransactionsPopupProps> = ({isOpen, userId, onClose}) => {
  const [transction, setTransactions] = useState<transactionI[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [errorApi, errorContext] = message.useMessage()
  const onError = (msg: string) => {
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }

  const getTransactions = () => {
    setIsLoading(true)
    api.getAllUserTransactions(userId)
      .then(res => setTransactions(res.transactions))
      .catch(err => onError(err.message))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    userId !== 0 && getTransactions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={[]}
      width='90%'
    >
      {errorContext}
      <TransactionsTable
        data={transction}
        loading={isLoading}
        admin
      />
    </Modal>
  )
}

export default TransactionsPopup