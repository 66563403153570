import { adminEaringI } from "types/adminTypes";

class Api {
  private _url: string
  private _headers: any;

  constructor(url: string) {
    this._url = url
    this._headers = {
      'Content-Type': 'application/JSON',
    }
  }

  private _getResponse(res: Response) {
    if (res.ok) {
      return res.json()
    }

    return res.text().then(text => {throw JSON.parse(text)})
  }

  //User Handles

  signUp(mail: string, password: string, passwordConfirn: string, inviteCode: string) {
    return fetch(`${this._url}/api/user/register`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({
        email: mail,
        password1: password,
        password2: passwordConfirn,
        invite_code: inviteCode
      })
    })
      .then(this._getResponse)
  }

  login(mail: string, password: string) {
    return fetch(`${this._url}/api/user/login`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({
        email: mail,
        password: password,
      })
    })
      .then(this._getResponse)
  }

  getUserAssets() {
    return fetch(`${this._url}/api/p/assets/list`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        balances: true
      })
    })
      .then(this._getResponse)
  }

  getUserInfo() {
    return fetch(`${this._url}/api/p/user/info`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({})
    })
      .then(this._getResponse)
  }

  getWalletForDeposit(assetId: number)  {
    return fetch(`${this._url}/api/p/user/wallet`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        asset_id: assetId 
      })
    })
      .then(this._getResponse)
  }

  getEarnings() {
    return fetch(`${this._url}/api/p/earnings/list`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({})
    })
      .then(this._getResponse)
  }

  subscribeToProduct(amount: number, earnId: number) {
    return fetch(`${this._url}/api/p/earnings/subscribe`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        amount: amount,
        earning_id: earnId
      })
    })
      .then(this._getResponse)
  }

  getTransactionsHistory() {
    return fetch(`${this._url}/api/p/transactions/list`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({})
    })
      .then(this._getResponse)
  }

  checkProduct(productId: number) {
    // РАЗКОМИТИТЬ
    // return fetch(`${this._url}/api/p/URL`, {
    //   method: 'POST',
    //   headers: {
    //     ...this._headers,
    //     'X-User-Token': localStorage.getItem('token')
    //   },
    //   body: JSON.stringify({
    //     earning_id: productId
    //   })
    // })
    // .then(this._getResponse)

    // УДОЛИТЬ НАХУЙ
    return new Promise((resolve, reject) => {setTimeout(() => {
      resolve(productId === 1000 ? 
        {
          progress: true,
          amount: 0.025
        } :
        {
          progress: false,
          amount: 0
        })
    }, 500)})
  }

  getWithdawInfo(assetId: number) {
    // РАЗКОМИТИТЬ
    // return fetch(`${this._url}/api/p/URL`, {
    //   method: 'POST',
    //   headers: {
    //     ...this._headers,
    //     'X-User-Token': localStorage.getItem('token')
    //   },
    //   body: JSON.stringify({
    //     asset_id: assetId
    //   })
    // })
    // .then(this._getResponse)

    // УДОЛИТЬ НАХУЙ
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          balance: 0.2,
          network_fee: 0.00001,
          withdrawal_min: 0.01,
          remaining_limit: 50
        })
      }, 500)
    })
  }
  
  createWithdraw(assetId: number, amount: number, address: string) {
    return fetch(`${this._url}/api/p/transactions/withdraw`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        asset_id: assetId,
        amount: amount,
        address: address
      })
    })
      .then(this._getResponse)
  }

  sendKYC(data: any) {
    return fetch(`${this._url}/api/p/user/kyc/submit`, {
      method: 'POST',
      // @ts-ignore: Unreachable code error
      headers: {
        'X-User-Token': localStorage.getItem('token')
      },
      body: data
    })
      .then(this._getResponse)
  }

  //Admins Handles

  getAdminAssets() {
    return fetch(`${this._url}/api/a/assets/list`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({})
    })
      .then(this._getResponse)
  }

  switchAsset(assetId: number, newState: boolean) {
    return fetch(`${this._url}/api/a/assets/activate`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        asset_id: assetId,
        active: newState
      })
    })
      .then(this._getResponse)
  }

  getInviteCodes() {
    return fetch(`${this._url}/api/a/invites/list`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({})
    })
      .then(this._getResponse)
  }

  createInviteCode(code?: string) {
    return fetch(`${this._url}/api/a/invites/create`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        ...(code && {code: code})
      })
    })
      .then(this._getResponse)
  }

  getSettings() {
    return fetch(`${this._url}/api/a/settings/list`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({})
    })
      .then(this._getResponse)
  }

  updateSettings(key: string, newValue: string) {
    return fetch(`${this._url}/api/a/settings/update`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        key: key,
        value: newValue
      })
    })
      .then(this._getResponse)
  }

  getUsers() {
    return fetch(`${this._url}/api/a/users/list`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({})
    })
      .then(this._getResponse)
  }

  switchUser(userId: number, newState: boolean) {
    return fetch(`${this._url}/api/a/users/activate`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        user_id: userId,
        active: newState
      })
    })
      .then(this._getResponse)
  }

  switchAdmin(userId: number, newState: boolean) {
    return fetch(`${this._url}/api/a/users/promote`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        user_id: userId,
        admin: newState
      })
    })
      .then(this._getResponse)
  }

  switchPremium(userId: number, newState: boolean) {
    return fetch(`${this._url}/api/a/users/premium/update`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        user_id: userId,
        premium: newState
      })
    })
      .then(this._getResponse)
  }

  changeUserLevel(userId: number, newLevel: number) {
    return fetch(`${this._url}/api/a/users/level/update`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        user_id: userId,
        level: newLevel
      })
    })
      .then(this._getResponse)
  }

  createTransaction(assetId: number, state: string, type: string, amount: number, userId: number, comment?: string) {
    return fetch(`${this._url}/api/a/transactions/create`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        "asset_id": assetId,
        "amount": amount,
        "state": state,
        "user_id": userId,
        "type": type,
        "comment": comment || ''
      })
    })
      .then(this._getResponse)
  }

  changeUserKYC(userId: number, newKYS: string, comment: string) {
    return fetch(`${this._url}/api/a/users/kyc/update`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        user_id: userId,
        state: newKYS,
        comment: comment
      })
    })
      .then(this._getResponse)
  }

  getAllUserTransactions(userId: number) {
    return fetch(`${this._url}/api/a/transactions/list`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        user_id: userId
      })
    })
      .then(this._getResponse)
  }

  getAdminEarnings() {
    return fetch(`${this._url}/api/a/earnings/list`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({})
    })
      .then(this._getResponse)
  }

  createEarning(editedEarning: adminEaringI) {
    const { asset_id, duration_type, apr_min, level, apr_max, duration, amount_max, name, amount_min } = editedEarning
    return fetch(`${this._url}/api/a/earnings/create`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        asset_id: asset_id,
        duration_type: duration_type,
        apr_min: apr_min,
        level: level,
        apr_max: apr_max,
        duration: duration,
        amount_max: amount_max,
        name: name,
        amount_min: amount_min
      })
    })
      .then(this._getResponse)
  }

  editEarning(editedEarning: adminEaringI) {
    const { id, duration_type, apr_min, level, apr_max, duration, amount_max, name, amount_min } = editedEarning
    return fetch(`${this._url}/api/a/earnings/update`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        earning_id: id,
        duration_type: duration_type,
        apr_min: apr_min,
        level: level,
        apr_max: apr_max,
        duration: duration,
        amount_max: amount_max,
        name: name,
        amount_min: amount_min
      })
    })
      .then(this._getResponse)
  }

  getAllWithdraws() {
    return fetch(`${this._url}/api/a/transactions/list/withdrawals`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({})
    })
      .then(this._getResponse)
  }

  changeTransactionStatus(id: number, state: string, comment: string) {
    return fetch(`${this._url}/api/a/transactions/update`, {
      method: 'POST',
      headers: {
        ...this._headers,
        'X-User-Token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        id: id,
        state: state,
        comment: comment
      })
    })
      .then(this._getResponse)
  }
}

const api = new Api(window.env.APP_API_ENDPOINT)

export { api }