import React, { useEffect, useRef, memo } from 'react';

function TradingViewWidget() {
    const container = useRef<any>();

    useEffect(
    () => {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
        {
          "feedMode": "all_symbols",
          "isTransparent": true,
          "displayMode": "regular",
          "width": "100%",
          "height": "600",
          "colorTheme": "light",
          "locale": "en"
        }`;
        container.current.appendChild(script);
    },
    []
    );

    return (
    <div className="tradingview-widget-container" ref={container}>
        <div className="tradingview-widget-container__widget"></div>
    </div>
    );
}

export default memo(TradingViewWidget);