import { Alert, Flex, Input, Modal, Radio, Select, Typography } from 'antd'
import React, { FC, useEffect, useRef, useState } from 'react'
import EditPopupProps from './EditPopupProps'
import Title from 'antd/es/typography/Title'
import { adminEaringI } from 'types/adminTypes'
import { api } from 'utilits/api'
import { DownOutlined } from '@ant-design/icons'
import { useOutsideClick } from '@chakra-ui/react'
import { useUserContext } from 'Context/UserContext'
import { getCryptoIcon } from 'utilits/getCryptoIcon'
import { userAssetI } from 'types/types'
const { Text } = Typography

const EditPopup: FC<EditPopupProps> = ({isOpen, earning, onClose, onSuccess}) => {
  const { user } = useUserContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  
  const currencyRef = useRef<any>()
  const [currency, setCurrency] = useState<number>(0)
  const [isCurrencySelectOpen, setIsCurrencySelectOpen] = useState<boolean>(false)
  const [currencyOption, setCurrencyOptions] = useState<{value: number, label: JSX.Element}[]>([])

  const [name, setName] = useState<string>('')
  const [aprMin, setAprMin] = useState<string>('')
  const [aprMax, setAprMax] = useState<string>('')
  const [amountMin, setAmountMin] = useState<string>('')
  const [amountMax, setAmountMax] = useState<string>('')
  const [durationType, setDurationType] = useState<'Fixed'|'Flexible'>('Fixed')
  const [duration, setDuration] = useState<string>('')
  const [level, setLevel] = useState<1|2|3>(1)

  const handleAprMinChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value
    if(!Number.isNaN(Number(value)) || target.value === ',') {
      setAprMin(value)
    }
  }
  const handleAprMaxChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value
    if(!Number.isNaN(Number(value)) || target.value === ',') {
      setAprMax(value)
    }
  }
  const handleAmountMinChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value
    if(!Number.isNaN(Number(value)) || target.value === ',') {
      setAmountMin(value)
    }
  }
  const handleAmountMaxChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value
    if(!Number.isNaN(Number(value)) || target.value === ',') {
      setAmountMax(value)
    }
  }
  const handleDurationChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value
    if(!Number.isNaN(Number(value))) {
      setDuration(value)
    }
  }
  
  const handleEdit = () => {
    if(earning) {
      const editedObj: adminEaringI = {
        ...earning,
        name: name,
        apr_min: Number(aprMin),
        apr_max: Number(aprMax),
        amount_min: Number(amountMin),
        amount_max: Number(amountMax),
        duration_type: durationType,
        duration: Number(duration),
        level: level
      }
      if(JSON.stringify(editedObj) !== JSON.stringify(earning)) {
        setIsLoading(true)
        api.editEarning(editedObj)
          .then(onSuccess)
          .then(() => setError(''))
          .then(onClose)
          .catch(err => setError(err.message))
          .finally(() => setIsLoading(false))
      }
    }
  }

  const handleCreate = () => {
    if(currency !== 0) {
      const newObj: adminEaringI = {
        active: false,
        id: 0,
        created_at: '',
        asset_name: '',
        asset_ticker: '',

        asset_id: currency,
        name: name,
        apr_min: Number(aprMin),
        apr_max: Number(aprMax),
        amount_min: Number(amountMin),
        amount_max: Number(amountMax),
        duration_type: durationType,
        duration: Number(duration),
        level: level
      }
      setIsLoading(true)
      api.createEarning(newObj)
        .then(onSuccess)
        .then(() => setError(''))
        .then(onClose)
        .catch(err => setError(err.message))
        .finally(() => setIsLoading(false))
    } else {
      setError('Invalid asset')
    }
  }

  const proccessCurrencyOptions = (assets: userAssetI[]): {value: number, label: JSX.Element}[] => {
    const filteredAssets = assets.filter(a => a.depositable)
    const assetsOptions = filteredAssets.map(a => {
      return {
        value: a.id,
        ticker: a.ticker,
        label: 
          <Flex gap={5}>
            <img src={getCryptoIcon(a.ticker)} alt={a.ticker} style={{width: 20, height: 20}}/>
            <Text strong>{a.ticker}</Text>
            <Text>{a.name}</Text>
          </Flex>
      }
    })
    return assetsOptions
  }

  useOutsideClick({
    ref: currencyRef,
    handler: () => setIsCurrencySelectOpen(false)
  })

  useEffect(() => {
    if(isOpen && earning) {
      setName(earning.name)
      setAprMin(String(earning.apr_min))
      setAprMax(String(earning.apr_max))
      setAmountMin(String(earning.amount_min))
      setAmountMax(String(earning.amount_max))
      setDurationType(earning.duration_type)
      setDuration(String(earning.duration))
      setLevel(earning.level)
    } else {
      setCurrency(0)
      setName('')
      setAprMin('')
      setAprMax('')
      setAmountMin('')
      setAmountMax('')
      setDurationType('Flexible')
      setDuration('')
      setLevel(1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    user?.assets && setCurrencyOptions(proccessCurrencyOptions(user.assets))
  }, [user])
  return (
    <Modal
      title={
        <Flex align='baseline' gap={10}>
          <Title level={3}>{earning === null ? 'Create Product' : 'Edit Earning'}</Title>
          <Text type='secondary'>{earning?.id}</Text>
        </Flex>
      }
      open={isOpen}
      onCancel={onClose}
      onOk={earning === null ? handleCreate : handleEdit}
      okButtonProps={{disabled: isLoading, loading: isLoading}}
    >
      <Flex
        vertical
        gap={20}
        style={{marginTop: 20}}
      >
        {
          earning === null &&
            <Flex gap={10} style={{height: 30}} align='center'>
              <Text strong style={{minWidth: 100}}>Asset: </Text>
              <Select
                placeholder='Select asset'
                style={{width: '100%', maxWidth: 500}}
                value={currency === 0 ? undefined : currency}
                onChange={setCurrency}
                options={currencyOption}
                onClick={() => setIsCurrencySelectOpen(state => !state)}
                open={isCurrencySelectOpen}
                suffixIcon={
                  <DownOutlined className={`select-icon ${isCurrencySelectOpen && 'select-icon_turned'}`}/>
                }
                dropdownRender={menu => (
                  <Flex
                    ref={currencyRef}
                    vertical
                    gap={5}
                  >
                    {menu}
                  </Flex>
                )}
              />
            </Flex>
        }
        <Flex gap={10} style={{height: 30}} align='center'>
          <Text strong style={{minWidth: 100}}>Name: </Text>
          <Input
            placeholder='Staking'
            value={name}
            onChange={({target}) => setName(target.value)}
          />
        </Flex>
        <Flex gap={10} style={{height: 30}} align='center'>
          <Text strong style={{minWidth: 100}}>APR: </Text>
          <Input
            placeholder='0.01'
            value={aprMin}
            onChange={handleAprMinChange}
          />
          -
          <Input
            placeholder='0.05'
            value={aprMax}
            onChange={handleAprMaxChange}
          />
        </Flex>
        <Flex gap={10} style={{height: 30}} align='center'>
          <Text strong style={{minWidth: 100}}>Amount: </Text>
          <Input
            placeholder='0.01'
            value={amountMin}
            onChange={handleAmountMinChange}
          />
          -
          <Input
            placeholder='0.05'
            value={amountMax}
            onChange={handleAmountMaxChange}
          />
        </Flex>
        <Flex gap={10} style={{height: 30}} align='center'>
          <Text strong style={{minWidth: 100}}>Duration: </Text>
          <Radio.Group
            value={durationType}
            onChange={({target}) => setDurationType(target.value)}
          >
            <Radio.Button value='Flexible'>Flexible</Radio.Button>
            <Radio.Button value='Fixed'>Fixed</Radio.Button>
          </Radio.Group>
          <Input
            placeholder='11000'
            style={{maxWidth: 200}}
            value={duration}
            onChange={handleDurationChange}
          />
        </Flex>
        <Flex gap={10} style={{height: 30}} align='center'>
          <Text strong style={{minWidth: 100}}>Level: </Text>
          <Radio.Group 
            value={level}
            onChange={({target}) => setLevel(target.value)}
          >
            <Radio.Button value={1}>1</Radio.Button>
            <Radio.Button value={2}>2</Radio.Button>
            <Radio.Button value={3}>3</Radio.Button>
          </Radio.Group>
        </Flex>
        {
          error && <Alert message={error} type='error' />
        }
      </Flex>
    </Modal>
  )
}

export default EditPopup