import { MenuOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Drawer, Flex } from 'antd'
import { Header } from 'antd/es/layout/layout'
import Title from 'antd/es/typography/Title'
import React, { FC, useState } from 'react'
import Menu from './Menu/Menu'
import { useNavigate } from 'react-router-dom'
import './Header.scss'
import MobileMenu from './Menu/MobileMenu'
import { useDisplayWidth } from 'utilits/useDisplayWidth'

const MyHeader: FC = () => {
  const navigate = useNavigate()
  const displayWidth = useDisplayWidth()
  
  const [isMenuOpen, setIsMenuopen] = useState<boolean>(false)
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorLink: '#fff',
            fontSize: 16,
            borderRadius: 5,
            primaryShadow: 'none',
          },
          Layout: {
            headerBg: '#0c0f19'
          },
          Drawer: {
            colorBgElevated: '#0c0f19'
          },
          Collapse: {
            headerPadding: 0
          }
        }
      }}
    >
      <Header
        style={{
          width: '100%',
          minHeight: 75,
          padding: displayWidth > 800 ? undefined : '0 15px',
          position: 'sticky', 
          top: 0, 
          zIndex: 100
        }}
      >
        <Flex 
          align='center' 
          justify='space-between' 
          style={{height: '100%', maxWidth: 1400, margin: '0 auto'}} 
          gap={40}
        >
          <Title 
            level={3} 
            style={{color: '#fff', minWidth: 'max-content'}}
            onClick={() => navigate('/')}
            className='header__title'
          >
            {window.env.APP_TITLE}
          </Title>
          {
            displayWidth > 1000 ?
              <Menu/> : 
              <>
                <Button type='link' style={{padding: 0}}>
                  <MenuOutlined onClick={() => setIsMenuopen(true)}/>
                </Button>
                <Drawer
                  open={isMenuOpen}
                  onClose={() => setIsMenuopen(false)}
                  closable={false}
                  width={350}
                >
                  <MobileMenu 
                    onClose={() => setIsMenuopen(false)}
                  />
                </Drawer>
              </>
          }
        </Flex>
      </Header>
    </ConfigProvider>
  )
}

export default MyHeader