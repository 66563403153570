import { Typography, Flex } from "antd"
import { userAssetI } from "types/types"
import { getCryptoIcon } from "./getCryptoIcon"

const {Text} = Typography

const proccessCurrencyOptions = (assets: userAssetI[]): {value: number, ticker: string, label: JSX.Element}[] => {
  const filteredAssets = assets.filter(a => a.depositable)
  const assetsOptions = filteredAssets.map(a => {
    return {
      value: a.id,
      ticker: a.ticker,
      label: 
        <Flex gap={5}>
          <img src={getCryptoIcon(a.ticker)} alt={a.ticker} style={{width: 25, height: 25}}/>
          <Text strong style={{fontSize: 17}}>{a.ticker}</Text>
          <Text style={{fontSize: 17}}>{a.name}</Text>
        </Flex>
    }
  })
  return assetsOptions
}

export {
  proccessCurrencyOptions
}