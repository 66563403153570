
import { CollapseProps, Flex } from 'antd'
import { getCryptoIcon } from 'utilits/getCryptoIcon'
import country from 'country-list-js'
import Title from 'antd/es/typography/Title'
import getCountryFlag from 'country-flag-icons/unicode'
import i18n from 'translations/i18n'

const mailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/

const emptyBalanceRegex = /(0\.0+)$/gm

const cryptoCurrencies = [
  {
    value: 'BTC',
    label: <Flex align='center' gap={5}><img src={getCryptoIcon('BTC')} alt='' style={{width: 20}}/>BTC</Flex>
  },
  {
    value: 'ETH',
    label: <Flex align='center' gap={5}><img src={getCryptoIcon('ETH')} alt='' style={{width: 20}}/>ETH</Flex>
  },
  {
    value: 'BNB',
    label: <Flex align='center' gap={5}><img src={getCryptoIcon('BNB')} alt='' style={{width: 20}}/>BNB</Flex>
  },
  {
    value: 'DOGE',
    label: <Flex align='center' gap={5}><img src={getCryptoIcon('DOGE')} alt='' style={{width: 20}}/>DOGE</Flex>
  },
  {
    value: 'ADA',
    label: <Flex align='center' gap={5}><img src={getCryptoIcon('ADA')} alt='' style={{width: 20}}/>ADA</Flex>
  },
  {
    value: 'AVAX',
    label: <Flex align='center' gap={5}><img src={getCryptoIcon('AVAX')} alt='' style={{width: 20}}/>AVAX</Flex>
  },
  {
    value: 'DOT',
    label: <Flex align='center' gap={5}><img src={getCryptoIcon('DOT')} alt='' style={{width: 20}}/>DOT</Flex>
  },
  {
    value: 'LINK',
    label: <Flex align='center' gap={5}><img src={getCryptoIcon('LINK')} alt='' style={{width: 20}}/>LINK</Flex>
  },
  {
    value: 'SHIB',
    label: <Flex align='center' gap={5}><img src={getCryptoIcon('SHIB')} alt='' style={{width: 20}}/>SHIB</Flex>
  },
  {
    value: 'SOL',
    label: <Flex align='center' gap={5}><img src={getCryptoIcon('SOL')} alt='' style={{width: 20}}/>SOL</Flex>
  },
  // {
  //   value: 'TON',
  //   label: <Flex align='center' gap={5}><img src={getCryptoIcon('TON')} alt='' style={{width: 20}}/>TON</Flex>
  // },
  {
    value: 'XRP',
    label: <Flex align='center' gap={5}><img src={getCryptoIcon('XRP')} alt='' style={{width: 20}}/>XPR</Flex>
  },
]

const documentsOptions = [
  {
    label: 'Driver License',
    value: 'DriverLicense'
  },
  {
    label: 'ID Card',
    value: 'IDCard'
  },
  {
    label: 'Passport',
    value: 'Passport'
  }
]

const countryOptions = country.names().map(c => {
  const cc = country.findByName(c).code.iso2
  return { label: getCountryFlag(cc) + ' ' + c, value: c}
}).filter(c => c.value !== 'North Korea')

const panelStyle: React.CSSProperties = {
  marginBottom: 10,
  background: 'transparent',
  borderRadius: 5,
  border: 'none',
  padding: '5px 20px',
  boxShadow: '0px 0px 20px -1px rgba(0,0,0,0.1)',
}

const FAQText: CollapseProps['items'] = [
  {
    key: '1',
    label: <Title level={5}>{i18n.t('FAQ.0.title')}</Title>,
    children: <p style={{padding: 6}}>{i18n.t('FAQ.0.text')}</p>,
    style: panelStyle
  }, 
  {
    key: '2',
    label: <Title level={5}>{i18n.t('FAQ.1.title')}</Title>,
    children: <p style={{padding: 6}}>{i18n.t('FAQ.1.text')}</p>,
    style: panelStyle
  }, 
  {
    key: '3',
    label: <Title level={5}>{i18n.t('FAQ.2.title')}</Title>,
    children: <p style={{padding: 6}}>{i18n.t('FAQ.2.text')}</p>,
    style: panelStyle
  }
]

export {
  mailRegex,
  emptyBalanceRegex,
  cryptoCurrencies,
  documentsOptions,
  countryOptions,
  FAQText
}