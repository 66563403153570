import { createContext, useContext } from "react";
import { userI } from "types/types";

const UserContext = createContext<{user: userI|null, changeUser: ((user: userI|null) => void)|undefined}>({
  user: null,
  changeUser: undefined
})

const useUserContext = () => useContext(UserContext)

export { UserContext, useUserContext }