import { Button, ConfigProvider, Flex, Input } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { FC, useState } from 'react'
import Footer from 'components/Reusable/Footer/Footer'
import { Outlet } from 'react-router-dom'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { Helmet } from 'react-helmet'
import PageHeading from 'components/Reusable/PageHeading/PageHeading'
import { useTranslation } from 'react-i18next'

const Secutity: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'settings'})
  const displayWidth = useDisplayWidth()
  
  const [password, setPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorBgContainer: '#eff2fc',
            defaultHoverBg: '#d9e2ff',
            defaultHoverColor: '#000'
          }
        }
      }}
    >
      <Helmet>
        <title>{t('tab_title')}</title>
      </Helmet>
      <PageHeading title={t('title')} />
      <Flex vertical gap={30} style={{width: '100%', maxWidth: 1500 , padding: displayWidth  > 800 ? '30px 50px' : '20px 20px'}}>
        <Title level={1}>{t('security')}</Title>
        <Title level={4} style={{color: '#4044ee'}}>{t('password')}</Title>
        <Flex wrap='wrap' gap={30} >
          <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
            <Title level={5}>{t('password')}</Title>
            <Input.Password 
              size='large' 
              placeholder={t('password')}
              value={password}
              onChange={({target}) => setPassword(target.value)}
            />
          </Flex>
        </Flex>
        <Flex wrap='wrap' gap={30} >
          <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
            <Title level={5}>{t('new_password')}</Title>
            <Input.Password 
              size='large' 
              placeholder={t('new_password')}
              value={newPassword}
              onChange={({target}) => setNewPassword(target.value)}
            />
          </Flex>
          <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
            <Title level={5}>{t('confirm_passord')}</Title>
            <Input.Password 
              size='large' 
              placeholder={t('repeat_password')}
              value={passwordConfirm}
              onChange={({target}) => setPasswordConfirm(target.value)}
            />
          </Flex>
        </Flex>
        <Flex gap={10}>
          <Button size='large' style={{border: 'none', minHeight: 50, padding: '0 30px'}}>{t('cancel')}</Button>
          <Button size='large' style={{minHeight: 50, padding: '0 30px'}} type='primary'>{t('save')}</Button>
        </Flex>
        {/* <Divider/>
        <Flex vertical>
          <Title level={4} style={{color: '#4044ee'}}>Your sessions</Title>
          <Title level={5} type='secondary'>This is a list of devices that have logged into your account. Remove any sessions that you do not recognize.</Title>
        </Flex>
        <Flex vertical gap={20} style={{marginBottom: 100}}>
          {
            devices.map(d => <Device key={d.name} device={d}/>)
          }
        </Flex> */}
      </Flex>
      <Footer/>
      <Outlet />
    </ConfigProvider>
  )
}

export default Secutity