import { Alert, Table, message } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { FC, useEffect, useState } from 'react'
import EditSetsPopup from './EditSetsPopup/EditSetsPopup'
import { adminSettingsI } from 'types/adminTypes'
import { api } from 'utilits/api'
import AdminWrapper from '../AdminWrapper/AdminWrapper'
import { getSettingsColumns } from './SettignsServe'

const Settings: FC = () => {
  const [settings, setSettings] = useState<adminSettingsI[]>([])
  const [selectedSets, setSelectedSets] = useState<adminSettingsI|null>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  const [errorApi, errorContext] = message.useMessage()
  const onError = (msg: string) => {
    setIsLoading(false)
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }

  const changeSettings = (changedSettings: adminSettingsI) => {
    const {key, value} = changedSettings
    setIsLoading(true)
    api.updateSettings(key, value)
      .then(getSettings)
      .then(() => setSelectedSets(null))
      .catch(err => onError(err.message))
  }

  const getSettings = () => {
    setIsLoading(true)
    api.getSettings()
      .then((res: {settings: adminSettingsI[]}) => {
        setSettings(res.settings)
        setIsLoading(false)
      })
      .catch(err => onError(err.message))
  }

  const settingsColumns = getSettingsColumns(setSelectedSets)

  useEffect(() => {
    getSettings()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <AdminWrapper>
      {errorContext}
      <Title level={3}>Settings</Title>
      <Table
        size='small'
        loading={isLoading}
        columns={settingsColumns}
        pagination={false}
        dataSource={settings}
        rowKey={r => r.key}
      />
      <EditSetsPopup
        isOpen={selectedSets !== null}
        onClose={() => setSelectedSets(null)}
        settings={selectedSets}
        onEdit={changeSettings}
        loading={isLoading}
      />
    </AdminWrapper>
  )
}

export default Settings