import React, { FC, useEffect, useState } from 'react'
import './Opportunities.scss'
import { Divider, Flex, Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import avatars from 'images/avatars.png'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { useTranslation } from 'react-i18next'
const { Text } = Typography

const Opportunities: FC = () => {
  const {t} = useTranslation('translation' ,{keyPrefix: 'landing.opportunities'})
  const displayWidth = useDisplayWidth()
  const [x, setX] = useState<number>(0)
  const [y, setY] = useState<number>(0)

  function parallax(event?: { pageX: number; pageY: number; }) {
    if (event) {
      setX((window.innerWidth - event.pageX) / 90)
      setY((window.innerHeight - event.pageY) / 90)
    } else {
      setY((window.innerHeight - window.scrollY) / 90)

    }
  }

  useEffect(() => {
    document.addEventListener("mousemove", parallax)
    document.addEventListener("scroll", () => parallax())
    return () => {
      document.removeEventListener("scroll", () => parallax())
      window.removeEventListener('mousemove', () => parallax)
    }
  }, [])
  return (
    <Flex
      gap={20}
      align='center'
      justify='center'
      style={{ padding: displayWidth > 1200 ? '50px 50px 0' : '30px 10px 0' }}
      vertical={displayWidth < 800}
    >
      <Flex
        vertical
        gap={30}
        align='flex-start'
        style={{ width: displayWidth > 800 ? '50%' : '100%', padding: displayWidth > 800 ? 50 : 10, maxWidth: 700 }}
      >
        <Title level={2} style={{ fontWeight: 800 }}>
          {t('title', {app_name: window.env.APP_TITLE})}
        </Title>
        <Text type='secondary'>
          {t('caption', {app_name: window.env.APP_TITLE})}
        </Text>
        <Divider />
        <Flex
          justify={displayWidth > 800 ? 'space-between' : 'space-evenly'}
          style={{ width: '100%' }}
        >
          <Flex
            vertical
            gap={20}
          >
            <Title level={1}>23%</Title>
            <Text type='secondary' style={{ fontSize: 16 }}>{t('percent')}</Text>
          </Flex>
          <Flex
            vertical
            gap={20}
          >
            <Title level={1}>132b</Title>
            <Text type='secondary' style={{ fontSize: 16 }}>{t('coins')}</Text>
          </Flex>
        </Flex>
      </Flex>
      <div className="parallax-wrap-op">
        <div className="circle circle_outer" style={{ transform: `translateX(${x * 1}px) translateY(${y * 1}px)`, zIndex: 10 }} />
        <div className="circle circle_fst-inner" style={{ transform: `translateX(${x * 1.5}px) translateY(${y * 1.5}px)`, zIndex: 20 }} />
        <div className="circle circle_snd-inner" style={{ transform: `translateX(${x * 2}px) translateY(${y * 2}px)`, zIndex: 30 }} />
        <div className="circle circle_center" style={{ transform: `translateX(${x * 2.2}px) translateY(${y * 2.2}px)`, zIndex: 40 }}>
          400k+
        </div>
        <img src={avatars} alt="" className='imgO' style={{ transform: `translateX(${x * -0.5}px) translateY(${y * -0.5}px)`, zIndex: 50 }} />
      </div>
    </Flex>
  )
}

export default Opportunities