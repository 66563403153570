import React, { FC } from 'react'
import PageHeadingProps from './PageHeadingProps'
import { Flex } from 'antd'
import Title from 'antd/es/typography/Title'
import { useDisplayWidth } from 'utilits/useDisplayWidth'

const PageHeading: FC<PageHeadingProps> = ({title}) => {
  const displayWidth = useDisplayWidth()
  return (
    <Flex style={{width: '100%', background: '#0c0f19'}} justify='center'>
      <Title style={{color: '#fff', maxWidth: 1500, width: '100%', padding: displayWidth > 800 ? 50 : '30px 20px'}}>{title}</Title>
    </Flex>
  )
}

export default PageHeading