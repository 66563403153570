const getDateDiff = (spawnDate: string) => {
  const now = new Date();
  const spawn = new Date(spawnDate);
  const diff = new Date(now.getTime() - spawn.getTime())
  let seconds = Math.floor(diff.getTime() / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24)

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24
  return `${days > 0 ? `${days}d ` : ''}${hours > 0 ? `${hours}h ` : ''}${minutes}min ago`
}

export { getDateDiff }