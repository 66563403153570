import { Button, Flex, TableColumnsType, Typography } from "antd"
import { adminWithdrawsI } from "types/adminTypes"
import { displayUSDPrice } from "utilits/displayUSDPrice"
import { getCryptoIcon } from "utilits/getCryptoIcon"
import { getTransactionStatus } from "utilits/tagsColorFunctions"
const { Text } = Typography

const getWithdrawsColumns = (onStatePopup: (id: number, state: string) => void): TableColumnsType<adminWithdrawsI> => [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
    align: 'center'
  },
  {
    title: 'User ID',
    key: 'user_id',
    dataIndex: 'user_id',
    align: 'center'
  },
  {
    title: 'Wallet ID',
    key: 'wallet_id',
    dataIndex: 'wallet_id',
    align: 'center'
  },
  {
    title: 'Crypto',
    key: 'asset',
    width: 120,
    render: (_, {asset_name, asset_ticker}) => 
      <Flex gap={5} align='center'>
        <img src={getCryptoIcon(asset_ticker)} alt={asset_ticker} width={30} height={30}/>
        <Flex vertical>
          <Text strong>{asset_ticker}</Text>
          <Text type='secondary'>{asset_name}</Text>
        </Flex>
      </Flex>
  },
  {
    title: 'Amount',
    key: 'amount',
    align: 'end',
    width: 140,
    render: (_, {amount, price_usdt, asset_ticker}) => 
      <Flex vertical align='end'>
        <Text strong>{amount.toFixed(7)} {asset_ticker}</Text>
        <Text type='secondary'>{displayUSDPrice(amount * (price_usdt || 0))}</Text>
      </Flex>
  },
  {
    title: 'Address',
    key: 'address',
    dataIndex: 'comment',
  },
  {
    title: 'Time',
    key: 'time',
    render: (_, {created_at}) => 
      <Text>
        {new Date(created_at).toLocaleString(undefined, {timeZone: 'UTC'})}
      </Text>
  },
  {
    title: 'State',
    key: 'state',
    render: (_, {id, state}) => 
      <Button type='link' style={{padding: 0}} onClick={() => onStatePopup(id, state)}>
        { getTransactionStatus(state) }
      </Button>,
  },
]

export {
  getWithdrawsColumns
}