const getPriceFunc = async (currency: string, loadingHandler:(state: boolean) => void) => {
  loadingHandler(true)
  return await fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${currency}USDT`)
    .then(res => {
      if(res.ok) {
        loadingHandler(false)
        return res.json()
      }

      return res.text().then(text => {throw JSON.parse(text)})
    })
      .then(res => res.price)
}

export {
  getPriceFunc
}