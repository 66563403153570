import { Flex, Typography } from "antd"
import { userAssetI } from "types/types"
import { getCryptoIcon } from "utilits/getCryptoIcon"
const { Text } = Typography

const stateOption = [
  {
    value: 'Pending',
    lable: 'Pending'
  },
  {
    value: 'Success',
    lable: 'Success'
  },
  {
    value: 'Cancelled',
    lable: 'Cancelled'
  },
  {
    value: 'Frozen',
    lable: 'Frozen'
  },
  {
    value: 'Failed',
    lable: 'Failed'
  },
]

const typeOption = [
  {
    value: 'Deposit',
    lable: 'Deposit'
  },
  {
    value: 'Withdraw',
    lable: 'Withdraw'
  },
  {
    value: 'Promocode',
    lable: 'Promocode'
  },
  {
    value: 'Earning',
    lable: 'Earning'
  },
  {
    value: 'Transfer',
    lable: 'Transfer'
  },
]

const proccessCurrencyOptions = (assets: userAssetI[]): {value: number, label: JSX.Element}[] => {
  const filteredAssets = assets.filter(a => a.depositable)
  const assetsOptions = filteredAssets.map(a => {
    return {
      value: a.id,
      label: 
        <Flex gap={5}>
          <img src={getCryptoIcon(a.ticker)} alt={a.ticker} style={{width: 25, height: 25}}/>
          <Text strong style={{fontSize: 17}}>{a.ticker}</Text>
        </Flex>
    }
  })
  return assetsOptions
}

export {
  stateOption,
  typeOption,
   proccessCurrencyOptions
}