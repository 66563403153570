export const styles = {
  title: {
    maxWidth: 600,
    fontWeight: 900
  },
  captionBox: {
    width: '100%'
  },
  caption: {
    text: {
      margin: 0,
    },
    icon: {
      color: '#6663f1',
      fontWeight: 100
    },
    stong: {
      color: '#6663f1',
      fontWeight: 900
    }
  }
}