import { DownOutlined, LogoutOutlined } from '@ant-design/icons'
import { Flex, Button, Collapse } from 'antd'
import React, { FC, useEffect } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useUserContext } from 'Context/UserContext'
import { useTranslation } from 'react-i18next'
import LangsSelect from './LangsSelect'

const MobileMenu: FC<{onClose:() => void}> = ({onClose}) => {
  const {t} = useTranslation('translation', {keyPrefix: 'header'})
  const langs = window.env.APP_LANGS.split(',')
  const user = useUserContext()
  const navigate = useNavigate()
  const location = useLocation()

  const handleLogOut = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('isBalanceShown')
    user.changeUser && user.changeUser(null)
    navigate('/')
  }

  useEffect(() => {
    onClose()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
  return user.user ?
    <Flex
      vertical
      align='start'
      style={{height: '100%'}}
    >
      <Link to='/'>
        <Button 
          size='large'
          type='link'
          style={{
            ...(location.pathname === '/' && { color: '#6663f1' })
          }}
        >
          {t('home')}
        </Button>
      </Link>
      <Link to='/events'>
        <Button 
          size='large'
          type='link'
          style={{
            ...(location.pathname === '/events' && { color: '#6663f1' })
          }}
        >
          {t('events')}
        </Button>
      </Link>
      <Link to='/market-news'>
        <Button 
          size='large'
          type='link'
          style={{
            ...(location.pathname === '/market-news' && { color: '#6663f1' })
          }}
        >
          {t('market_news')}
        </Button>
      </Link>
      <Link to='/market-heatmap'>
        <Button 
          size='large'
          type='link'
          style={{
            ...(location.pathname === '/market-heatmap' && { color: '#6663f1' })
          }}
        >
          {t('market_heatmap')}
        </Button>
      </Link>
      <Link to='/conventor'>
        <Button 
          size='large'
          type='link'
          style={{
            ...(location.pathname === '/conventor' && { color: '#6663f1' })
          }}
        >
          {t('conventor')}
        </Button>
      </Link>
      <Link to='/earn'>
        <Button 
          size='large'
          type='link'
          style={{
            ...(location.pathname === '/earn' && { color: '#6663f1' })
          }}
        >
          {t('earn')}
        </Button>
      </Link>
      <Link to='/account'>
        <Button 
          size='large'
          type='link'
          style={{
            ...(location.pathname === '/account' && { color: '#6663f1' })
          }}
        >
          {t('account')}
        </Button>
      </Link>
      <Link to='/assets'>
        <Button 
          size='large'
          type='link'
          style={{
            ...(location.pathname === '/assets' && { color: '#6663f1' })
          }}
        >
          {t('assets')}
        </Button>
      </Link>
      <Link to='/deposit'>
        <Button 
          size='large'
          type='link'
          style={{
            ...(location.pathname === '/deposit' && { color: '#6663f1' })
          }}
        >
          {t('deposit')}
        </Button>
      </Link>
      <Link to='/withdraw'>
        <Button
          size='large'
          type='link'
          style={{
            ...(location.pathname === '/withdraw' && { color: '#6663f1' })
          }}
        >
          {t('withdraw')}
        </Button>
      </Link>
      <Link to='/security'>
        <Button 
          size='large'
          type='link'
          style={{
            ...(location.pathname === '/security' && { color: '#6663f1' })
          }}
        >
          {t('security')}
        </Button>
      </Link>
      {
        user.user.pro &&
          <Collapse 
            ghost
            expandIconPosition='end'
            expandIcon={(props) => 
              <div 
                style={{
                  transform: props.isActive ? 'rotate(180deg)' : undefined,
                  color: props.isActive ? '#6663f1' : '#f2f0ff',
                  margin: '5px 0 0'
                }}
              >
                <DownOutlined />
              </div>
            }
            items={[{
              label: 
                <Button 
                  size='large'
                  type='link'
                >
                  {t('admin.admin_panel')}
                </Button>,
              children: 
                <Flex vertical style={{paddingLeft: 20}} align='start'>
                  <Link to='/admin/assets'>
                    <Button
                      size='large'
                      type='link'
                      style={{
                        ...(location.pathname === '/admin/assets' && { color: '#6663f1' })
                      }}
                    >
                      {t('admin.assets')}
                    </Button>
                  </Link>
                  <Link to='/admin/users'>
                    <Button
                      size='large'
                      type='link'
                      style={{
                        ...(location.pathname === '/admin/users' && { color: '#6663f1' })
                      }}
                    >
                      {t('admin.users')}
                    </Button>
                  </Link>
                  <Link to='/admin/invitation-codes'>
                    <Button
                      size='large'
                      type='link'
                      style={{
                        ...(location.pathname === '/admin/invitation-codes' && { color: '#6663f1' })
                      }}
                    >
                      {t('admin.codes')}
                    </Button>
                  </Link>
                  <Link to='/admin/settings'>
                    <Button
                      size='large'
                      type='link'
                      style={{
                        ...(location.pathname === '/admin/settings' && { color: '#6663f1' })
                      }}
                    >
                      {t('admin.settings')}
                    </Button>
                  </Link>
                  <Link to='/admin/earnings'>
                    <Button
                      size='large'
                      type='link'
                      style={{
                        ...(location.pathname === '/admin/earnings' && { color: '#6663f1' })
                      }}
                    >
                      {t('admin.earnings')}
                    </Button>
                  </Link>
                  <Link to='/admin/withdrawals'>
                    <Button
                      size='large'
                      type='link'
                      style={{
                        ...(location.pathname === '/admin/withdrawals' && { color: '#6663f1' })
                      }}
                    >
                      {t('admin.withdrawals')}
                    </Button>
                  </Link>
                </Flex>
          }]}/>
      }
      <Button
        type='primary'
        size='small'
        onClick={handleLogOut}
        style={{marginTop: 10}}
      >
        <LogoutOutlined /> {t('sign_out')}
      </Button>
      {langs.length > 1 && <LangsSelect mobile/> }
    </Flex> :
    <Flex
      gap={10} 
      style={{marginLeft: 'auto', height: '100%'}}
      vertical
    >
      <Link to='/sign-in'><Button type='link'>{t('sign_in')}</Button></Link>
      <Link to='/sign-up'><Button type='primary'>{t('sign_up')}</Button></Link>
      {langs.length > 1 && <LangsSelect mobile/> }
    </Flex>
}

export default MobileMenu