import { Flex, Tag } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import PersonalInfo from './PersonalInfo/PersonalInfo'
import KYC from 'components/Account/KYC/KYC'
import { useUserContext } from 'Context/UserContext'
import { getKYCColor } from 'utilits/tagsColorFunctions.tsx'
import { Helmet } from 'react-helmet'
import Footer from 'components/Reusable/Footer/Footer'
import PageHeading from 'components/Reusable/PageHeading/PageHeading'
import { useTranslation } from 'react-i18next'

const AccountDetails: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'account'})
  const displayWidth = useDisplayWidth()
  const { user } = useUserContext()

  return (
    <>
      <Helmet>
        <title>{t('tab_title')}</title>
      </Helmet>
      <PageHeading title={t('title')} />
      {
        user?.kyc_state !== 'Unverified' ?
          <Flex
            gap={10}
            align='center'
            style={{maxWidth: 1500, width: '100%', padding: displayWidth > 800 ? 50 : '30px 20px'}}
          >
            <Title level={4}>{t('KYC_verification')}</Title>
            <Tag color={getKYCColor(user?.kyc_state || '')}>{user?.kyc_state}</Tag>
          </Flex> :
          <KYC />
      }
      <PersonalInfo />
      <Footer/>
      <Outlet />
    </>
  )
}

export default AccountDetails