const styles = {
  form: {
    maxWidth: 500,
    display: 'flex',
    width: '100%'
  },
  formLayout: {
    padding: '50px 10px',
    width: '100%'
  },
  image: {
    width: 500
  },
  fullWidth: {
    width: '100%'
  }
}

export { styles }