import React, { FC, useEffect, useState } from 'react'
import AdminWrapper from '../AdminWrapper/AdminWrapper'
import Title from 'antd/es/typography/Title'
import { Alert, Table, message } from 'antd'
import { getWithdrawsColumns } from './WithdrawsServe'
import { api } from 'utilits/api'
import { adminWithdrawsI } from 'types/adminTypes'
import StatusPopup from './StatusPopup/StatusPopup'

const Withdraws: FC = () => {
  const [withdraws, setWithdraws] = useState<adminWithdrawsI[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [isStatePopupOpen, setIsStatePopupOpen] = useState<boolean>(false)
  const [selectedTransactionId, setSelectedTransactionId] = useState<number>(0)
  const [selectedTransactionState, setSelectedTransactionState] = useState<string>('')

  const [errorApi, errorContext] = message.useMessage()
  const onError = (msg: string) => {
    setIsLoading(false)
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }

  const openStatePopup = (id: number, state: string) => {
    setSelectedTransactionId(id)
    setSelectedTransactionState(state)
    setIsStatePopupOpen(true)
  }

  const editTransaction = (id: number, newState: string, comment: string) => {
    setIsLoading(true)
    api.changeTransactionStatus(id, newState, comment)
      .then(getWithdraws)
      .then(() => setIsStatePopupOpen(false))
      .catch(err => onError(err.message))
  }

  const getWithdraws = () => {
    setIsLoading(true)
    api.getAllWithdraws()
      .then(res => setWithdraws(res.transactions))
      .catch(err => onError(err.message))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getWithdraws()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const withdrawColumns = getWithdrawsColumns(openStatePopup)
  return (
    <AdminWrapper>
      {errorContext}
      <Title level={3}>Withdraw Requests</Title>
      <Table
        size='small'
        loading={isLoading}
        columns={withdrawColumns}
        pagination={false}
        dataSource={withdraws}
        rowKey={r => r.id}
        scroll={{x: 1100}}
      />
      <StatusPopup
        isOpen={isStatePopupOpen}
        transactionId={selectedTransactionId}
        transactionStatus={selectedTransactionState}
        loading={isLoading}
        onClose={() => setIsStatePopupOpen(false)}
        onChangeStatus={editTransaction}
      />
    </AdminWrapper>
  )
}

export default Withdraws