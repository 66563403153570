import { ThemeConfig } from "antd";

const globalConfig: ThemeConfig = {
  token: {
    fontFamily: 'Manrope',
    colorPrimary: '#6663f1',
    colorText: '#565973',
    colorTextHeading: '#000',
    fontSize: 15
  },
  components: {
    Typography: {
      titleMarginBottom: 0,
    },
    Button: {
      colorLink: '#565973',
      colorPrimaryHover: '#4044ee',
      colorLinkHover: '#6663f1',
      primaryShadow: '0 .5rem 1.125rem -0.5rem rgba(99,102,241,.9)',
      colorText: '#000',
      defaultHoverBg: '#6663f1',
      defaultHoverColor: '#fff',
      defaultHoverBorderColor: 'transparent',
      borderColorDisabled: 'transparent',
      paddingBlock: 8,
      paddingBlockLG: 10,
      paddingBlockSM: 5.5,
      paddingInline: 26,
      paddingInlineLG: 30,
      paddingInlineSM: 18,
      contentFontSize: 14,
      contentFontSizeLG: 16,
      contentFontSizeSM: 12,
      borderRadiusSM: 3,
      borderRadius: 5,
      borderRadiusLG: 8,
    },
    Collapse: {
      contentPadding: 0,
      headerBg: 'transparent',
      colorBgContainer: 'transparent',
      headerPadding: '10px 0',
    },
    Popover: {
      titleMinWidth: 0,
      colorBgElevated: '#0b0f19',
      boxShadowSecondary: '0 0 0 1px #ffffff24',
      colorTextHeading: '#fff',
      colorText: '#fff'
    },
    FloatButton: {
      colorBgElevated: '#0000001a',
      colorText: '#fff'
    },
    Alert: {
      colorText: 'lightcoral'
    },
    Message: {
      contentBg: 'transparent',
      contentPadding: 0,
    },
    Select: {
      optionSelectedFontWeight: undefined
    },
    Tag: {
      marginXS: 0
    }
  }
}

export {
  globalConfig
}