import { RcFile } from "antd/es/upload"
import FileResizer from "react-image-file-resizer"

const resizeFile = (file: RcFile) =>
  new Promise((resolve) => {
    FileResizer.imageFileResizer(
      file,
      512,
      512,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      "blob"
    )
  })

export { resizeFile }
