import React, { FC, useEffect, useState } from 'react'
import AdminWrapper from '../AdminWrapper/AdminWrapper'
import Title from 'antd/es/typography/Title'
import { Alert, Button, Flex, Table, message } from 'antd'
import { adminEaringI } from 'types/adminTypes'
import { api } from 'utilits/api'
import { PlusOutlined } from '@ant-design/icons'
import EditPopup from './EditPopup/EditPopup'
import { getEarningsColumns } from './EarningsServe'

const Earnings: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [earnings, setEarnings] = useState<adminEaringI[]>([])
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [selectedEarning, setSelectedEarning] = useState<adminEaringI|null>(null)
  
  const [errorApi, errorContext] = message.useMessage()
  const onError = (msg: string) => {
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }

  const getEarnings = () => {
    setIsLoading(true)
    api.getAdminEarnings()
      .then(res => {
        setEarnings(res.earnings)
        setIsLoading(false)
      })
      .catch(err => onError(err.message))
      .finally(() => setIsLoading(false))
  }

  const earningsColumns = getEarningsColumns(setSelectedEarning)

  useEffect(() => {
    selectedEarning !== null && setIsPopupOpen(true)
  }, [selectedEarning])
  useEffect(() => {
    !isPopupOpen && setSelectedEarning(null)
  }, [isPopupOpen])
  useEffect(() => {
    getEarnings()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <AdminWrapper>
      {errorContext}
      <Flex align='center' justify='space-between' style={{width: '100%'}}>
        <Title level={3}>Earnings</Title>
        <Button 
          type='primary'
          onClick={() => setIsPopupOpen(true)}
        >
          <PlusOutlined /> Create Product
        </Button>
      </Flex>
      <Table
        size='small'
        loading={isLoading}
        columns={earningsColumns}
        pagination={false}
        dataSource={earnings}
        scroll={{x: 1100}}
        rowKey={r => r.id}
      />
      <EditPopup
        isOpen={isPopupOpen}
        earning={selectedEarning}
        onClose={() => setIsPopupOpen(false)}
        onSuccess={getEarnings}
      />
    </AdminWrapper>
  )
}

export default Earnings