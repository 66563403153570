import { WalletOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons"
import { TableColumnsType, Flex, Popconfirm, Button, Tag, Typography, Divider } from "antd"
import { userForAdminI } from "types/adminTypes"
import { countries } from "country-flag-icons"
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { getBooleanTag, getKYCColor, getLevelColor } from "utilits/tagsColorFunctions.tsx"
import { documentsOptions } from "consts/consts.tsx"
import { getCryptoIcon } from "utilits/getCryptoIcon.ts"
import { displayUSDPrice } from "utilits/displayUSDPrice"
const {Title, Text} = Typography

const getCountryFlag = (ConuntryCode: string) => {
  return countries.includes(ConuntryCode.toUpperCase()) ?
    getUnicodeFlagIcon(ConuntryCode):
    ConuntryCode.toUpperCase()
}

const processDoc = (doc: string) => {
  return documentsOptions.find(d => d.value === doc)?.label
}

const openPhoto = (link: string) => {
  console.log(link)
  window.open(`${window.env.APP_API_ENDPOINT}/api/storage/${link}`, '_blank')
}

const getUsersColumns = (
    onSwitchUser: (id: number, status: boolean) => void,
    onSwitchAdmin: (id: number, status: boolean) => void,
    onBalancePopup: (id: number) => void,
    onLevelPopup: (id: number, level: number) => void,
    onKYCPopup: (id: number, kyc: string) => void,
    onAssetsPopup: (id: number) => void,
    onSwitchPremium: (id: number, status: boolean) => void
  ): TableColumnsType<userForAdminI> => [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id'
  },
  {
    title: 'Info',
    key: 'info',
    render: (_, {email, cc, ip}) => 
      <Flex vertical>
        <Text>{email}</Text>
        <Text type='secondary'>{`${getCountryFlag(cc)} ${ip}`}</Text>
      </Flex>
  },
  {
    title: 'Total Balance',
    key: 'total_balance',
    render: (_, {balances}) => 
      <Flex vertical gap={3} style={{minWidth: 180}}>
        <Flex align='center' gap={5}>
          <img src={getCryptoIcon('BTC')} alt="" width={18} height={18}/>
          <Text strong>BTC:</Text>
          <Text>{(balances?.BTC?.total_balance || 0).toFixed(2)} ({displayUSDPrice((balances?.BTC?.total_balance || 0) * (balances?.BTC?.price_usdt || 0))})</Text>
        </Flex>
        <Flex align='center' gap={5}>
          <img src={getCryptoIcon('ETH')} alt="" width={18} height={18}/>
          <Text strong>ETH:</Text>
          <Text>{(balances?.ETH?.total_balance || 0).toFixed(2)} ({displayUSDPrice((balances?.ETH?.total_balance || 0) * (balances?.ETH?.price_usdt || 0))})</Text>
        </Flex>
        <Flex align='center' gap={5}>
          <img src={getCryptoIcon('BNB')} alt="" width={18} height={18}/>
          <Text strong>BNB:</Text>
          <Text>{(balances?.BNB?.total_balance || 0).toFixed(2)} ({displayUSDPrice((balances?.BNB?.total_balance || 0) * (balances?.BNB?.price_usdt || 0))})</Text>
        </Flex>
      </Flex>
  },
  {
    title: 'Total Deposited',
    key: 'total_deposited',
    render: (_, {balances}) => 
      <Flex vertical gap={3} style={{minWidth: 180}}>
        <Flex align='center' gap={5}>
          <img src={getCryptoIcon('BTC')} alt="" width={18} height={18}/>
          <Text strong>BTC:</Text>
          <Text>{(balances?.BTC?.total_deposited || 0).toFixed(2)} ({displayUSDPrice((balances?.BTC?.total_deposited || 0) * (balances?.BTC?.price_usdt || 0))})</Text>
        </Flex>
        <Flex align='center' gap={5}>
          <img src={getCryptoIcon('ETH')} alt="" width={18} height={18}/>
          <Text strong>ETH:</Text>
          <Text>{(balances?.ETH?.total_deposited || 0).toFixed(2)} ({displayUSDPrice((balances?.ETH?.total_deposited || 0) * (balances?.ETH?.price_usdt || 0))})</Text>
        </Flex>
        <Flex align='center' gap={5}>
          <img src={getCryptoIcon('BNB')} alt="" width={18} height={18}/>
          <Text strong>BNB:</Text>
          <Text>{(balances?.BNB?.total_deposited || 0).toFixed(2)} ({displayUSDPrice((balances?.BNB?.total_deposited || 0) * (balances?.BNB?.price_usdt || 0))})</Text>
        </Flex>
      </Flex>
  },
  {
    title: 'KYC',
    key: 'kyc',
    align: 'center',
    render: (_, {kyc, kyc_state, id}) => 
        <Flex vertical align='center' gap={3}>
          {
            kyc && kyc_state !== 'Verified' &&
            <>
              <Flex gap={2}>
                <Flex style={{maxWidth: 220, maxHeight: 25, overflow: 'scroll', textWrap: 'nowrap'}}>
                  <Text strong>{kyc.firstname} {kyc.lastname}</Text>
                </Flex>
                <Text>({new Date(kyc.dob).toLocaleDateString(undefined, {timeZone: 'UTC'})})</Text>
              </Flex>
              <Text>{getCountryFlag(kyc.country)} {kyc.country} / {processDoc(kyc.document_type)} / {kyc.document_id}</Text>
              <Flex align='center' gap={5}>
                <Button type="link" onClick={() => openPhoto(kyc.image_selfie)}>Selfie</Button>
                <Divider type='vertical' />
                <Button type="link" onClick={() => openPhoto(kyc.image_document)}>Document</Button>
              </Flex>
            </>
          }
          <Button type='link' style={{padding: 0}} onClick={() => onKYCPopup(id, kyc_state)}>
            <Tag color={getKYCColor(kyc_state)} style={kyc_state === 'Unverified' ? {color: '#565973'} : {}}>{kyc_state}</Tag>
          </Button>
        </Flex>  
  },
  {
    title: 'Level',
    key: 'level',
    align: 'center',
    render: (_, {level, id}) => 
      <Button type='link' style={{padding: 0}} onClick={() => onLevelPopup(id, level)}>
        <Tag color={getLevelColor(level)} style={level === 1 ? {color: '#565973'} : {}}>Level {level}</Tag>
      </Button>
  },
  {
    title: 'Active',
    key: 'active',
    align: 'center',
    render: (_, {active, id}) => 
      <Popconfirm
        icon={false}
        cancelButtonProps={{type: 'primary'}}
        placement='left'
        title={
          <Title level={5} style={{color: '#fff'}}>
            You sure to {active ? 'disable' : 'acivate'} user?
          </Title>
        }
        onConfirm={() => onSwitchUser(id, active)}
      >
        <Button type='link' style={{padding: 0}}>
          {getBooleanTag(active, true)}
        </Button>
      </Popconfirm>
  },
  {
    title: 'Admin',
    key: 'admin',
    align: 'center',
    render: (_, {admin, id}) => 
      <Popconfirm
        icon={false}
        cancelButtonProps={{type: 'primary'}}
        placement='left'
        title={
          <Title level={5} style={{color: '#fff'}}>
            You sure to {admin ? 'demote' : 'promote'} user?
          </Title>
        }
        onConfirm={() => onSwitchAdmin(id, admin)}
      >
        <Button type='link' style={{padding: 0}}>
          {getBooleanTag(admin)}
        </Button>
      </Popconfirm>
  },
  {
    title: 'Premium',
    key: 'admin',
    align: 'center',
    render: (_, {premium, id}) => 
      <Popconfirm
        icon={false}
        cancelButtonProps={{type: 'primary'}}
        placement='left'
        title={
          <Title level={5} style={{color: '#fff'}}>
            Update user premium state?
          </Title>
        }
        onConfirm={() => onSwitchPremium(id, premium)}
      >
        <Button type='link' style={{padding: 0}}>
          {getBooleanTag(premium)}
        </Button>
      </Popconfirm>
  },
  {
    key: 'actions',
    width: 100,
    render: (_, {id}) => 
      <Flex gap={5}>
        <Button
          size='small'
          type='primary'
          onClick={() => onAssetsPopup(id)}
        >
          <WalletOutlined /> Transactions
        </Button>
        <Button
          size='small'
          onClick={() => onBalancePopup(id)}
        >
          <VerticalAlignBottomOutlined /> Create TX
        </Button>
      </Flex>
  }
]

export {
  getUsersColumns
}