import React, { FC, useState } from 'react'
import NewCodePopupProps from './NewCodePopupProps'
import { Input, Modal, Typography } from 'antd'
const { Text } = Typography

const NewCodePopup: FC<NewCodePopupProps> = ({isOpen, loading, onClose, onCreate}) => {
  const [code, setCode] = useState<string>('')

  const createCode = () => {
    onCreate(code.trim() !== '' ? code : undefined)
  }
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title='Create new invitation code'
      onOk={createCode}
      okButtonProps={{
        disabled: loading,
        loading: loading
      }}
    >
      <Input
        value={code}
        onChange={({target}) => setCode(target.value)}
        placeholder='Сode'
      />
      <Text type='secondary' style={{fontSize: 12}}>
        Leave input empty to generate a random code
      </Text>
    </Modal>
  )
}

export default NewCodePopup