import React, { FC, useEffect, useState } from 'react'
import KYCPopupProps from './KYCPopupProps'
import { Flex, Input, Modal, Radio } from 'antd'
import Title from 'antd/es/typography/Title'
import { useDisplayWidth } from 'utilits/useDisplayWidth'

const KYCPopup: FC<KYCPopupProps> = ({isOpen, userId, userKYCStatus, loading, onClose, onChangeKYCStatus}) => {
  const displayWidth = useDisplayWidth()
  const [KYCStatus, setKYCStatus] = useState<string>('')
  const [comment, setComment] = useState<string>('')

  const buttonStyles = {width: '25%', padding: 0, fontSize: displayWidth < 400 ? 12 : undefined}

  useEffect(() => {
    setKYCStatus(userKYCStatus)
    setComment('')
  }, [userKYCStatus, userId])
  return (
    <Modal
      open={isOpen}
      title={
        <Flex gap={10} align='baseline'>
          <Title style={{margin: 0}} level={3}>Change user level</Title>
          <Title style={{margin: 0}} level={5} type='secondary'>{userId}</Title>
        </Flex>
      }
      onCancel={onClose}
      okButtonProps={{disabled: KYCStatus === userKYCStatus || loading, loading: loading}}
      onOk={() => onChangeKYCStatus(userId, KYCStatus, comment)}
    >
      <Flex vertical gap={10}>
        <Radio.Group
          size={displayWidth < 450 ? 'small' : 'middle'}
          style={{width: '100%'}}
          value={KYCStatus}
          onChange={({target}) => setKYCStatus(target.value)}
        >
          <Radio.Button style={{...buttonStyles, textAlign: 'center'}} value={'Unverified'}>Unverified</Radio.Button>
          <Radio.Button style={{...buttonStyles, textAlign: 'center'}} value={'Verification'}>Verification</Radio.Button>
          <Radio.Button style={{...buttonStyles, textAlign: 'center'}} value={'Verified'}>Verified</Radio.Button>
          <Radio.Button style={{...buttonStyles, textAlign: 'center'}} value={'Rejected'}>Rejected</Radio.Button>
        </Radio.Group>
        <Input
          size={displayWidth < 450 ? 'small' : 'middle'}
          placeholder='Comment'
          value={comment}
          onChange={({target}) => setComment(target.value)}
        />
        </Flex>
    </Modal>
  )
}

export default KYCPopup