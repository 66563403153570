import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Alert, ConfigProvider, Flex, message } from 'antd'
import { UserContext } from 'Context/UserContext'
import { userI } from 'types/types'
import MyHeader from 'components/Header/MyHeader'
import MarketNews from 'components/MarketNews/MarketNews'
import MarketHeatmap from 'components/MarketHeatmap/MarketHeatmap'
import Events from 'components/Events/Events'
import CurrencyConventor from 'components/CurrencyConventor/CurrencyConventor'
import Login from 'components/Login/Login'
import Register from 'components/Register/Register'
import ResetAccount from 'components/ResetAccount/ResetAccount'
import SuccessReset from 'components/ResetAccount/SuccessReset'
import Landing from 'components/Landing/Landing'
import Deposit from 'components/Deposit/Deposit'
import Withdraw from 'components/Withdraw/Withdraw'
import AccountDetails from 'components/Account/Account'
import Security from 'components/Security/Security'
import Earn from 'components/Earn/Earn'
import Assets from 'components/Assets/Assets'
import AdminAssets from 'components/Admin/Assets/Assets'
import ProtectedRouteElement from 'utilits/ProtectedRoute'
import NotFoundPage from 'components/NotFoundPage/NotFoundPage'
import Subscribe from 'components/Earn/Subscribe/Subscribe'
import Users from 'components/Admin/Users/Users'
import InvCodes from 'components/Admin/InvCodes/InvCodes'
import Settings from 'components/Admin/Settings/Settings'
import { api } from 'utilits/api'
import Earnings from 'components/Admin/Earnings/Earnings'
import TermsAndConditions from 'components/TermsAndConditions/TermsAndConditions'
import Privacy from 'components/Privacy/Privacy'
import Withdraws from 'components/Admin/Withdraws/Withdraws'
import LoadingPage from 'components/LoadingPage/LoadingPage'
import UseScrollToTop from 'utilits/useScrollToTop'
import { globalConfig } from 'consts/themeConfigs'

function App() {
  const [user, changeUser] = useState<userI|null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [errorApi, errorContext] = message.useMessage()

  const onError = (msg: string) => {
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }
  const getUserInfo = () => {
    setIsLoading(true)
    api.getUserInfo()
      .then(userInfo => {
        api.getUserAssets()
          .then(assets => {
            changeUser({
              ...userInfo,
              assets: assets.assets,
            })
          })
          .catch((err) => onError(err.message))
          .finally(() => setIsLoading(false))
      })
      .catch((err) => {
        onError('Unexpected')
        api.getUserInfo()
          .then(userInfo => {
            api.getUserAssets()
              .then(assets => {
                changeUser({
                  ...userInfo,
                  assets: assets.assets,
                })
              })
              .catch((err) => onError(err.message))
              .finally(() => setIsLoading(false))
          })
          .catch((err) => {
            onError(err.message)
            setIsLoading(false)
            localStorage.removeItem('token')
          })
      })
  }

  useEffect(() => {
    localStorage.getItem('token') && getUserInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <ConfigProvider theme={globalConfig}>
      {errorContext}
      <UserContext.Provider value={{user, changeUser}}>
      <UseScrollToTop />
      <Flex vertical align='center' style={{backgroundColor: '#fafafa', minHeight: '100vh'}}>
        <MyHeader/>
        <Routes>
          <Route path='*' element={ <NotFoundPage /> }/>
          <Route path='/' element={ <Landing /> } />
          <Route path='/conventor' element={ <CurrencyConventor/> } />
          <Route path='/events' element={ <Events/> } />
          <Route path='/market-news' element={ <MarketNews/> } />
          <Route path='/market-heatmap' element={ <MarketHeatmap/> } />
          <Route path='/terms_and_conditions' element={ <TermsAndConditions/> } />
          <Route path='/privacy' element={ <Privacy/> } />

          <Route path='/sign-in' element={ <ProtectedRouteElement forLoged element={ <Login/> } /> } />
          <Route path='/sign-up' element={ <ProtectedRouteElement forLoged element={ <Register/> } /> } />
          <Route path='/reset-password' element={ <ProtectedRouteElement forLoged element={ <ResetAccount /> } /> } />
          <Route path='/success-reset' element={ <ProtectedRouteElement forLoged element={ <SuccessReset /> } /> } />

          <Route path='/deposit' element={ <ProtectedRouteElement element={ <Deposit /> } /> } />
          <Route path='/withdraw' element={ <ProtectedRouteElement element={ <Withdraw /> } /> } />
          <Route path='/account' element={ <ProtectedRouteElement element={ <AccountDetails /> } /> } />
          <Route path='/security' element={ <ProtectedRouteElement element={ <Security /> } /> } />
          <Route path='/earn' element={ <ProtectedRouteElement element={ <Earn /> } /> } />
          <Route path='/earn/subscribe/:coin/:productId' element={ <ProtectedRouteElement element={ <Subscribe /> } /> } />
          <Route path='/assets' element={ <ProtectedRouteElement element={ <Assets /> } /> } />

          <Route path='/admin/assets' element={ <ProtectedRouteElement element={ isLoading ? <LoadingPage /> : ( user?.pro ? <AdminAssets /> : <NotFoundPage/>)} /> }/>
          <Route path='/admin/users' element={ <ProtectedRouteElement element={ isLoading ? <LoadingPage /> : ( user?.pro ? <Users /> : <NotFoundPage/>)} />} /> 
          <Route path='/admin/invitation-codes' element={ <ProtectedRouteElement element={ isLoading ? <LoadingPage /> : ( user?.pro ? <InvCodes /> : <NotFoundPage/>)} />}/>
          <Route path='/admin/settings' element={ <ProtectedRouteElement element={ isLoading ? <LoadingPage /> : ( user?.pro ? <Settings /> : <NotFoundPage/>)} />} />
          <Route path='/admin/earnings' element={ <ProtectedRouteElement element={ isLoading ? <LoadingPage /> : ( user?.pro ? <Earnings /> : <NotFoundPage/>)} />} />
          <Route path='/admin/withdrawals' element={ <ProtectedRouteElement element={ isLoading ? <LoadingPage /> : ( user?.pro ? <Withdraws /> : <NotFoundPage/>)} />} />
        </Routes>
      </Flex>
      </UserContext.Provider>
    </ConfigProvider>
  )
}

export default App
