import { Button, ConfigProvider, Flex, Table, TableColumnsType, Typography,  } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { FC, useEffect, useState } from 'react'
import { userAssetI } from 'types/types'
import { useNavigate } from 'react-router-dom'
import { VerticalAlignBottomOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { getCryptoIcon } from 'utilits/getCryptoIcon'
import { useUserContext } from 'Context/UserContext'
import { displayUSDPrice } from 'utilits/displayUSDPrice'
import { useTranslation } from 'react-i18next'
const {Text} = Typography

const Equity: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'assets.equity'})
  const navigate = useNavigate()
  const displayWidth = useDisplayWidth()
  const { user } = useUserContext()

  const [assets, setAssets] = useState<userAssetI[]>([])
  // const [search, setSearch] = useState<string>('')

  const tableColumns: TableColumnsType<userAssetI> = [
    {
      title: t('coin'),
      key: 'coin',
      sorter: {compare: ( a, b ) => a.name < b.name ? 1 : -1},
      showSorterTooltip: false,
      render: (_, {ticker, name}) => 
        <Flex gap={10} align='center'>
          <img src={getCryptoIcon(ticker)} alt={ticker} style={{width: 30, height: 30}} />
          <Flex vertical>
            <Title level={5} style={{textWrap: 'nowrap'}}>{ticker}</Title>
            { displayWidth > 450 && <Title level={5} type='secondary' style={{margin: 0, textWrap: 'nowrap'}}>{name}</Title> }
          </Flex>
        </Flex>
    },
    {
      title: t('equity'),
      key: 'equity',
      align: 'end',
      sorter: {compare: ( a, b ) => a.balance - b.balance},
      showSorterTooltip: false,
      render: (_, {price_usdt, balance, balance_earn, balance_frozen, ticker}) => 
        <Flex vertical align='flex-end'>
          <Text strong style={{textWrap: 'nowrap'}}>{(balance + balance_earn + balance_frozen).toFixed(7)} {ticker}</Text>
          <Text type='secondary'>{displayUSDPrice(price_usdt ? ((balance + balance_earn + balance_frozen) * price_usdt) : 0)}</Text>
        </Flex>,
      responsive: ['lg']
    },
    {
      title: t('available'),
      key: 'available',
      align: 'end',
      sorter: {compare: ( a, b ) => (a.balance - a.balance_earn) - (b.balance - b.balance_earn)},
      showSorterTooltip: false,
      render: (_, {price_usdt, balance, balance_earn, ticker}) => 
        <Flex vertical align='flex-end'>
          <Text strong style={{textWrap: 'nowrap'}}>{(balance - balance_earn).toFixed(7)} {ticker}</Text>
          <Text type='secondary'>{displayUSDPrice(price_usdt ? (balance - balance_earn) * price_usdt : 0)}</Text>
        </Flex>
    },
    {
      title: t('earn'),
      key: 'earn',
      align: 'end',
      sorter: {compare: ( a, b ) => a.balance_earn - b.balance_earn},
      showSorterTooltip: false,
      render: (_, {price_usdt, balance_earn, ticker}) => 
        <Flex vertical align='flex-end'>
          <Text strong style={{textWrap: 'nowrap'}}>{balance_earn.toFixed(7)} {ticker}</Text>
          <Text type='secondary'>{displayUSDPrice(price_usdt ? balance_earn * price_usdt : 0)}</Text>
        </Flex>,
      responsive: ['sm']
    },
    {
      key: 'actions',
      align: 'end',
      render: (_, {depositable}) => 
        <Flex 
          gap={displayWidth < 850 ? 5 : 10}
          justify='end'
          vertical={displayWidth < 850}
        >
          {
            depositable && 
              <Button
                type='primary'
                size={displayWidth > 900 ? 'middle' : 'small'}
                onClick={() => navigate('/deposit')}
              >
                <VerticalAlignBottomOutlined /> {t('deposit')}
              </Button>
          }
          <Button
            size={displayWidth > 900 ? 'middle' : 'small'}
            onClick={() => navigate('/withdraw')}
          >
            <VerticalAlignTopOutlined /> {t('withdraw')}
          </Button>
        </Flex>
    }
  ]

  useEffect(() => {
    user?.assets && setAssets(user.assets)
  }, [user])

  // useEffect(() => {
  //   setCoins(assets.filter(c => c.name.toLowerCase().includes(search.toLowerCase().trim())))
  // }, [search])
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            rowExpandedBg: 'transparent',
            colorBgContainer: 'transparent',
            headerBg: 'transparent',
            borderColor: 'transparent',
            headerSplitColor: 'transparent',
            bodySortBg: 'transparent',
            headerSortActiveBg: 'trasparent',
            headerSortHoverBg: 'transparent'
          },
          Button: {
            primaryShadow: 'none'
          },
          Typography: {
            titleMarginTop: 5,
            fontSize: 16,
            fontSizeHeading5: displayWidth < 500 ? 14 : 16
          }
        }
      }}
    >
      <Flex 
        vertical
        gap={50}
        style={{width: '100%', maxWidth: 1500, margin: '0 auto', padding: displayWidth > 800 ? 50 : '30px 20px'}}
      >
        <Title level={1}>{t('title')}</Title>
        {/* <Input
          size='large'
          prefix={<SearchOutlined/>}
          placeholder='Find coin'
          value={search}
          onChange={({target}) => setSearch(target.value)}
        /> */}
        <Table
          size={displayWidth > 450 ? 'middle' : 'small'}
          dataSource={assets}
          columns={tableColumns}
          pagination={false}
          rowKey={r => r.name}
        />
      </Flex>
    </ConfigProvider>
  )
}

export default Equity