import React, { FC } from 'react'
import Footer from 'components/Reusable/Footer/Footer'
import Title from 'antd/es/typography/Title'
import { Flex } from 'antd'
import EventsWidget from './EventsWidget'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const Events: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'widgets.events'})
  const displayWidth = useDisplayWidth()
  return (
    <>
      <Helmet>
        <title>{t('tab_title')}</title>
      </Helmet>
      <Flex
        vertical
        style={{width: '100%', maxWidth: 1500, margin: '0 auto', padding: displayWidth > 800 ? 50 : 20}}
        gap={30}
      >
        <Title level={1}>{t('title')}</Title>
        <EventsWidget />
      </Flex>
      <Footer />
    </>
  )
}

export default Events