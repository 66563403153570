import { Flex, Input, Button, ConfigProvider } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDisplayWidth } from 'utilits/useDisplayWidth'

const PersonalInfo: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'account.personal_info'})
  const displayWidth = useDisplayWidth()

  const [name, setName] = useState<string>('')
  const [secondName, setSecondName] = useState<string>('')
  const [mail, setMail] = useState<string>('')
  const [number, setNumber] = useState<string>('')
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorBgContainer: '#eff2fc',
            defaultHoverBg: '#d9e2ff',
            defaultHoverColor: '#000'
          }
        }
      }}
    >
      <Flex vertical gap={30} style={{width: '100%', maxWidth: 1500, padding: displayWidth  > 800 ? '30px 50px' : '20px 20px'}}>
          <Title level={2}>{t('title')}</Title>
          <Flex wrap='wrap' gap={30}>
            <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
              <Title level={5}>{t('firstname')}</Title>
              <Input 
                size='large' 
                placeholder={t('firstname')}
                value={name}
                onChange={({target}) => setName(target.value)}
              />
            </Flex>
            <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
              <Title level={5}>{t('lastname')}</Title>
              <Input
                size='large'
                placeholder={t('lastname')}
                value={secondName}
                onChange={({target}) => setSecondName(target.value)}
              />
            </Flex>
          </Flex>
          <Flex wrap='wrap' gap={30}>
            <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
              <Title level={5}>{t('email')}</Title>
              <Input
                size='large'
                placeholder='mail@example.com'
                value={mail}
                onChange={({target}) => setMail(target.value)}
              />
            </Flex>
            <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
              <Title level={5}>{t('number')}</Title>
              <Input
                size='large'
                placeholder='+1 ___ ___ __'
                value={number}
                onChange={({target}) => setNumber(target.value)}
              />
            </Flex>
          </Flex>
          <Flex gap={10} style={{marginBottom:100}}>
            <Button size='large' style={{border: 'none', minHeight: 50, padding: '0 30px'}}>{t('cancel_btn')}</Button>
            <Button size='large' style={{minHeight: 50, padding: '0 30px'}} type='primary'>{t('save_btn')}</Button>
          </Flex>
        </Flex>
      </ConfigProvider>
  )
}

export default PersonalInfo