import { Alert, Card, Collapse, ConfigProvider, Flex, QRCode, Select, Steps, Typography, message } from 'antd'
import React, { FC, useEffect, useRef, useState } from 'react'
import { CheckOutlined, CopyOutlined, DownOutlined } from '@ant-design/icons'
import { useOutsideClick } from '@chakra-ui/react'
import Footer from 'components/Reusable/Footer/Footer'
import { Outlet } from 'react-router-dom'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { api } from 'utilits/api'
import { useUserContext } from 'Context/UserContext'
import { depositInfoI, transactionI } from 'types/types'
import TransactionsTable from 'components/Reusable/TransactionsTable/TransactionsTable'
import { FAQText } from 'consts/consts'
import { proccessCurrencyOptions } from 'utilits/useCurrencyOptions'
import { Helmet } from 'react-helmet'
import PageHeading from 'components/Reusable/PageHeading/PageHeading'
import { useTranslation } from 'react-i18next'
const { Text, Title } = Typography

const Deposit: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'deposit'})
  const displayWidth = useDisplayWidth()
  const {user} = useUserContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [step, setStep] = useState<number>(0)
  const [depositInfo, setDepositInfo] = useState<depositInfoI|null>(null)
  // currency states
  const currencyRef = useRef<any>()
  const [currency, setCurrency] = useState<number>(0)
  const [currencyTicker, setCurrencyTicker] = useState<string>('')
  const [isCurrencySelectOpen, setIsCurrencySelectOpen] = useState<boolean>(false)
  const [currencyOption, setCurrencyOptions] = useState<{value: number, ticker: string, label: JSX.Element}[]>([])
  //netwirk states
  // const networkRef = useRef<any>()
  // const [network, setNetwork] = useState<string>('')
  // const [isNetworkSelectOpen, setIsNetworkSelectOpen] = useState<boolean>(false)
  // const [networkOptions, setNetworkOptions] = useState<{value: string, label: any}[]>(networks)

  //change handlers
  
  const [transactions, setTransactions] = useState<transactionI[]>([])

  const [errorApi, errorContext] = message.useMessage()
  const onError = (msg: string) => {
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }
  
  const handleCurrencyChange = (value: number, option: any) => {
    setCurrency(value)
    setCurrencyTicker(option.ticker)
    api.getWalletForDeposit(value)
      .then(setDepositInfo)
      .then(() => setStep(1))
      .catch(err => onError(err.message))
    // setNetwork('')
  }
  // const handleNetworkChange = (value: string) => {
  //   setNetwork(value)
  //   setDepositAddress('gdwrG2X2ptEr1xQWkSByJ4fadsdtgh454')
  //   setStep(2)
  // }
  // const handleNetworkFilterChange = (input: string, option?: { label: string; value: any }) => {
  //   return (JSON.stringify(option?.label) || option?.value || '').toLowerCase().includes(input.toLowerCase())
  // }

  const getTransitions = () => {
    setIsLoading(true)
    api.getTransactionsHistory()
      .then(res => setTransactions(res.transactions))
      .catch(err => onError(err.message))
      .finally(() => setIsLoading(false))
  }

  useOutsideClick({
    ref: currencyRef,
    handler: () => setIsCurrencySelectOpen(false)
  })
  // useOutsideClick({
  //   ref: networkRef,
  //   handler: () => setIsNetworkSelectOpen(false)
  // })

  // useEffect(() => {
  //   setIsCurrencySelectOpen(false)
  //   setIsNetworkSelectOpen(false)
  // }, [currency, network])

  useEffect(() => {
    user?.assets && setCurrencyOptions(proccessCurrencyOptions(user.assets))
  }, [user])

  useEffect(() => {
    getTransitions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ConfigProvider
        theme={{
          components: {
            Button: {
              primaryShadow: 'none'
            },
          }
        }}
      >
      {errorContext}
      <Helmet>
        <title>{t('tab_title')}</title>
      </Helmet>
      <PageHeading title={t('title')} />
      <Flex vertical style={{padding: displayWidth > 800 ? 50 : '30px 20px', maxWidth: 1500, width: '100%'}}>
        <Flex justify='space-between' wrap={displayWidth > 1200 ? 'nowrap' : 'wrap'} gap={30}>
          <Steps
            direction='vertical'
            current={step}
            style={{maxWidth: 800, height: 'min-content', minHeight: 100}}
            items={[
              {
                title: t('select_coin'),
                description: 
                  <Select
                    size='large'
                    style={{width: '100%', maxWidth: 500}}
                    placeholder={t('select_placeholder')}
                    value={currency === 0 ? undefined : currency}
                    onChange={handleCurrencyChange}
                    options={currencyOption}
                    onClick={() => setIsCurrencySelectOpen(state => !state)}
                    open={isCurrencySelectOpen}
                    suffixIcon={
                      <DownOutlined className={`select-icon ${isCurrencySelectOpen && 'select-icon_turned'}`}/>
                    }
                    dropdownRender={menu => (
                      <Flex
                        ref={currencyRef}
                        vertical
                        gap={5}
                      >
                        {menu}
                      </Flex>
                    )}
                  />
              },
              // {
              //   title: 'Select Network',
              //   description: 
              //     <>
              //       {
              //         currency &&
              //           <Select
              //             size='large'
              //             showSearch
              //             style={{width: '100%', maxWidth: 500}}
              //             placeholder={<><SearchOutlined/> Select Network</>}
              //             value={network === '' ? undefined : network}
              //             onChange={handleNetworkChange}
              //             options={networkOptions}
              //             onClick={() => setIsNetworkSelectOpen(state => !state)}
              //             open={isNetworkSelectOpen}
              //             filterOption={handleNetworkFilterChange}
              //             suffixIcon={
              //               <DownOutlined className={`select-icon ${isNetworkSelectOpen && 'select-icon_turned'}`}/>
              //             }
              //             dropdownRender={menu => (
              //               <Flex
              //                 ref={networkRef}
              //                 vertical
              //                 gap={5}
              //               >
              //                 {menu}
              //               </Flex>
              //             )}
              //           />
              //       }
              //     </>
              // },
              {
                title: t('address_title'),
                description: 
                <>
                  {
                    currency !== 0 && depositInfo &&
                      <Card size='small' style={{width: '100%', padding: 5}}>
                        <Flex gap={10} vertical={displayWidth < 600}  align='center'>
                          <QRCode size={200} style={{padding: 0, minWidth: 200}} color='#000' value={depositInfo.address} bordered={false}/>
                          <Flex vertical style={{width: '100%', height: '100%'}} align='flex-start'>
                            <Title level={5}>{t('address')}</Title>
                            <Text 
                              copyable={{
                                icon: [<CopyOutlined style={{color: '#6663f1'}} />, <CheckOutlined style={{color: '#6663f1'}} />],
                                tooltips: false,
                              }}
                              style={{fontSize: 16}}
                            >
                              {depositInfo.address}
                            </Text>
                            <Text type='secondary' style={{marginTop: 10}}>{t('arrival_time')} {depositInfo.arrival_time}</Text>
                            <Flex  style={{marginTop: 10, width: '100%'}}>
                              <Flex vertical>
                                <Text strong>{t('minimum_deposit')}</Text>
                                <Text type='secondary'>{depositInfo.minimal_deposit} {currencyTicker}</Text>
                              </Flex>
                              <Flex vertical style={{marginLeft: 'auto'}}>
                                <Text strong style={{textAlign: 'end'}}>{t('expected_arrival')}</Text>
                                <Text type='secondary' style={{textAlign: 'end'}}>{depositInfo.confirmations} {t('confirmations')}</Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Card>
                  }
                </>
              }
            ]}
          />
          <Flex vertical gap={20} style={{maxWidth: displayWidth > 1200 ? 500 : undefined,}}>
            <Title level={3}>{t('FAQ')}</Title>
            <Collapse 
              items={FAQText}
              expandIconPosition='end'
              ghost
              expandIcon={(props) => 
                <div 
                  style={{
                    padding: 8, borderRadius: '50%',
                    background: props.isActive ? '#6663f1' : '#f2f0ff', 
                    transform: props.isActive ? 'rotate(180deg)' : undefined,
                    color: props.isActive ? '#f2f0ff' : '#6663f1',
                    boxShadow: props.isActive ? '0 .5rem 1.125rem -0.5rem rgba(99,102,241,.9)' : undefined,
                    margin: 'auto',
                  }}
                >
                  <DownOutlined />
                </div>
              }
            />
            <Title level={3}>{t('tips')}</Title>
            <Flex vertical gap={5}>
              <Text type='secondary'>{t('tip_1', {currencyTicker: currencyTicker})}</Text>
              <Text type='secondary'>{t('tip_2')}</Text>
            </Flex>
          </Flex>
        </Flex>
        <Title style={{marginTop: 30, marginBottom: 20}} level={3}>{t('recent_transactions')}</Title>
        <TransactionsTable
          data={transactions}
          loading={isLoading}
        />
      </Flex>
      <Footer />
      <Outlet />
    </ConfigProvider>
  )
}

export default Deposit