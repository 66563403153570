import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import './Products.scss'
import { useOutsideClick } from '@chakra-ui/react'
import { Alert, Button, ConfigProvider, Divider, Flex, Input, Select, Table, TableColumnsType, Typography, message } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { FC, useEffect, useRef, useState } from 'react'
import { acitiveProductI, earnCoinsI } from 'types/types'
import { useNavigate } from 'react-router-dom'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { api } from 'utilits/api'
import { getCryptoIcon } from 'utilits/getCryptoIcon'
import { findAsset, proccessProducts } from './ProductsServe'
import { convertSToTime } from 'utilits/converSToTime'
import { useUserContext } from 'Context/UserContext'
import { useTranslation } from 'react-i18next'
const {Text} = Typography

const Products: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'earn.products'})
  const navigate = useNavigate()
  const durationRef = useRef<any>()
  const displayWidth = useDisplayWidth()
  const { user } = useUserContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [search, setSearch] = useState<string>('')
  const [duration, setDuration] = useState<string>('All Durations')
  const [isDurationOpen, setIsDurationOpen] = useState<boolean>(false)

  const [displayedProducts, setDisplayedProducts] = useState<earnCoinsI[]>()
  const [products, setProducts] = useState<earnCoinsI[]>([])
  const [activeProduct, setActiveProduct] = useState<acitiveProductI|null>(null)

  const [errorApi, errorContext] = message.useMessage()

  const onError = (msg: string) => {
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }

  const coinsTableColumns: TableColumnsType<earnCoinsI> = [
    {
      title: <Title level={5} style={{textWrap: 'nowrap', color: '#B0B0B0'}}>{t('assets')}</Title>,
      key: 'assets',
      render: (_, {name, ticker}) => 
        <Flex align='center' gap={10}>
          <img src={getCryptoIcon(ticker)} alt={ticker} style={{width: displayWidth > 500 ? 40 : 30, height: displayWidth > 500 ? 40 : 30}}/>
          <Flex vertical>
            <Title level={5} style={{margin: 0}}>{ticker}</Title>
            <Title level={5} style={{margin: 0, textWrap: 'nowrap'}} type='secondary'>{name}</Title>
          </Flex>
        </Flex>
    },
    {
      title: <Title level={5} style={{textWrap: 'nowrap', color: '#B0B0B0'}}>{t('max_deposit')}</Title>,
      key: 'max_deposit',
      align: 'center',
      width: 50
    },
    {
      title: <Title level={5} style={{textWrap: 'nowrap', color: '#B0B0B0'}}>{t('APR')}</Title>,
      key: 'APR',
      align: 'center',
      render: (_, {APR}) => <Text strong style={{color: 'green', textWrap: 'nowrap'}}>{APR}</Text>
    },
    {
      title: t('duration'),
      key: 'duration',
      render: (_, {duration}) => <Text strong>{duration}</Text> ,
      responsive: ['md']
    },
    Table.EXPAND_COLUMN,
  ]

  useOutsideClick({
    ref: durationRef,
    handler: () => setIsDurationOpen(false)
  })

  const getEarnings = () => {
    setIsLoading(true)
    api.getEarnings()
      .then(res => {
        user?.assets && setProducts(proccessProducts(res.earnings, user.assets))
        setActiveProduct(res.active)
      })
      .catch(err => onError(err.message))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    let filteredProducts = products.filter(c => c.name?.toLowerCase().includes(search.toLowerCase()))
    filteredProducts = duration !== 'All Durations' ?  filteredProducts.filter(c => c.duration.includes(duration)) : filteredProducts
    setDisplayedProducts(filteredProducts)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, duration, products])

  useEffect(() => {
    getEarnings()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerColor: '#b0b0b0',
            rowExpandedBg: 'transparent',
            colorBgContainer: 'transparent',
            headerBg: 'transparent',
            borderColor: 'transparent',
            headerSplitColor: 'transparent',
          },
          Button: {
            primaryShadow: 'none'
          },
          Typography: {
            fontSize: displayWidth > 600 ? 20 : 14,
            fontSizeHeading5: displayWidth > 500 ? 20 : 14
          }
        }
      }}
    >
      {errorContext}
      <Flex
        vertical
        style={{width: '100%', maxWidth: 1500, margin: '0 auto', padding: displayWidth > 800 ? 50 : 10}}
        gap={30}
      >
        <Title level={1}>{t('title')}</Title>
        <Flex gap={20}>
          <Input
            placeholder={t('serch_placeholer')}
            prefix={<SearchOutlined/>}
            style={{width: 250}}
            value={search}
            onChange={({target}) => setSearch(target.value)}
          />
          <Select
            size='large'
            style={{width: 150}}
            value={duration}
            onChange={setDuration}
            options={[
              {label: t('all_durations'), value: 'All Durations'},
              {label: t('flexible'), value: 'Flexible'},
              {label: t('fixed'), value: 'Fixed'},
            ]}
            onClick={() => setIsDurationOpen(state => !state)}
            open={isDurationOpen}
            suffixIcon={
              <DownOutlined className={`select-icon ${isDurationOpen && 'select-icon_turned'}`}/>
            }
            dropdownRender={menu => (
              <Flex
                ref={durationRef}
                vertical
                gap={5}
              >
                {menu}
              </Flex>
            )}
          />
        </Flex>
        <Title level={2}>{t('products_title')}</Title>
        <Table
          size='small'
          loading={isLoading}
          columns={coinsTableColumns}
          dataSource={displayedProducts}
          pagination={false}
          rowKey={r => r.ticker}
          rowClassName='row'
          expandable={{
            expandedRowRender: ({ticker, variants}) => 
              <>
              {
                variants.map((v, i) => 
                  <div key={i}>
                    <Flex
                      justify='space-between' 
                      align='center'
                      style={{paddingLeft: 15}}
                    >
                      <Text 
                        strong
                        style={{maxWidth:  displayWidth > 600 ? 300 : 100, width: '100%'}}
                      >
                        {v.name}
                      </Text>
                      <Flex style={{maxWidth: 250, minWidth: 80, width: '100%'}} align='center' vertical>
                        <Text>
                          ${(v.amount_max * (findAsset(v.asset_ticker, user?.assets).price_usdt || 0)).toFixed(2)}
                        </Text>
                        <Text type='secondary' style={{fontSize: displayWidth > 600 ? 14 : 10}}>≈ {v.amount_max} {v.asset_ticker}</Text>
                      </Flex>
                      <Flex style={{maxWidth: 150, minWidth: 100, width: '100%'}} align='center' vertical>
                        <Text 
                          style={{color: 'green'}}
                        >
                          +${(v.amount_max * v.apr_max * 0.01 * (findAsset(v.asset_ticker, user?.assets).price_usdt || 0)).toFixed(2)}
                        </Text>
                        <Text type='secondary' style={{fontSize: displayWidth > 600 ? 14 : 10}}>
                          ≈ {(v.amount_max * v.apr_max * 0.01).toFixed(3)} {v.asset_ticker} ({v.apr_max}%)
                        </Text>
                      </Flex>
                      { displayWidth > 767 &&
                        <Flex gap={10} style={{maxWidth: 350, width: '100%'}}>
                          <Text>{v.duration_type}</Text>
                          <Text type='secondary'>{v.duration_type === 'Fixed' && `${convertSToTime(v.duration)}`}</Text>
                        </Flex>
                      }
                      <Button 
                        type='primary' 
                        disabled={
                          user?.premium ? false : (v.level !== user?.account_level || (activeProduct !== null && v.id !== activeProduct?.earning_id))
                        } 
                        size={displayWidth > 600 ? 'middle' : 'small'}
                        onClick={() => navigate(`subscribe/${ticker}/${v.id}`)}
                      >
                        {t(v.id === activeProduct?.earning_id ? 'show_btn' : 'subscribe_btn')}
                      </Button>
                    </Flex>
                    <Divider style={{margin: 10}}/>
                  </div>
                )
              }
              </>,
            expandIcon: ({expanded, record, onExpand}) => 
              <DownOutlined onClick={e => onExpand(record, e)} style={{padding: '0 20px'}} className={`select-icon ${expanded && 'select-icon_turned'}`}
            />,
            expandRowByClick: true,
            defaultExpandedRowKeys: ['ETH', 'BTC', 'BNB'],
          }}
        />
      </Flex>
    </ConfigProvider>
  )
}

export default Products