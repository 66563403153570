import { useLayoutEffect, useState } from "react"

const useDisplayWidth = () => {
  const [displayWidth, setDisplayWidth] = useState<number>(0)

  useLayoutEffect(() => {
    setDisplayWidth(window.innerWidth)
    window.addEventListener('resize', () => setDisplayWidth(window.innerWidth))
    return () => window.removeEventListener('resize', () => setDisplayWidth(window.innerWidth))
  }, [])

  return displayWidth
}

export { useDisplayWidth }