import React, { FC, useState } from 'react'
import { Footer as AntFooter } from 'antd/es/layout/layout'
import { Button, ConfigProvider, Flex, Input, Modal, Space, Typography } from 'antd'
import { CheckCircleOutlined, MailOutlined } from '@ant-design/icons'
import { Links } from './Links'
import { mailRegex } from 'consts/consts'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { useTranslation } from 'react-i18next'
const {Text, Title} = Typography

const Footer: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'footer'})
  const displayWidth = useDisplayWidth()
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const [mail, setMail] = useState<string>('')
  const [mailError, setMailError] = useState<boolean>(false)

  const subscribe = () => {
    if(mailRegex.test(mail.trim())) {
      setMail('')
      setIsSuccess(true)
      setTimeout(() => setIsSuccess(false), 2500)
    } else {
      setMailError(true)
      setTimeout(() => setMailError(false), 2500)
    }
  }
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: '#0c0f19',
            headerBg: '#0c0f19',
            titleColor: '#fff',
            boxShadow: '0 0 0 1px #ffffff24'
          },
          Button: {
            primaryShadow: 'none',
            colorLink: '#33354d',
            colorLinkHover: '#6663f1',
            fontWeight: 500,
            fontSize: 16,
          }
        }
      }}>
      <AntFooter style={{width: '100%', background: 'transparent', height: 'fit-content', maxWidth: 1400, margin: '30px auto 0', padding: displayWidth > 800 ? 50 : 10}}>
        <Flex vertical gap={50}>
          <Flex gap={50} style={{ maxWidth: '100%'}} justify={displayWidth > 1100 ? 'space-between' : undefined} wrap='wrap'>
            <Flex
              vertical
              gap={30}
              style={{width: displayWidth > 1100 ? 450 : (displayWidth > 560 ? '50%' : '100%')}}
            >
              <Title level={1}>{window.env.APP_TITLE}</Title>
              <Text type='secondary' style={{fontSize: 14, lineHeight: 1.5}}>
                {t('description', {app_name: window.env.APP_TITLE})}
              </Text>
              <Flex vertical gap={5}>
                <Text strong>{t('subscribe_form.caption')}</Text>
                <Space.Compact>
                  <Input
                    placeholder={t('subscribe_form.placeholder')}
                    prefix={<MailOutlined />}
                    value={mail}
                    onChange={({target}) => setMail(target.value)}
                    status={mailError ? 'error' : undefined}
                  />
                  <Button type='primary' onClick={subscribe}>{t('subscribe_form.button')}</Button>
                </Space.Compact>
              </Flex>
            </Flex>
            <Links displayWidth={displayWidth}/>
          </Flex>
          <Text type='secondary' style={{fontSize: 12}}>{t('copyright')}</Text>
        </Flex>
      </AntFooter>
      <Modal
          centered
          children={
            <Flex
              align='center' 
              gap={10}
              style={{color: '#fff', fontWeight: 600, fontSize: 18, width: '100%', minWidth: 280}}
            >
              <CheckCircleOutlined 
                style={{color: '#6663f1'}}
              />
              {t('subscribe_form.success')}
            </Flex>}
          width={320}
          open={isSuccess}
          onCancel={() => setIsSuccess(false)}
          closeIcon={null}
          footer={null}
        />
    </ConfigProvider>
  )
}

export default Footer