import React, { FC, useEffect, useRef, useState } from 'react'
import { Flex, Input, Modal, Select, Space } from 'antd'
import AddBalancePopupProps from './AddBalancePopupProps'
import { SearchOutlined, DownOutlined } from '@ant-design/icons'
import { emptyBalanceRegex } from 'consts/consts'
import Title from 'antd/es/typography/Title'
import { proccessCurrencyOptions, stateOption, typeOption } from './AddBalancePopupServe'
import { useUserContext } from 'Context/UserContext'
import { useOutsideClick } from '@chakra-ui/react'

const AddBalancePopup: FC<AddBalancePopupProps> = ({userId, isOpen, loading, onClose, onAccrue}) => {
  const assetRef = useRef<any>()
  const stateRef = useRef<any>()
  const typeRef = useRef<any>()
  const {user} = useUserContext()

  const [value, setValue] = useState<string>('')

  const [currency, setCurrency] = useState<number>(0)
  const [isCurrencySelectOpen, setIsCurrencySelectOpen] = useState<boolean>(false)
  const [currencyOption, setCurrencyOptions] = useState<{value: number, label: JSX.Element}[]>([])

  const [state, setState] = useState<string>('')
  const [isStateSelectOpen, setIsStateSelectOpen] = useState<boolean>(false)

  const [type, setType] = useState<string>('')
  const [isTypeSelectOpen, setIsTypeSelectOpen] = useState<boolean>(false)

  const [comment, setComment] = useState<string>('')

  const handleValueChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    if(!Number.isNaN(Number(target.value)) || target.value === ',' || (value === '' && target.value === '-')) {
      setValue(target.value)
    }
  }

  useOutsideClick({
    ref: assetRef,
    handler: () => setIsCurrencySelectOpen(false)
  })
  useOutsideClick({
    ref: stateRef,
    handler: () => setIsStateSelectOpen(false)
  })
  useOutsideClick({
    ref: typeRef,
    handler: () => setIsTypeSelectOpen(false)
  })

  const handleTransaction = () => {
    onAccrue(currency, state, type, Number(value), userId, comment)
  }

  useEffect(() => {
    user?.assets && setCurrencyOptions(proccessCurrencyOptions(user.assets))
  }, [user])

  useEffect(() => {
    setValue('')
    setCurrency(0)
    setState('')
    setType('')
    setComment('')
  }, [isOpen])
  return (
    <Modal
      open={isOpen}
      title={
        <Flex gap={10} align='baseline'>
          <Title style={{margin: 0}} level={3}>Add coins to user balance</Title>
          <Title style={{margin: 0}} level={5} type='secondary'>{userId}</Title>
        </Flex>
      }
      onCancel={onClose}
      okButtonProps={{
        disabled: value === '' || value === '0' || value === '0.' || emptyBalanceRegex.test(value) || currency === 0 || loading,
        loading: loading
      }}
      onOk={handleTransaction}
    >
      <Flex vertical gap={10}>
        <Space.Compact style={{width: '100%'}} size='large'>
        <Input
          value={value}
          onChange={handleValueChange}
          placeholder='0.0000000'
        />
        <Select
          size='large'
          style={{width: 120}}
          placeholder={<><SearchOutlined/> Coin</>}
          value={currency === 0 ? undefined : currency}
          onChange={setCurrency}
          options={currencyOption}
          onClick={() => setIsCurrencySelectOpen(state => !state)}
          open={isCurrencySelectOpen}
          suffixIcon={
            <DownOutlined className={`select-icon ${isCurrencySelectOpen && 'select-icon_turned'}`}/>
          }
          dropdownRender={menu => (
            <Flex
              ref={assetRef}
              vertical
              gap={5}
            >
              {menu}
            </Flex>
          )}
        />
        </Space.Compact>
        <Flex gap={10}>
        <Select
          size='large'
          style={{width: '100%'}}
          placeholder='Select State'
          value={state === '' ? undefined : state}
          onChange={setState}
          options={stateOption}
          onClick={() => setIsStateSelectOpen(state => !state)}
          open={isStateSelectOpen}
          suffixIcon={
            <DownOutlined className={`select-icon ${isStateSelectOpen && 'select-icon_turned'}`}/>
          }
          dropdownRender={menu => (
            <Flex
              ref={stateRef}
              vertical
              gap={5}
            >
              {menu}
            </Flex>
          )}
        />
        <Select
          size='large'
          style={{width: '100%'}}
          placeholder='Select Type'
          value={type === '' ? undefined : type}
          onChange={setType}
          options={typeOption}
          onClick={() => setIsTypeSelectOpen(state => !state)}
          open={isTypeSelectOpen}
          suffixIcon={
            <DownOutlined className={`select-icon ${isTypeSelectOpen && 'select-icon_turned'}`}/>
          }
          dropdownRender={menu => (
            <Flex
              ref={typeRef}
              vertical
              gap={5}
            >
              {menu}
            </Flex>
          )}
        />
        </Flex>
        <Input
          size='large'
          placeholder='Comment'
          value={comment}
          onChange={({target}) => setComment(target.value)}
        />
      </Flex>
    </Modal>
  )
}

export default AddBalancePopup