import { Alert, Button, Card, Collapse, Flex, Input, Spin, Typography, message } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { FC, useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import Footer from 'components/Reusable/Footer/Footer'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { useUserContext } from 'Context/UserContext'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { acitiveProductI, earingI } from 'types/types'
import { api } from 'utilits/api'
import { convertSToTime } from 'utilits/converSToTime'
import Balance from 'components/Reusable/Balance/Balance'
import { getCryptoIcon } from 'utilits/getCryptoIcon'
import { FAQText } from 'consts/consts'
import { Helmet } from 'react-helmet'
import { displayUSDPrice } from 'utilits/displayUSDPrice'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
const { Text } = Typography

const Subscribe: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'subscribe'})
  const navigate = useNavigate()
  const { coin, productId } = useParams()
  const { user } = useUserContext()
  const asset = user?.assets && user.assets.find(a => a.ticker === coin)
  const availbleAmount = asset ? (asset.balance - asset.balance_earn) : 0
  const displayWidth = useDisplayWidth()
  const [value, setValue] = useState<string>('')
  const [product, setProduct] = useState<earingI|null>(null)
  const [activeProduct, setActiveProduct] = useState<acitiveProductI|null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [isBlocked, setIsBlocked] = useState<boolean>(true)
  
  const [errorApi, errorContext] = message.useMessage()
  const onError = (msg: string) => {
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }

  const handleValueChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value
    const valueAsNumber = Number(value)
    if(
        (!Number.isNaN(valueAsNumber) || target.value === ',') &&
        valueAsNumber <= (product?.amount_max || 0) &&
        valueAsNumber < availbleAmount &&
        valueAsNumber < availbleAmount &&
        valueAsNumber >= 0
      ) {
      setValue(value)
    }
  }

  const setMaxValue = () => {
    if(product && availbleAmount > 0)
      if(availbleAmount > product.amount_max) {
        setValue(String(product.amount_max))
      } else {
        setValue(String(availbleAmount))
      }
  }

  const subscribeToProduct = () => {
    const amount = Number(value)
    if(asset && amount !== 0 && product && amount <= product.amount_max && amount >= product.amount_min) {
      setIsLoading(true)
      api.subscribeToProduct(amount, product.id)
        .then(() => {
          setInProgress(true)
          getProduct()
        })
        .catch(err => onError(err.message))
        .finally(() => setIsLoading(false))
    }
  }

  const getProduct = () => {
    setIsLoading(true)
    api.getEarnings()
      .then((res: {earnings: earingI[], active: acitiveProductI}) => {
        const product = res.earnings.find(p => p.id === Number(productId))
        product === undefined || product.asset_ticker !== coin ? 
          navigate('/doesnt_exist') : 
          setProduct(res.earnings.find(p => p.id === Number(productId)) || null)
        setActiveProduct(res.active)
      })
      .catch(err => onError(err.message))
      .finally(() => setIsLoading(false))
  }

  const handleStakingEnd = () => {
    setInProgress(false)
    setActiveProduct(null)
    // getProduct()
  }

  useEffect(() => {
    const amount = Number(value)
    if(amount <= (product?.amount_max || 0) && amount >= (product?.amount_min || 0))
      {
        setIsBlocked(false)
      } else {
        setIsBlocked(true)
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if(user?.assets) {
      if(user?.assets && user.assets.find(a => a.ticker === coin)) {
        getProduct()
      } else {
        navigate('/earn')
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if(activeProduct && activeProduct.earning_id !== 0 && activeProduct.earning_id !== product?.id) {
      navigate('/earn')
    } else if(activeProduct && activeProduct?.earning_id === product?.id) {
      setValue(String(activeProduct.amount))
    }
    setInProgress(activeProduct !== null && activeProduct?.earning_id === product?.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProduct])

  useEffect(() => {
    product && user && (product.level !== user.account_level) && !user.premium && navigate('/earn')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, user])
  return (
    <>
    {errorContext}
    <Helmet>
      <title>{t('tab_title')}</title>
    </Helmet>
    <Balance />
    <Flex
      align='baseline'
      vertical
      gap={5}
      style={{width: '100%', maxWidth: 1500, margin: '0 auto', padding: displayWidth > 1000 ? '50px 50px 0' : '30px 20px 0'}}
    >
      <Title level={2}>{t('title')}</Title>
      {
        asset ? 
          <Flex gap={5} align='baseline' style={{opacity: .5}}>
            <img src={getCryptoIcon(asset.ticker)} alt={asset.ticker} width={18} height={18}/>
            <Title level={4}>{asset.ticker}</Title>
            <Title level={4} type='secondary' style={{margin: 0}}>{asset.name}</Title>
          </Flex> :
          <Spin />
      }
    </Flex>
    <Flex
      style={{width: '100%', maxWidth: 1500, margin: '0 auto', padding: displayWidth > 1000 ? 50 : '30px 20px'}}
      gap={30}
      wrap={displayWidth > 1100 ? undefined : 'wrap'}
      justify='space-between'
    >
      <Flex 
        vertical
        gap={10}
        style={{width: '100%', minWidth: 300, maxWidth: displayWidth > 700 ? '45%' : undefined}}
      >
        <Flex gap={5} vertical>
          <Text type='secondary'>{t('available')}: {availbleAmount?.toFixed(7)} {asset?.ticker}</Text>
          <Input
            size='large'
            value={value}
            onChange={handleValueChange}
            disabled={inProgress}
            suffix={
              <Button 
                type='link' 
                style={{color: inProgress ? '#000' : '#6663f1'}}
                onClick={setMaxValue}
                disabled={inProgress}
              >
                MAX
              </Button>
            }
          />
        </Flex>
      </Flex>
      <Flex 
        vertical
        gap={50}
        style={{width: '100%', minWidth: 300, maxWidth: displayWidth > 700 ? '45%' : undefined}}
      >
        <Card
          style={{minHeight: 300}}
        >
          {
            isLoading ? <Spin /> :
              <>
                <Flex vertical gap={10}>
                  <Title level={4}>{t('summary')}</Title>
                  <Flex
                    style={{width: '100%'}}
                    justify='space-between'
                  >
                    <Text type='secondary'>{t('price')}</Text>
                    <Text>{displayUSDPrice(asset?.price_usdt || 0)}</Text>
                  </Flex>
                  <Flex
                    style={{width: '100%'}}
                    justify='space-between'
                  >
                    <Text type='secondary'>{t('amount')}</Text>
                    <Text>{value || 0} {asset?.ticker}</Text>
                  </Flex>
                  <Flex
                    style={{width: '100%'}}
                    justify='space-between'
                  >
                    <Text type='secondary'>{t('amount_range')}</Text>
                    <Text>{product?.amount_min} / {product?.amount_max} {asset?.ticker}</Text>
                  </Flex>
                  <Flex
                    style={{width: '100%'}}
                    justify='space-between'
                  >
                    <Text type='secondary'>{t('fee')}</Text>
                    <Text>
                      {
                        inProgress ?
                          activeProduct?.staking_fee : '0.001'
                      }
                      {' '}{asset?.ticker}
                    </Text>
                  </Flex>
                  <Flex
                    style={{width: '100%'}}
                    justify='space-between'
                  >
                    <Text type='secondary'>{t('duration')}</Text>
                    <Text>
                      {
                        inProgress ?
                          <Countdown
                            key={activeProduct?.finished_at}
                            date={activeProduct === null ? '3000-06-14T14:01:53.796887Z' : activeProduct?.finished_at}
                            renderer={
                              ({days, hours, minutes, seconds}) => <>{days > 0 && days + 'd'} {hours > 0 && hours + 'h'} {minutes}min {seconds}s</>
                            }
                            onComplete={() => handleStakingEnd()}
                          /> :
                          (
                            product?.duration !== 0 ?
                              convertSToTime(product?.duration || 0) :
                              product?.duration_type
                          )
                      }
                    </Text>
                  </Flex>
                  <Flex
                    style={{width: '100%'}}
                    justify='space-between'
                  >
                    <Text type='secondary'>{t('earnings')}</Text>
                    <Flex gap={3}>
                      <Text style={{color: '#33c864'}} strong>
                        {
                          `+ ${
                            inProgress ?
                            Number(activeProduct?.estimated_earnings).toFixed(7) :
                            (Number(value) * (product?.apr_max || 1) * 0.01).toFixed(7)
                          } 
                          ${asset?.ticker}`
                        } 
                      </Text>  
                      <Text type='secondary'>
                        ({displayUSDPrice(
                          inProgress ?
                          Number((activeProduct?.estimated_earnings || 0) * (asset?.price_usdt || 0)) :
                          Number(value) * (product?.apr_max || 1) * 0.01 * (asset?.price_usdt || 0)
                        )})
                      </Text>
                    </Flex>
                  </Flex>
                  <Button
                    size='large'
                    type='primary'
                    style={{marginTop: 50}}
                    disabled={inProgress || isBlocked}
                    onClick={subscribeToProduct}
                  >
                    {
                      inProgress ?
                        <Text><LoadingOutlined/> {t('progress_btn')}</Text> :
                        t('stake_btn')
                    }
                  </Button>
                </Flex>
              </>
          }
        </Card>
      </Flex>
      <Flex 
        vertical
        gap={50}
        style={{width: '100%', minWidth: 300}}
      >
        <Collapse 
          items={FAQText}
          expandIconPosition='end'
          ghost
          expandIcon={(props) => 
            <div 
              style={{
                padding: 8, borderRadius: '50%',
                background: props.isActive ? '#6663f1' : '#f2f0ff', 
                transform: props.isActive ? 'rotate(180deg)' : undefined,
                color: props.isActive ? '#f2f0ff' : '#6663f1',
                boxShadow: props.isActive ? '0 .5rem 1.125rem -0.5rem rgba(99,102,241,.9)' : undefined,
                margin: 'auto',
              }}
            >
              <DownOutlined />
            </div>
          }
        />
      </Flex>
    </Flex>
    <Footer />
    <Outlet />
    </>
  )
}

export default Subscribe