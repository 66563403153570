import { gray, purple } from "@ant-design/colors";

export const getStyles = (displayWidth: number) => {
  return {
    section: {
      background: `linear-gradient(315deg, ${purple[8]} 0%, #0c0f19 30%)`,
      width: '100%',
      
    },
    content: {
      maxWidth: 1500,
      padding: displayWidth > 800 ? 50 : 20,
      width: '100%',
    },
    discribtion: {
      width: displayWidth > 900 ? '50%' : "100%",
      maxWidth: displayWidth > 900 ? 600 : "100%",
    },
    title: {
      color: '#fff',
      fontWeight: 800
    },
    caption: {
      color: gray[3],
      fontSize: 18
    },
    // button: {
    //   padding: displayWidth > 1200 ? '10px 35px' : '5px 10px',
    // },
    users: {
      text: {color: gray[1]},
      strong: {color: '#fff'}
    }
  }
}