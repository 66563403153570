import { Flex, Input, Modal, Radio } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import EditSetsPopupProps from './EditSetsPopupProps'

const EditSetsPopup: FC<EditSetsPopupProps> = ({isOpen, settings, loading, onClose, onEdit}) => {
  const [value, setValue] = useState<string>('')

  const handleEdit = () => {
    onEdit({
      key: settings?.key || '',
      value: value
    })
  }

  useEffect(() => {
    settings && setValue(settings.value)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={`Change ${settings?.key} value`}
      onOk={handleEdit}
      okButtonProps={{
        disabled: value.trim() === settings?.value || loading,
        loading: loading
      }}
    >
      {
        (settings?.value === 'false' || settings?.value ==='true') ?
          <Flex align='center' gap={10}>
            Value: 
            <Radio.Group value={value} size='small' onChange={({target}) => setValue(target.value)}>
              <Radio.Button value='true'>True</Radio.Button>
              <Radio.Button value='false'>False</Radio.Button>
            </Radio.Group>
          </Flex>
           :
          <Input 
            size='large'
            placeholder='Value'
            value={value}
            onChange={({target}) => setValue(target.value)}
          />
      }
      
    </Modal>
  )
}

export default EditSetsPopup