import { Button, Result } from 'antd'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'

const SuccessReset: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'success_reset'})
  const navigate = useNavigate()
  return (
    <>
      <Helmet>
        <title>{t('tab_title')}</title>
      </Helmet>
      <Result
        status='success'
        title={t('title')}
        extra={
          <Button 
            type="primary"
            onClick={() => navigate('/sign-in')}
          >
            {t('button')}
          </Button>
        }
      />
      <Outlet/>
    </>
  )
}

export default SuccessReset