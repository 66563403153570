import { Flex, Button, Collapse, Typography } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
const { Text, Title } = Typography

const Links: FC<{ displayWidth: number }> = ({ displayWidth }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'footer.links' })
  return displayWidth > 1100 ? (
    <>
      <Flex vertical gap={30} wrap='wrap' style={{ maxHeight: 320 }}>
        <Flex vertical gap={10}>
          <Link to='/'>
            <Button type='link'>{t('home')}</Button>
          </Link>
          <Link to='/events'>
            <Button type='link'>{t('events')}</Button>
          </Link>
          <Link to='/market-news'>
            <Button type='link'>{t('market_news')}</Button>
          </Link>
          <Link to='/market-heatmap'>
            <Button type='link'>{t('market_heatmap')}</Button>
          </Link>
          <Link to='/conventor'>
            <Button type='link'>{t('conventor')}</Button>
          </Link>
        </Flex>
        <Flex vertical gap={10}>
          <Link to='/terms_and_conditions'>
            <Button type='link'>{t('terms_and_conditions')}</Button>
          </Link>
          <Link to='/privacy'>
            <Button type='link'>{t('privacy')}</Button>
          </Link>
        </Flex>
      </Flex>
      <Flex vertical>
        <Title level={5}>{t('contact')}</Title>
        <Button
          type='link'
          href={window.env.APP_CONTACT_US_LINK}
          target='_blank'
          style={{ padding: 0, margin: 0, color: '#6663f1' }}
        >
          {window.env.APP_CONTACT_US_EMAIL}
        </Button>
      </Flex>
    </>
  ) : (
    <Flex vertical gap={10}>
      <Collapse
        ghost
        expandIconPosition='end'
        style={{ minWidth: 180 }}
        items={[
          {
            key: 1,
            label: (
              <Text strong style={{ fontSize: 16 }}>
                {t('title')}
              </Text>
            ),
            children: (
              <Flex vertical>
                <Link to='/'>
                  <Button type='link'>{t('home')}</Button>
                </Link>
                <Link to='/events'>
                  <Button type='link'>{t('events')}</Button>
                </Link>
                <Link to='/market-news'>
                  <Button type='link'>{t('market_news')}</Button>
                </Link>
                <Link to='/market-heatmap'>
                  <Button type='link'>{t('market_heatmap')}</Button>
                </Link>
                <Link to='/conventor'>
                  <Button type='link'>{t('conventor')}</Button>
                </Link>
                <Link to='/terms_and_conditions'>
                  <Button type='link'>{t('terms_and_conditions')}</Button>
                </Link>
                <Link to='/privacy'>
                  <Button type='link'>{t('privacy')}</Button>
                </Link>
              </Flex>
            ),
          },
        ]}
      />
      <Flex vertical>
        <Title level={5}>{t('contact')}</Title>
        <Button
          type='link'
          href={window.env.APP_CONTACT_US_LINK}
          target='_blank'
          style={{
            padding: 0,
            margin: 0,
            textAlign: 'start',
            color: '#6663f1',
          }}
        >
          {window.env.APP_CONTACT_US_EMAIL}
        </Button>
      </Flex>
    </Flex>
  )
}

export { Links }
