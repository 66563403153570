import React, { FC } from 'react'
import { transactionI } from 'types/types'
import TransactionsTableProps from './TransactionsTableProps'
import { ConfigProvider, Flex, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { getCryptoIcon } from 'utilits/getCryptoIcon'
import { getTransactionStatus, getTransactionType } from 'utilits/tagsColorFunctions'
import { displayUSDPrice } from 'utilits/displayUSDPrice'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { useTranslation } from 'react-i18next'
const {Text} = Typography

const TransactionsTable: FC<TransactionsTableProps> = ({data, loading, admin}) => {
  const {t} = useTranslation('translation', {keyPrefix: 'transactions_table'})
  const displayWidth = useDisplayWidth()

  const tableColumns: ColumnsType<transactionI> = [
    {
      title: t('asset'),
      key: 'asset',
      width: displayWidth > 500 ? 130 : 90,
      render: (_, {asset_name, asset_ticker}) => 
        <Flex gap={10} align='center'>
          <img src={getCryptoIcon(asset_ticker)} alt={asset_ticker} width={30} height={30}/>
          <Flex vertical>
            <Text strong>{asset_ticker}</Text>
            { displayWidth > 500 && <Text type='secondary'>{asset_name}</Text> }
          </Flex>
        </Flex>
    },
    {
      title: t('time'),
      key: 'time',
      render: (_, {created_at}) => 
        <Text>
          {new Date(created_at).toLocaleString(undefined, {timeZone: 'UTC'})}
        </Text>
    },
    {
      title: t('comment'),
      key: 'comment',
      width: 220,
      render: (_, {comment}) => <Text>{comment}</Text>,
      ...(!admin && {responsive: ['lg']})

    },
    {
      title: t('type'),
      key: 'type',
      render: (_, {type}) => getTransactionType(type),
      ...(!admin && {responsive: ['sm']})
    },
    {
      title: t('status'),
      key: 'status',
      render: (_, {state}) => getTransactionStatus(state),
      ...(!admin && {responsive: ['sm']})
    },
    {
      title: '',
      key: 'status',
      width: 45,
      render: (_, {state}) => getTransactionStatus(state, true),
      ...(!admin && {responsive: ['xs']})
    },
    {
      title: t('amount'),
      key: 'amount',
      width: 140,
      render: (_, {amount, price_usdt, asset_ticker}) => 
        <Flex vertical align='end'>
          <Text strong style={{textWrap: 'nowrap'}}>{amount.toFixed(7)} {asset_ticker}</Text>
          <Text type='secondary'>{displayUSDPrice(amount * price_usdt)}</Text>
        </Flex>
    },
  ]
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 16
        },
        components: {
          Table: {
            headerColor: '#b0b0b0',
            rowExpandedBg: 'transparent',
            colorBgContainer: 'transparent',
            headerBg: 'transparent',
            borderColor: 'transparent',
            headerSplitColor: 'transparent',
          },
        }
      }}
    >
      <Table
        loading={loading}
        columns={tableColumns}
        size='small'
        dataSource={data}
        pagination={false}
        rowKey={r => r.id}
        scroll={admin ? {x: 1000} : undefined}
      />
    </ConfigProvider>
  )
}

export default TransactionsTable