import { EditOutlined } from "@ant-design/icons"
import { TableColumnsType, Button } from "antd"
import { adminSettingsI } from "types/adminTypes"

const getSettingsColumns = (onOpenSettings: (sets:adminSettingsI|null) => void): TableColumnsType<adminSettingsI> => [
  {
    title: 'Key',
    key: 'key',
    dataIndex: 'key'
  },
  {
    title: 'Value',
    key: 'value',
    dataIndex: 'value',
  },
  {
    key: 'edit',
    align: 'end',
    render:(_, sets) => 
      <Button
        type='text'
        style={{padding: '5px 10px'}}
        onClick={() => onOpenSettings(sets)}
      >
        <EditOutlined />
      </Button>
  }
]

export {
  getSettingsColumns
}