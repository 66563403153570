import { LoadingOutlined, CheckOutlined, CloseOutlined, PauseOutlined, WarningOutlined } from "@ant-design/icons"
import { Tag } from "antd"

const getLevelColor = (level: number) => {
  switch(level) {
    case 1:
      return '#e1e2e3'
    case 2: 
      return '#ffba06'
    case 3: 
      return '#21c55d'
  }
}

const getKYCColor = (status: string) => {
  switch(status) {
    case 'Unverified':
      return '#e1e2e3'
    case 'Verification': 
      return '#ffba06'
    case 'Verified': 
      return '#21c55d'
    case 'Rejected': 
      return '#ef4444'
  }
}

const getTransactionStatus = (status: string, mobile?: boolean) => {
  switch(status) {
    case 'Pending':
      return <Tag color='#ffba061f' style={{margin: 0, color: '#ffba06'}}> <LoadingOutlined />  {!mobile &&status}</Tag>
    case 'Success':
      return <Tag color='#21c55d1f' style={{margin: 0, color: '#21c55d'}}> <CheckOutlined />  {!mobile && status}</Tag>
    case 'Cancelled':
      return <Tag color='#ef44441f' style={{margin: 0, color: '#ef4444'}}> <CloseOutlined />  {!mobile && status}</Tag>
    case 'Frozen':
      return <Tag color='#4c82f71f' style={{margin: 0, color: '#4c82f7'}}> <PauseOutlined />  {!mobile && status}</Tag>
    case 'Failed':
      return <Tag color='#ffba061f' style={{margin: 0, color: '#ffba06'}}> <WarningOutlined />  {!mobile && status}</Tag>
  }
}

const getTransactionType = (type: string) => {
  return <Tag color='#f3f6ff' style={{margin: 0, color: '#33354d'}}>{type}</Tag>
}

const getBooleanTag = (condition: boolean, acivity?: boolean) => {
  return (
    <Tag color={condition ? '#21c55d' : '#ef4444'}>
      {acivity ? (condition ? 'Active' : 'Disabled') : String(condition)}
    </Tag>
  )
}

export {
  getLevelColor,
  getKYCColor,
  getTransactionStatus,
  getTransactionType,
  getBooleanTag
}