import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import i18n from 'translations/i18n'
import { I18nextProvider } from 'react-i18next'
import LoadingPage from 'components/LoadingPage/LoadingPage'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

declare global {
  interface Window { env: any; }
}

root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route path='*' element={ <App /> } />
        </Routes>
      </Suspense>
    </I18nextProvider>
  </BrowserRouter>

)
