import { EditOutlined } from "@ant-design/icons"
import { TableColumnsType, Flex, Button, Typography } from "antd"
import { adminEaringI } from "types/adminTypes"
import { convertSToTime } from "utilits/converSToTime"
import { getCryptoIcon } from "utilits/getCryptoIcon"
import { getBooleanTag } from "utilits/tagsColorFunctions"
const {Text, Title} = Typography

const getEarningsColumns = (onSelectEarning: (earing: adminEaringI) => void): TableColumnsType<adminEaringI> => [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id'
  },
  {
    title: 'Coin',
    key: 'coin',
    render: (_, {asset_name, asset_ticker}) => 
      <Flex align='center' gap={5}>
        <img src={getCryptoIcon(asset_ticker)} alt={asset_ticker} style={{width: 30, height: 30}}/>
        <Flex vertical>
          <Title level={5} style={{margin: 0}}>{asset_ticker}</Title>
          <Title level={5} style={{margin: 0}} type='secondary'>{asset_name}</Title>
        </Flex>
      </Flex>
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: 'APR',
    key: 'apr',
    render: (_, {apr_min, apr_max}) =>
      <Text>{apr_min}-{apr_max}%</Text>
  },
  {
    title: 'Amount',
    key: 'amount',
    render: (_, {amount_min, amount_max, asset_ticker}) =>
      <Text>{amount_min}-{amount_max} {asset_ticker}</Text>
  },
  {
    title: 'Duration Type',
    key: 'duration_type',
    render: (_, {duration_type, duration}) => 
      <Flex gap={5}>
        <Text>{duration_type}</Text>
        <Text type='secondary'>{convertSToTime(duration)}</Text>
      </Flex>
  },
  {
    title: 'Level',
    key: 'level',
    dataIndex: 'level',
  },
  {
    title: 'Active',
    key: 'active',
    align: 'center',
    render: (_, {active}) => getBooleanTag(active, true)
  },
  {
    title: 'Created At',
    key: 'created_at',
    render: (_, {created_at}) => 
      <Text>
        {new Date(created_at).toLocaleString(undefined, {timeZone: 'UTC'})}
      </Text>
  },
  {
    key: 'edit',
    render: (_, asset) => 
      <Button
        type='primary'
        size='small'
        onClick={() => onSelectEarning(asset)}
      >
        <EditOutlined /> Edit
      </Button>
  },
]

export {
  getEarningsColumns
}