import { Button, Flex, Popconfirm, TableColumnsType, Typography } from "antd"
import { adminAssetsI } from "types/adminTypes"
import { getCryptoIcon } from "utilits/getCryptoIcon"
import { getDateDiff } from "utilits/getDateDiff"
import { getBooleanTag } from "utilits/tagsColorFunctions"
const {Text, Title} = Typography

const getAssetsColumns = (onSwitchAsset: (id: number, state: boolean) => void): TableColumnsType<adminAssetsI> => [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id'
  },
  {
    title: 'Coin',
    key: 'coin',
    render: (_, {name, ticker}) => 
      <Flex align='center' gap={5}>
        <img src={getCryptoIcon(ticker)} alt={ticker} style={{width: 30, height: 30}}/>
        <Flex vertical>
          <Title level={5} style={{margin: 0}}>{ticker}</Title>
          <Title level={5} style={{margin: 0}} type='secondary'>{name}</Title>
        </Flex>
      </Flex>
  },
  {
    title: 'Depositable',
    key: 'depositable',
    align: 'center',
    render: (_, {depositable}) => getBooleanTag(depositable)
  },
  {
    title: 'Created At',
    key: 'created_at',
    render: (_, {created_at}) => 
      <Text>
        {new Date(created_at).toLocaleString(undefined, {timeZone: 'UTC'})}
      </Text>
  },
  {
    title: 'Updated At',
    key: 'updated_at',
    render: (_, {updated_at}) => 
      <Text>
        {getDateDiff(updated_at)}
      </Text>
  },
  {
    title: 'Active',
    key: 'active',
    align: 'center',
    render: (_, {active, ticker, id}) => 
      <Popconfirm
        icon={false}
        cancelButtonProps={{type: 'primary', size: 'small'}}
        okButtonProps={{size: 'small'}}
        placement='left'
        title={
          <Title level={5} style={{color: '#fff'}}>
            You sure to {active ? 'disable' : 'acivate'} {ticker} asset?
          </Title>
        }
        onConfirm={() => onSwitchAsset(id, active)}
      >
        <Button type='link' style={{padding: 0}}>
          {getBooleanTag(active, true)}
        </Button>
      </Popconfirm>
  },
]

export {
  getAssetsColumns
}