import React, { FC, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import './Landing.scss'
import { ConfigProvider, Flex, FloatButton } from 'antd'
import Banner from './Banner/Banner'
// import Partners from './Partners/Partners'
import Traiding from './Traiding/Traiding'
import Opportunities from './Opportunities/Opportunities'
import Tokens from './Tokens/Tokens'
import Tutorial from './Tutorial/Tutorial'
// import Video from './Video/Video'
import { gray } from '@ant-design/colors'
// import Feedback from './Feedback/Feedback'
import { UpOutlined } from '@ant-design/icons'
import CTA from './CTA/CTA'
import Footer from '../Reusable/Footer/Footer'
// import Market from './Market/Market'
import { useInView } from 'react-intersection-observer'
import { Transition } from 'react-transition-group'
import { defaultStyle, transitionStyles } from './LandingServe'
import { useDisplayWidth } from '../../utilits/useDisplayWidth'
import { Helmet } from 'react-helmet'

const Landing: FC = () => {
  const { ref: bannerRef, inView } = useInView()
  const buttonRef = useRef<any>()
  const displayWidth = useDisplayWidth()
  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            fontSizeHeading1: displayWidth > 1200 ? 60 : (displayWidth > 750 ? 50 : 45),
            fontSizeHeading2: displayWidth > 1200 ? 44 : 34,
            fontSizeHeading3: displayWidth > 1200 ? 24 : 20,
            fontSizeHeading5: 14,
            lineHeight: 1.3,
            fontSize: displayWidth > 1200 ? 16 : 14,
          },
          Modal: {
            contentBg: gray[9]
          }
        }
      }}
    >
      <Helmet>
        <title>Staking platform</title>
      </Helmet>
      <Flex
        vertical
        style={{width: '100%'}}
      >
        <Flex
          style={{width: '100%'}}
          ref={bannerRef}
        >
          <Banner/>
        </Flex>
        {/* <Partners /> */}
        {/* <Market /> */}
        {/* <Divider /> */}
        <Traiding />
        <Opportunities />
        <Tokens />
        <Tutorial />
        {/* <Video /> */}
        {/* <Feedback /> */}
        <CTA />
        <Footer />
        <Transition nodeRef={buttonRef} in={!inView} timeout={2}>
          {(state) => (
            <FloatButton 
              ref={buttonRef}
              style={{...defaultStyle, ...(state !== 'unmounted' && transitionStyles[state])}} 
              icon={<UpOutlined />} 
              onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'})}}
            />
          )}
        </Transition>
        <Outlet/>
      </Flex>
    </ConfigProvider>
  )
}

export default Landing