import { DownOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import { useOutsideClick } from '@chakra-ui/react'
import { Button, Collapse, Flex, Popover } from 'antd'
import React, { FC, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useUserContext } from 'Context/UserContext'
import { useTranslation } from 'react-i18next'
import LangsSelect from './LangsSelect'

const Menu: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'header'})
  const langs = window.env.APP_LANGS.split(',')
  
  const popRef = useRef<any>()
  const user = useUserContext()

  const [isPopOpen, setIsPopOpen] = useState<boolean>(false)

  const navigate = useNavigate()
  const location = useLocation()

  const handleLogOut = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('isBalanceShown')
    user.changeUser && user.changeUser(null)
    navigate('/')
  }

  useOutsideClick({
    ref: popRef,
    handler: () => setIsPopOpen(false)
  })
  return (
      <Flex
        style={{width: '100%'}}
        gap={10}
        align={'center'}
      >
        <Flex 
          gap={10}
        >
          <Link to='/'>
            <Button 
              size='large'
              type='link'
              style={{
                ...(location.pathname === '/' && { color: '#6663f1' })
              }}
            >
              {t('home')}
            </Button>
          </Link>
          <Link to='/events'>
            <Button 
              size='large'
              type='link'
              style={{
                ...(location.pathname === '/events' && { color: '#6663f1' })
              }}
            >
              {t('events')}
            </Button>
          </Link>
          <Link to='/market-news'>
            <Button 
              size='large'
              type='link'
              style={{
                ...(location.pathname === '/market-news' && { color: '#6663f1' })
              }}
            >
              {t('market_news')}
            </Button>
          </Link>
          <Link to='/market-heatmap'>
            <Button 
              size='large'
              type='link'
              style={{
                ...(location.pathname === '/market-heatmap' && { color: '#6663f1' })
              }}
            >
              {t('market_heatmap')}
            </Button>
          </Link>
          <Link to='/conventor'>
            <Button 
              size='large'
              type='link'
              style={{
                ...(location.pathname === '/conventor' && { color: '#6663f1' })
              }}
            >
              {t('conventor')}
            </Button>
          </Link>
        </Flex>
        {
          localStorage.getItem('token') ? 
          <Flex
            style={{marginLeft: 'auto'}}
            gap={10}
          >
            <Link to='/assets'>
              <Button 
                size='large'
                type='link'
                style={{
                  padding: 0, textAlign: 'start',
                  ...(location.pathname === '/assets' && { color: '#6663f1' })
                }}
              >
                {t('assets')}
              </Button>
            </Link>
            <Link to='/earn'>
              <Button 
                size='large'
                type='link'
                style={{
                  padding: 0, textAlign: 'start',
                  ...(location.pathname === '/earn' && { color: '#6663f1' })
                }}
              >
                {t('earn')}
              </Button>
            </Link>
            <Popover
              open={isPopOpen}
              placement='bottom'
              arrow={false}
              style={{border: 'solid 5px red'}}
              content={
                <Flex
                  ref={popRef}
                  vertical
                  align='start'
                >
                  <Link to='/account'>
                    <Button 
                      size='large'
                      type='link'
                      style={{
                        ...(location.pathname === '/account' && { color: '#6663f1' })
                      }}
                      onClick={() => setIsPopOpen(false)}
                    >
                      {t('account')}
                    </Button>
                  </Link>
                  <Link to='/deposit'>
                    <Button 
                      size='large'
                      type='link'
                      style={{
                        ...(location.pathname === '/deposit' && { color: '#6663f1' })
                      }}
                      onClick={() => setIsPopOpen(false)}
                    >
                      {t('deposit')}
                    </Button>
                  </Link>
                  <Link to='/withdraw'>
                    <Button
                      size='large'
                      type='link'
                      style={{
                        ...(location.pathname === '/withdraw' && { color: '#6663f1' })
                      }}
                      onClick={() => setIsPopOpen(false)}
                    >
                      {t('withdraw')}
                    </Button>
                  </Link>
                  {
                    user.user && user.user.pro &&
                    <Collapse 
                      ghost
                      expandIconPosition='end'
                      expandIcon={(props) => 
                        <div 
                          style={{
                            transform: props.isActive ? 'rotate(180deg)' : undefined,
                            color: props.isActive ? '#6663f1' : '#f2f0ff',
                            margin: '5px 0 0'
                          }}
                        >
                          <DownOutlined />
                        </div>
                      }
                      items={[{
                        label: 
                          <Button 
                            size='large'
                            type='link'
                          >
                            {t('admin.admin_panel')}
                          </Button>,
                        children: 
                          <Flex vertical style={{paddingLeft: 10}} align='start'>
                            <Link to='/admin/assets'>
                              <Button
                                size='large'
                                type='link'
                                style={{
                                  ...(location.pathname === '/admin/assets' && { color: '#6663f1' })
                                }}
                                onClick={() => setIsPopOpen(false)}
                              >
                                {t('admin.assets')}
                              </Button>
                            </Link>
                            <Link to='/admin/users'>
                              <Button
                                size='large'
                                type='link'
                                style={{
                                  ...(location.pathname === '/admin/users' && { color: '#6663f1' })
                                }}
                                onClick={() => setIsPopOpen(false)}
                              >
                                {t('admin.users')}
                              </Button>
                            </Link>
                            <Link to='/admin/invitation-codes'>
                              <Button
                                size='large'
                                type='link'
                                style={{
                                  ...(location.pathname === '/admin/invitation-codes' && { color: '#6663f1' })
                                }}
                                onClick={() => setIsPopOpen(false)}
                              >
                                {t('admin.codes')}
                              </Button>
                            </Link>
                            <Link to='/admin/settings'>
                              <Button
                                size='large'
                                type='link'
                                style={{
                                  ...(location.pathname === '/admin/settings' && { color: '#6663f1' })
                                }}
                                onClick={() => setIsPopOpen(false)}
                              >
                                {t('admin.settings')}
                              </Button>
                            </Link>
                            <Link to='/admin/earnings'>
                              <Button
                                size='large'
                                type='link'
                                style={{
                                  ...(location.pathname === '/admin/earnings' && { color: '#6663f1' })
                                }}
                                onClick={() => setIsPopOpen(false)}
                              >
                                {t('admin.earnings')}
                              </Button>
                            </Link>
                            <Link to='/admin/withdrawals'>
                              <Button
                                size='large'
                                type='link'
                                style={{
                                  ...(location.pathname === '/admin/withdrawals' && { color: '#6663f1' })
                                }}
                                onClick={() => setIsPopOpen(false)}
                              >
                                {t('admin.withdrawals')}
                              </Button>
                            </Link>
                          </Flex>
                    }]}/>
                  }
                  <Button
                    type='primary'
                    size='small'
                    onClick={handleLogOut}
                    style={{marginTop: 5, width: '100%'}}
                  >
                    <LogoutOutlined /> {t('sign_out')}
                  </Button>
                </Flex>
              }
            >
              <Button
                size='large'
                style={{marginLeft: 'auto', padding: 0}}
                type='link'
                onClick={() => setIsPopOpen(s => !s)}
              >
                <UserOutlined />
              </Button>
            </Popover>
            <Link to='/security'>
              <Button 
                size='large'
                type='link'
                style={{
                  padding: 0, textAlign: 'start',
                  ...(location.pathname === '/security' && { color: '#6663f1' })
                }}
                onClick={() => setIsPopOpen(false)}
              >
                <SettingOutlined />
              </Button>
            </Link>
          </Flex> :
            <Flex
              gap={20} 
              style={{marginLeft: 'auto'}}
            >
              <Link to='/sign-in'><Button type='link' style={{padding: 0}}>{t('sign_in')}</Button></Link>
              <Link to='/sign-up'><Button type='primary' style={{maxWidth: 150, textWrap: 'wrap'}}>{t('sign_up')}</Button></Link>
            </Flex> 
        }
        { langs.length > 1 && <LangsSelect /> }
      </Flex>
  )
}

export default Menu