import React, { FC, useEffect, useState } from 'react'
import LevelPopupProps from './LevelPopupProps'
import { Flex, Modal, Radio } from 'antd'
import Title from 'antd/es/typography/Title'

const LevelPopup: FC<LevelPopupProps> = ({isOpen, userId, userLevel, loading, onClose, onChangeLevel}) => {
  const [level, setLevel] = useState<number>(1)

  useEffect(() => {
    setLevel(userLevel)
  }, [userLevel, userId])
  return (
    <Modal
      open={isOpen}
      title={
        <Flex gap={10} align='baseline'>
          <Title style={{margin: 0}} level={3}>Change user level</Title>
          <Title style={{margin: 0}} level={5} type='secondary'>{userId}</Title>
        </Flex>
      }
      onCancel={onClose}
      okButtonProps={{
        disabled: level === userLevel || loading,
        loading: loading
      }}
      onOk={() => onChangeLevel(userId, level)}
    >
      <Radio.Group style={{width: '100%'}} value={level} onChange={({target}) => setLevel(target.value)}>
        <Radio.Button style={{width: '33.33%', textAlign: 'center'}} value={1}>Level 1</Radio.Button>
        <Radio.Button style={{width: '33.33%', textAlign: 'center'}} value={2}>Level 2</Radio.Button>
        <Radio.Button style={{width: '33.33%', textAlign: 'center'}} value={3}>Level 3</Radio.Button>
      </Radio.Group>
    </Modal>
  )
}

export default LevelPopup