import React, { FC } from 'react'
import { Flex, Typography } from 'antd'
import Step from './Step'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { useTranslation } from 'react-i18next'
const { Text, Title } = Typography

const Tutorial: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'landing.tutorial'})
  const displayWidth = useDisplayWidth()
  const steps = [1,2,3,4]
  return (
    <Flex
      vertical
      gap={displayWidth > 800 ? 30 : 10}
      align='center'
      style={{padding: displayWidth > 1000 ? 50 : 10, maxWidth: 1400, margin: '0 auto'}}
    >
      <Title level={2} style={{fontWeight: 800}}>{t('title')}</Title>
      <Text type='secondary' style={{maxWidth: 500, textAlign: 'center'}}>
        {t('description', {app_name: window.env.APP_TITLE})}
      </Text>
      <Flex
        justify='center'
        align={displayWidth < 800 ? 'center' : undefined}
        vertical={displayWidth < 800}
        style={{width: '100%'}}
      >
        {
          steps.map((s) => <Step key={s} step={s} />)
        }
      </Flex>
    </Flex>
  )
}

export default Tutorial