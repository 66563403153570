import bg from 'images/signin-bg.jpg'
import type * as CSS from 'csstype'

const getStyles = (displayWidth: number): {[key: string]: CSS.Properties} => {
  return {
    form: {
      display: 'flex', 
      justifyContent: displayWidth > 1200 ? 'end' : 'center', 
      width: displayWidth > 1200 ? '50%' : '100%',
      backgroundColor: '#fff', 
      padding: 'auto 10px', 
      height: 'calc(100svh - 64px)'
    },
    formLayout: {
      padding: displayWidth > 1200 ? '50px' : '50px 20px',
      width: '100%',
      maxWidth: '750px',
    },
    fullWidth: {
      width: '100%'
    },
    checkbox: {
      color: 'grey',
      width: 'max-content',
      padding:0
    },
    checkboxButton: {
      padding: 0,
      marginLeft: '-6px'
    },
    image: {
      display: 'flex',
      width: displayWidth > 1200 ? '50%' : 0,
      backgroundImage: `url(${bg}`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }
  }
}

export {getStyles}