import { Alert, Button, Checkbox, Flex, Input, Typography, message } from 'antd'
import { CheckboxProps } from 'antd/es/checkbox'
import Title from 'antd/es/typography/Title'
import React, { FC, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { styles } from './LoginStyles'
import { LockTwoTone, MailTwoTone } from '@ant-design/icons'
import { purple } from '@ant-design/colors'
import { mailRegex } from 'consts/consts'
import { useUserContext } from 'Context/UserContext'
import { api } from 'utilits/api'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
const { Text } = Typography

const Login: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'sign_in'})
  const navigate = useNavigate()
  const user = useUserContext()

  const [password, setPassword] = useState<string>('')
  const [mail, setMail] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rememberCheckbox, setRememberCheckbox] = useState<boolean>(false)

  const [isMailError, setIsMailError] = useState<boolean>(false)
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false)
  const [apiError, setApiError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  const [errorApi, errorContext] = message.useMessage()

  const onError = (msg: string) => {
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }

  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    setIsPasswordError(false)
  }
  const changeMail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value)
    setIsMailError(false)
  }
  const changeRememberCheckbox: CheckboxProps['onChange'] = (e) => {
    setRememberCheckbox(e.target.checked)
  }

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!mailRegex.test(mail.trim())) {
      setIsMailError(true)
    } else if(password.trim() === '') {
      setIsPasswordError(true)
    } else {
      setApiError('')
      setIsLoading(true)
      api.login(mail, password)
        .then((res: {token: string, admin: boolean}) => {
          localStorage.setItem('token', res.token)
        })
        .then(() => {
          api.getUserInfo()
            .then(userInfo => {
              api.getUserAssets()
                .then(assets => {
                  user.changeUser && user.changeUser({
                    ...userInfo,
                    assets: assets.assets
                  })
                })
                .catch((err) => {
                  onError(err.message)
                  setIsLoading(false)
                })
            })
            .catch((err) => {
              onError(err.message)
              setIsLoading(false)
            })
        })
        .then(() => navigate('/'))
        .catch(err => {
          setApiError(err.message)
          setIsLoading(false)
        })
    }
  }
  
  const handleError = () => {
    if (isMailError) {
      return t('mail_error')
    } else if (isPasswordError) {
      return t('password_error')
    } else if (apiError) {
      return apiError
    }
  }

  return (
    <>
    {errorContext}
    <Helmet>
      <title>{t('tab_title')}</title>
    </Helmet>
    <form 
      style={styles.form}
      onSubmit={handleLogin}
    >
      <Flex
        vertical
        gap={30}
        style={styles.formLayout}
      >
        <Flex
          vertical
          gap={10}
          style={styles.fullWidth}
          align='center'
        >
          <Title level={1}>{t('title')}</Title>
          <Flex 
            gap={5} 
            align='center'
          >
            <Trans i18nKey='sign_in.reg_link'>
            <Text type='secondary'>Don't have an account yet?</Text>
            <Button 
              type='link'
              style={{padding: 0}}
              onClick={() => navigate('/sign-up')}
            >
              Register here.
            </Button>
            </Trans>
          </Flex>
        </Flex>
        <Flex
          vertical
          gap={5}
        >
          {
            (isPasswordError || isMailError || apiError) && <Alert message={handleError()} type='error' />
          }
          <Title level={5}>{t('email')}</Title>
          <Input 
            size='large'
            prefix={<MailTwoTone twoToneColor={purple[2]} />}
            value={mail}
            status={isMailError ? 'error' : undefined}
            onChange={changeMail}
          />
        </Flex>
        <Flex
          vertical
          gap={5}
        >
          <Title level={5}>{t('password')}</Title>
          <Input.Password 
            size='large'
            prefix={<LockTwoTone twoToneColor={purple[2]} />}
            status={isPasswordError ? 'error' : undefined}
            value={password}
            onChange={changePassword}
          />
        </Flex>
        <Checkbox 
          style={styles.checkbox}
          onChange={changeRememberCheckbox}
        >
          {t('remember_me')}
        </Checkbox>
        <Flex
          vertical
          gap={5}
        >
          <Button 
            size='large'
            type='primary'
            htmlType='submit'
            loading={isLoading}
            disabled={isLoading}
          >
            {t('sing_in_btn')}
          </Button>
          <Button 
            size='large' 
            type='link'
            onClick={() => navigate('/reset-password')}
          >
            {t('forgot_pass')}
          </Button>
        </Flex>
      </Flex>
    </form>
    <Outlet />
    </>
  )
}

export default Login