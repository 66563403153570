import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enTranslation from './langs/en/translation.json'
import enTerms from './langs/en/termsAndConditions.json'
import enPrivacy from './langs/en/privacy.json'
import ruTranslation from './langs/ru/translation.json'
import deTranslation from './langs/de/translation.json'
import esTranslation from './langs/es/translation.json'

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: localStorage.getItem('lang') || 'en',

    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: enTranslation,
        terms: enTerms,
        privacy: enPrivacy
      },
      ru: {
        translation: ruTranslation
      },
      de: {
        translation: deTranslation
      },
      es: {
        translation: esTranslation
      },
    }
  })

  export default i18n