import React, { FC, useEffect, useState } from 'react'
import mainImg from 'images/tokens/dashboard.jpg'
import popup from 'images/tokens/popup.png'
import alqo from 'images/tokens/alqo.svg'
import binance from 'images/tokens/binance.svg'
import bitcoin from 'images/tokens/bitcoin.svg'
import blockfi from 'images/tokens/blockfi.svg'
import ens from 'images/tokens/ens.svg'
import tether from 'images/tokens/tether.svg'
import './Tokens.scss'
import { Flex, Divider, Typography } from 'antd'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { useTranslation } from 'react-i18next'
const { Text, Title } = Typography

const Tokens: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'landing.tokens'})
  const displayWidth = useDisplayWidth()
  const [y, setY] = useState<number>(0)

  function parallax() {
    setY((window.innerHeight - window.scrollY) / 90)
  }

  useEffect(() => {
    document.addEventListener("scroll", parallax)
    return () => document.removeEventListener("scroll", parallax)
  }, [])
  return (
    <Flex
      gap={20}
      align='center'
      justify='center'
      style={{ padding: displayWidth > 800 ? '0 50px 50px' : '0 10px 10px', ...(displayWidth < 800 && { flexDirection: 'column-reverse' }) }}
    >
      <div className="parallax-wrap-t">
        <img src={mainImg} alt="" className='dimg' style={{ zIndex: 10 }} />
        <img src={popup} alt="" className='pimg' style={{ transform: `translateY(${y * 5}px)`, zIndex: 20 }} />
      </div>
      <Flex
        vertical
        gap={displayWidth > 800 ? 30 : 10}
        align='flex-start'
        style={displayWidth > 800 ? { width: '50%', padding: 50, maxWidth: 700 } : { width: '100%', padding: 10 }}
      >
        <Title level={2} style={{ lineHeight: 1.3, fontWeight: 800, ...(displayWidth < 800 && { textAlign: 'center' }) }}>
          {t('title', {app_name: window.env.APP_TITLE})}
        </Title>
        <Text type='secondary'>
          {t('description', {app_name: window.env.APP_TITLE})}
        </Text>
        <Divider />
        <Flex
          justify='space-between'
          style={{ width: '100%' }}
        >
          <img src={alqo} alt="" className='icon' />
          <img src={binance} alt="" className='icon' />
          <img src={bitcoin} alt="" className='icon' />
          <img src={blockfi} alt="" className='icon' />
          <img src={ens} alt="" className='icon' />
          <img src={tether} alt="" className='icon' />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Tokens