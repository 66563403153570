import { Flex } from 'antd'
import React, { FC } from 'react'
import './Traiding.scss'
import Title from 'antd/es/typography/Title'
import { CheckOutlined } from '@ant-design/icons'
import { styles } from './TraidingStyles'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { Trans, useTranslation } from 'react-i18next'

const Traiding: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'landing.trading'})
  const displayWidth = useDisplayWidth()
  return (
    <Flex
      vertical
      gap={50}
      align='center'
      style={{ padding: displayWidth > 1200 ? 50 : '50px 10px', maxWidth: 1400, margin: '0 auto' }}
    >
      <Title level={2} style={{ ...styles.title, textAlign: 'center' }}>
        {t('title')} {window.env.APP_TITLE}
      </Title>
      <Flex
        wrap='wrap'
        gap={20}
        style={styles.captionBox}
        align='center'
        justify='center'
      >
        <Title level={4} style={{ ...styles.caption.text, textAlign: 'center' }}>
          <Trans i18nKey='landing.trading.adv_1'>
            <CheckOutlined style={styles.caption.icon} /> Earn with the Crypto and get up to <strong style={styles.caption.stong}>12% back</strong>
          </Trans>
        </Title>
        <Title level={4} style={{ ...styles.caption.text, textAlign: 'center' }}>
          <Trans i18nKey='landing.trading.adv_2'>
            <CheckOutlined style={styles.caption.icon} /> Invite friends and get  <strong style={styles.caption.stong}>new opportunities</strong>
          </Trans>
        </Title>
        <Title level={4} style={{ ...styles.caption.text, textAlign: 'center' }}>
          <Trans i18nKey='landing.trading.adv_3'>
            <CheckOutlined style={styles.caption.icon} /> Trade and earn <strong style={styles.caption.stong}>200+ cryptocurrencies</strong>  and get a permanent <strong style={styles.caption.stong}>5% discount </strong>
          </Trans>
        </Title>
      </Flex>
    </Flex>
  )
}

export default Traiding