import { Alert, Table, message } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { FC, useEffect, useState } from 'react'
import { adminAssetsI } from 'types/adminTypes'
import { api } from 'utilits/api'
import AdminWrapper from '../AdminWrapper/AdminWrapper'
import { getAssetsColumns } from './AssetsServe'

const Assets: FC = () => {
  const [assets, setAssets] = useState<adminAssetsI[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  const [errorApi, errorContext] = message.useMessage()
  const onError = (msg: string) => {
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }

  const switchAsset = (id: number, state: boolean) => {
    setIsLoading(true)
    api.switchAsset(id, !state)
      .then(getAssets)
      .catch(err => {
        onError(err.message)
        setIsLoading(false)
      })
  }
  const getAssets = () => {
    setIsLoading(true)
    api.getAdminAssets()
      .then((res: {assets: adminAssetsI[]}) => {
        setAssets(res.assets)
      })
      .catch(err => {
        onError(err.message)
        setAssets([])
      })
      .finally(() => setIsLoading(false))
  }

  const assetsColumns = getAssetsColumns(switchAsset)

  useEffect(() => {
    getAssets()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <AdminWrapper>
      {errorContext}
      <Title level={3}>Assets</Title>
      <Table
        size='small'
        loading={isLoading}
        columns={assetsColumns}
        pagination={false}
        dataSource={assets}
        scroll={{x: 800}}
        rowKey={r => r.id}
      />
    </AdminWrapper>
  )
}

export default Assets