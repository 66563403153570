import { Button, ConfigProvider, Flex, Spin, Tag, Typography } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useDisplayWidth } from 'utilits/useDisplayWidth.tsx'
import { purple } from '@ant-design/colors'
import { useUserContext } from 'Context/UserContext.ts'
import { CheckOutlined, CopyOutlined, EyeInvisibleOutlined, EyeOutlined, VerticalAlignBottomOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { getKYCColor, getLevelColor } from 'utilits/tagsColorFunctions.tsx'
import { useTranslation } from 'react-i18next'
import { displayUSDPrice } from 'utilits/displayUSDPrice'
const { Text, Title } = Typography

const Balance: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'balance'})
  const displayWidth = useDisplayWidth()
  const navigate = useNavigate()
  const { user } = useUserContext()

  const [isBalanceShown, setIsBalanceShown] = useState<boolean>(
    (localStorage.getItem('isBalanceShown')) ?
    (localStorage.getItem('isBalanceShown') === 'true') :
    true
  )

  useEffect(() => {
    isBalanceShown ?
      localStorage.setItem('isBalanceShown', 'true') :
      localStorage.setItem('isBalanceShown', 'false')
  }, [isBalanceShown])

  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            colorText: '#fff',
            colorTextDescription: '#b9bac0',
            fontSize: displayWidth > 750 ? 18 : 16,
            colorTextHeading: '#fff'
          },
          Button: {
            colorBgContainer: 'transparent',
            colorText: '#ffffffd9',
            defaultHoverBg: '#ffffffd9',
            defaultHoverColor: '#000',
            defaultHoverBorderColor: 'transparent',
            colorBorder: '#ffffff80'
          },
        }
      }}
    >
      <Flex
        gap={50}
        style={{width: '100%', background: `linear-gradient(315deg, ${purple[8]} 0%, #0c0f19 20%)`}}
      >
        <Flex 
          justify='space-between' 
          wrap='wrap' 
          gap={30} 
          style={{width: '100%', maxWidth: 1500, margin: '0 auto', padding: displayWidth > 800 ? 50 : '30px 20px'}}
        >
          <Flex
            vertical 
            gap={20} 
            style={{width: '100%', maxWidth: 800}}
          >
            <Flex 
              wrap='wrap' 
              gap={20} 
              justify='space-between' 
              style={{maxWidth: 700, width: '100%'}}
            >
              <Flex vertical align='start'>
                <Text type='secondary' style={{minWidth: 150}}>{t('email')}</Text>
                <Text>{user ? user.email : <Spin />}</Text>
              </Flex>
              <Flex vertical align='start'>
                <Text type='secondary' style={{minWidth: 150}}>{t('userID')}</Text>
                {
                  user ? 
                    <Text
                      copyable={{
                        icon: [<CopyOutlined style={{color: '#6663f1'}} />, <CheckOutlined style={{color: '#6663f1'}} />],
                        tooltips: false,
                      }}
                    >
                      {user.id}
                    </Text> :
                    <Spin />
                }
              </Flex>
              <Flex vertical align='start'>
                <Text type='secondary' style={{minWidth: 150}}>{t('account_level')}</Text>
                {
                  user ? 
                    <Tag
                      color={getLevelColor(user.account_level)}
                      style={user.account_level === 1 ? {color: '#565973'} : {}}
                    >
                      {t('level')} {user.account_level}
                    </Tag> : 
                    <Spin />
                }
              </Flex>
            </Flex>
            <Flex
              vertical
              align='start'
            >
              <Text type='secondary' style={{minWidth: 150}}>{t('KYC')}</Text>
              {
                user ? 
                  <Button
                    type='text'
                    style={{padding: 0}}
                    onClick={() => navigate('/account')}
                  >
                    <Tag
                      color={getKYCColor(user.kyc_state)}
                      style={user.kyc_state === 'Unverified' ? {color: '#565973'} : {}}
                    >
                      {user.kyc_state}
                    </Tag>
                  </Button> : 
                  <Spin />
              }
            </Flex>
          </Flex>
          <Flex 
            vertical 
            gap={50}
            align={displayWidth < 600 ? 'center' : undefined}
            style={{maxWidth: displayWidth > 600 ? 400 : 1000, width: '100%', margin: displayWidth < 600 ? '0 auto' : 0}}
          >
            <Flex vertical>
              {
                isBalanceShown ? 
                  <Title>{user ? `${user.balance_btc.toFixed(7)} BTC` : <Spin />}</Title> :
                  <Title>&#x2022;&#x2022;&#x2022;&#x2022;  &#x2022;&#x2022;&#x2022;</Title>
              }
              <Flex gap={20}>
                {
                  isBalanceShown ?
                    <Title level={3} type='secondary'>{user ? `≈ $${user.balance_usdt.toFixed(2)}` : <Spin />}</Title> :
                    <Title level={3} type='secondary'>&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;</Title>
                }
                <Button 
                  type='link'
                  onClick={() => setIsBalanceShown(s => !s)}
                  style={{ padding: 0}}
                >
                  <Text type='secondary' style={{fontSize: 18}}>
                    {
                      isBalanceShown ?
                        <EyeInvisibleOutlined />:
                        <EyeOutlined />
                    }
                  </Text>
                </Button>
              </Flex>
              <Flex gap={5}>
                <Text type='secondary'>{t('pnl')}:</Text>
                <Flex gap={2}>
                  <Text style={{color: (user?.pnl_usdt || 0) >= 0 ? 'green' : 'red'}}>
                    {(user?.pnl_usdt || 0) > 0 && '+'}{(displayUSDPrice((user?.pnl_usdt || 0)))}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex gap={20} style={{width: '100%'}}>
              <Button 
                type='primary'
                size={displayWidth > 600 ? 'large' : 'middle'}
                style={{width: '100%', height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClick={() => navigate('/deposit')}
              >
                <VerticalAlignBottomOutlined />{t('deposit')}
              </Button>
              <Button
                size={displayWidth > 600 ? 'large' : 'middle'}
                style={{width: '100%', height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClick={() => navigate('/withdraw')}
              >
                <VerticalAlignTopOutlined /> {t('withdraw')}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ConfigProvider>
  )
}

export default Balance