import React, { FC } from 'react'
import Products from './Products/Products'
import Footer from 'components/Reusable/Footer/Footer'
import { Outlet } from 'react-router-dom'
import Balance from 'components/Reusable/Balance/Balance'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const Earn: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'earn'})
  return (
    <>
      <Helmet>
        <title>{t('tab_title')}</title>
      </Helmet>
      <Balance />
      <Products />
      <Footer />
      <Outlet />
    </>
  )
}

export default Earn