import { Flex, Spin } from 'antd'
import React, { FC } from 'react'

const LoadingPage: FC = () => {
  return (
    <Flex
      style={{width: '100%', height: '100vh'}}
      align='center'
      justify='center'
    >
      <Spin />
    </Flex>
  )
}

export default LoadingPage