import React, { FC, useRef, useState } from 'react'
import { GlobalOutlined } from '@ant-design/icons'
import { useOutsideClick } from '@chakra-ui/react'
import { Popover, Flex, Button } from 'antd'
import i18n from 'translations/i18n'
import getCountryFlag from 'country-flag-icons/unicode'

const LangsSelect: FC<{mobile?: boolean}> = ({mobile}) => {
  const langs = window.env.APP_LANGS.split(',')
  const langRef = useRef<any>()
  const [isLangsOpen, setIsLangsOpen] = useState<boolean>(false)
  const changeLanguage = (lang: string) => {
    setIsLangsOpen(false)
    localStorage.setItem('lang', lang)
    i18n.language.toLowerCase() !== lang.toLowerCase() && i18n.changeLanguage(lang)
  }
  useOutsideClick({
    ref: langRef,
    handler: () => setIsLangsOpen(false)
  })
  return (
    <Popover
      open={isLangsOpen}
      placement={mobile ? 'topLeft' : 'bottom'}
      arrow={false}
      content={
        <Flex
          ref={langRef}
          vertical
          align='start'
        >
          {langs.map((l: string) => 
            <Button
              key={l}
              size='large'
              type='link'
              style={{
                ...(i18n.language.toLowerCase() === l.toLowerCase() && { color: '#6663f1' })
              }}
              onClick={() => changeLanguage(l)}
            >
              {getCountryFlag(l.toLowerCase() === 'en' ? 'GB' : l)} {l.toUpperCase()}
            </Button>
          )}
        </Flex>
      }
    >
      <Button 
        size='large'
        type='link'
        style={{
          padding: 0, textAlign: 'start', 
          ...(mobile && {marginTop: 'auto'}),
          ...(isLangsOpen && { color: '#6663f1' })
        }}
        onClick={() => setIsLangsOpen(s => !s)}
      >
        <GlobalOutlined />
      </Button>
    </Popover>
  )
}

export default LangsSelect