import React, { FC, useEffect, useRef, useState } from 'react'
import { DownOutlined, SearchOutlined, SwapOutlined } from '@ant-design/icons'
import { useOutsideClick } from '@chakra-ui/react'
import './CurrencyConventor.scss'
import { Alert, Button, Flex, Input, Select, Typography, message } from 'antd'
import Title from 'antd/es/typography/Title'
import styles from './CurrencyConventorJSStyles'
import { getPriceFunc } from './CurrencyConventorServe'
import { Outlet } from 'react-router-dom'
import { cryptoCurrencies } from 'consts/consts'
import Footer from 'components/Reusable/Footer/Footer'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
const { Text } = Typography

const CurrencyConventor: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: "conventor"})
  // Sell states
  const selectSellRef = useRef<any>()
  const [sellValue, setSellValue] = useState<string>('')
  const [sellCurrency, setSellCurrency] = useState<string>('ETH')
  const [isSellSelectOpen, setIsSellSelectOpen] = useState<boolean>(false)
  const [sellUsdtPrice, setSellUsdtPrice] = useState<number>(1)
  // Get states 
  const selectGetRef = useRef<any>()
  const [getValue, setGetValue] = useState<string>('')
  const [getCurrency, setGetCurrency] = useState<string>('BTC')
  const [isGetSelectOpen, setIsGetSelectOpen] = useState<boolean>(false)
  const [getUsdtPrice, setGetUsdtPrice] = useState<number>(1)
  // Other states
  const [currencyFilter, setCurrencyFilter] = useState<string>('')
  const [currencyOptions, setCurrencyOptions] = useState<{value: string, label: JSX.Element}[]>(cryptoCurrencies)
  const [exchangeRate, setExchangeRate] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [errorApi, errorContext] = message.useMessage()

  const onError = (msg: string) => {
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }

  const getPrice = (currency: string) => getPriceFunc(currency, setIsLoading).catch((err) => onError(err.message))

  const handleSellValueChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value
    if(!Number.isNaN(Number(value)) || target.value === ',') {
      setSellValue(value)
      setGetValue((Number(value) * exchangeRate).toFixed(7))
    }
  }
  const handleGetValueChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value
    if(!Number.isNaN(Number(value)) || target.value === ',') {
      setGetValue(value)
      setSellValue((Number(value) / exchangeRate).toFixed(7))
    }
  }
  const handleSellCurrencyChange = (value: string) => {
    setSellCurrency(value)
    getPrice(value)
      .then(setSellUsdtPrice)
  }
  const handleGetCurrencyChange = (value: string) => {
    setGetCurrency(value)
    getPrice(value)
      .then(setGetUsdtPrice)
  }
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrencyFilter(e.target.value)
  }

  const swapInputs = () => {
    setCurrencyOptions(cryptoCurrencies)
    setSellCurrency(getCurrency)
    setSellValue(getValue)
    setSellUsdtPrice(getUsdtPrice)
    setGetCurrency(sellCurrency)
    setGetValue(sellValue)
    setGetUsdtPrice(sellUsdtPrice)
  }

  useOutsideClick({
    ref: selectSellRef,
    handler: () => setIsSellSelectOpen(false)
  })
  useOutsideClick({
    ref: selectGetRef,
    handler: () => setIsGetSelectOpen(false)
  })

  useEffect(() => {
    if(currencyFilter.trim() === '') {
      setCurrencyOptions(
        cryptoCurrencies
          .filter(o => o.value !== sellCurrency && o.value !== getCurrency)
      )
    } else {
      setCurrencyOptions(
        cryptoCurrencies
          .filter(o => o.value !== sellCurrency && o.value !== getCurrency)
          .filter(o => o.value.toLowerCase().includes(currencyFilter.toLowerCase()))
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyFilter])

  useEffect(() => {
    setCurrencyFilter('')
  }, [isGetSelectOpen, isSellSelectOpen])

  useEffect(() => {
    setCurrencyOptions(cryptoCurrencies.filter(o => o.value !== sellCurrency && o.value !== getCurrency))
  }, [sellCurrency, getCurrency])

  useEffect(() => {
    setExchangeRate(sellUsdtPrice / getUsdtPrice)
  }, [sellUsdtPrice, getUsdtPrice])

  useEffect(() => {
    setGetValue((Number(sellValue) * exchangeRate).toFixed(7))
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeRate])

  useEffect(() => {
    getPrice(sellCurrency)
      .then(setSellUsdtPrice)
    getPrice(getCurrency)
      .then(setGetUsdtPrice)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <>
    {errorContext}
    <Helmet>
      <title>{t('tab_title')}</title>
    </Helmet>
    <Flex
      vertical
      style={styles.converterBox}
      align='center'
      gap={50}
    >
      <Title style={{textAlign: 'center'}} level={1}>{t('title')}</Title>
      <Flex
        vertical
        gap={20}
        align='center'
        style={styles.fullWidth}
      >
        <Flex 
          style={styles.inputContainer}
          align='center'
        >
          <Flex 
            vertical 
            gap={5}
            style={styles.fullWidth}
          >
            <Text style={styles.caption}>{t('sell_input')}</Text>
            <Input
              style={styles.input}
              variant='borderless'
              value={sellValue}
              onChange={handleSellValueChange}
            />
          </Flex>
          <Select
            size='large'
            variant='borderless' 
            value={sellCurrency}
            onChange={handleSellCurrencyChange}
            options={currencyOptions}
            onClick={() => setIsSellSelectOpen(state => !state)}
            open={isSellSelectOpen}
            suffixIcon={
              <DownOutlined className={`select-icon ${isSellSelectOpen && 'select-icon_turned'}`}/>
            }
            dropdownRender={menu => (
              <Flex
                ref={selectSellRef}
                vertical
                gap={5}
              >
                <Input 
                  onClick={e => e.stopPropagation()}
                  value={currencyFilter}
                  onChange={handleFilterChange}
                  variant='borderless'
                  suffix={<SearchOutlined style={{color: '#00000040'}} />}
                  style={{backgroundColor: '#0000000A'}}
                />
                {menu}
              </Flex>
            )}
          />
        </Flex>
        <Button 
          icon={<SwapOutlined rotate={90}/>} 
          loading={isLoading}
          type='text' 
          style={{padding: 5, borderRadius: '50%'}}
          onClick={swapInputs}
        />
        <Flex
          vertical
          style={styles.fullWidth}
          gap={5}
        >
          <Flex 
            style={styles.inputContainer}
            align='center'
          >
            <Flex 
              vertical 
              gap={5}
              style={styles.fullWidth}
            >
              <Text style={styles.caption}>{t('get_input')}</Text>
              <Input
                style={styles.input}
                variant='borderless'
                value={getValue}
                onChange={handleGetValueChange}
                step='0,00000001'
              />
            </Flex>
            <Select 
              size='large'
              variant='borderless' 
              value={getCurrency}
              onChange={handleGetCurrencyChange}
              options={currencyOptions} 
              onClick={() => setIsGetSelectOpen(state => !state)}
              open={isGetSelectOpen}
              suffixIcon={<DownOutlined className={`select-icon ${isGetSelectOpen && 'select-icon_turned'}`}/>}
              dropdownRender={menu => (
                <Flex
                  ref={selectGetRef}
                  vertical
                  gap={5}
                >
                  <Input 
                    onClick={e => e.stopPropagation()}
                    value={currencyFilter}
                    onChange={handleFilterChange}
                    variant='borderless'
                    suffix={<SearchOutlined style={{color: '#00000040'}} />}
                    style={{backgroundColor: '#0000000A'}}
                  />
                  {menu}
                </Flex>
              )}
            />
          </Flex>
          <Text style={{fontSize: 12}} type='secondary'>1 {sellCurrency} = {exchangeRate.toFixed(7)} {getCurrency}</Text>
        </Flex>
        <Button 
          type='primary' 
          size='large'
          style={styles.fullWidth}
          disabled
        >
          {t('button')}
        </Button>
      </Flex>
    </Flex>
    <Footer />
    <Outlet/>
    </>
  )
}

export default CurrencyConventor