import { Flex, Input, Button, Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { FC, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import pimg from 'images/ForgotPassword.svg'
import { styles } from './ResetAccountStyles'
import { mailRegex } from 'consts/consts'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
const { Text } = Typography

const ResetAccount: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'reset_account'})
  const navigate = useNavigate()
  const displayWidth = useDisplayWidth()

  const [mail, setMail] = useState<string>('')
  const [mailError, setMailError] = useState<boolean>(false)

  const changeMail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value)
    setMailError(false)
  }

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(mailRegex.test(mail.trim())) {
      navigate('/success-reset')
    } else {
      setMailError(true)
    }
  }
  return (
    <Flex style={styles.fullWidth} justify='center' align='center'>
      <form 
        style={styles.form}
        onSubmit={handleReset}
      >
        <Helmet>
          <title>{t('tab_title')}</title>
        </Helmet>
        <Flex
          vertical
          gap={20}
          style={styles.formLayout}
          justify='flex-start'
        >
          <Flex
            vertical
            gap={5}
          >
            <Title level={2}>{t('title')}</Title>
            <Text type='secondary'>{t('instruction')}</Text>
          </Flex>
          <Flex
            vertical
            gap={5}
          >
            <Title level={5}>{t('email')}</Title>
            <Input 
              size='large'
              value={mail}
              onChange={changeMail}
              status={mailError ? 'error' : undefined}
            />
          </Flex>
          <Flex
            vertical
            gap={5}
          >
            <Button
              type='primary'
              htmlType='submit'
            >
              {t("button")}
            </Button>
          </Flex>
        </Flex>
      </form>
      {
        displayWidth > 1100 && <img src={pimg} alt="" style={styles.image} />
      }
      <Outlet/>
    </Flex>
  )
}

export default ResetAccount