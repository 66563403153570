import { Navigate } from "react-router-dom"

const ProtectedRouteElement: React.FC<{element: any, forLoged?: boolean}> = ({element, forLoged}) => {
  if(forLoged) {
    return localStorage.getItem("token") ? <Navigate to="/assets" replace /> : element
  } else {
    return localStorage.getItem("token") ? element : <Navigate to="/sign-in" replace />
  }
}

export default ProtectedRouteElement;
