import { Alert, Button, DatePicker, Flex, Input, Popover, Select, Upload, UploadProps, message } from 'antd'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import Title from 'antd/es/typography/Title'
import { countryOptions, documentsOptions } from 'consts/consts'
import { useOutsideClick } from '@chakra-ui/react'
import { DownOutlined, UploadOutlined } from '@ant-design/icons'
import { resizeFile } from './KYCServe'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import countryApi from 'country-list-js'
import { api } from 'utilits/api'
import { useUserContext } from 'Context/UserContext'
import { useTranslation } from 'react-i18next'

const KYC: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'account.KYC_form'})
  const displayWidth = useDisplayWidth()
  const {user, changeUser} = useUserContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  const [name, setName] = useState<string>('')
  const [isNameError, setIsNameError] = useState<boolean>(false)

  const [secondName, setSecondName] = useState<string>('')
  const [isSecondNameError, setIsSecondNameError] = useState<boolean>(false)

  const [document, setDocument] = useState<string>('')
  const [isDocumentError, setIsDocumentError] = useState<boolean>(false)

  const [docNumber, setDocNumber] = useState<string>('')
  const [isDocumentNumberError, setIsDocumentNumberError] = useState<boolean>(false)

  const [birthDate, setBirthDate] = useState<any>('')
  const [isBirthDateError, setIsBirthDateError] = useState<boolean>(false)

  const [country, setCountry] = useState<string>('')
  const [isCountryError, setIsCountryError] = useState<boolean>(false)

  const [selfie, setSelfie] = useState<File|null>(null)
  const [isSelfieError, setIsSelfieError] = useState<boolean>(false)

  const [docPhoto, setDocPhoto] = useState<File|null>(null)
  const [isDocPhotoError, setIsDocPhotoError] = useState<boolean>(false)

  const documenRef = useRef<any>()
  const [isDocumnentSelectOpen, setIsDocumnentSelectOpen] = useState<boolean>(false)

  const [errorApi, errorContext] = message.useMessage()
  const onError = (msg: string) => {
    setIsLoading(false)
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }
  
  useOutsideClick({
    ref: documenRef,
    handler: () => setIsDocumnentSelectOpen(false)
  })

  const props: UploadProps = {
    beforeUpload: () => {return false},
    maxCount: 1,
    accept: 'image/*',
    action: '',
  }

  const uploadSelfie = async (info: UploadChangeParam<UploadFile<any>>) => {
    setIsLoading(true)
    const stockFile = info.fileList[0] && info.fileList[0].originFileObj
      stockFile !== undefined && await resizeFile(stockFile)
        .then((res: any) => {
          const file = new File([res], stockFile.name)
          return file
        })
        .then(setSelfie)
        .finally(() => setIsLoading(false))
  }
  const uploadDocs = async (info: UploadChangeParam<UploadFile<any>>) => {
    setIsLoading(true)
    const stockFile = info.fileList[0] && info.fileList[0].originFileObj
      stockFile !== undefined && await resizeFile(stockFile)
        .then((res: any) => {
          const file = new File([res], stockFile.name)
          return file
        })
        .then(setDocPhoto)
        .finally(() => setIsLoading(false))
  }

  const refreshUser = () => {
    setIsLoading(true)
    api.getUserInfo()
      .then(userInfo => {
        user && changeUser && changeUser({
          ...user,
          ...userInfo
        })
        setIsLoading(false)
      })
      .catch(err => onError(err.message))
  }

  const sendKYC = () => {
    if(name.length < 3) {
      setIsNameError(true)
      setTimeout(() => setIsNameError(false), 3000)
    } else if(secondName.length < 3) {
      setIsSecondNameError(true)
      setTimeout(() => setIsSecondNameError(false), 3000)
    } else if(birthDate === '') {
      setIsBirthDateError(true)
      setTimeout(() => setIsBirthDateError(false), 3000)
    } else if(country === '') {
      setIsCountryError(true)
      setTimeout(() => setIsCountryError(false), 3000)
    } else if(document === '') {
      setIsDocumentError(true)
      setTimeout(() => setIsDocumentError(false), 3000)
    } else if(docNumber.length < 6) {
      setIsDocumentNumberError(true)
      setTimeout(() => setIsDocumentNumberError(false), 3000)
    } else if(selfie === null) {
      setIsSelfieError(true)
      setTimeout(() => setIsSelfieError(false), 3000)
    } else if(docPhoto === null) {
      setIsDocPhotoError(true)
      setTimeout(() => setIsDocPhotoError(false), 3000)
    } 
    else {
      const data = new FormData()
      data.append('firstname', name)
      data.append('lastname', secondName)
      data.append('dob', birthDate)
      data.append('country', countryApi.findByName(country).code.iso2)
      data.append('document_type', document)
      data.append('document_id', docNumber)
      data.append('image_selfie', selfie)
      data.append('image_document', docPhoto)

      setIsLoading(true)
      api.sendKYC(data)
        .then(() => {
          setName('')
          setSecondName('')
          setBirthDate('')
          setCountry('')
          setDocument('')
          setDocNumber('')
          setSelfie(null)
          setDocPhoto(null)
        })
        .then(refreshUser)
        .catch(err => onError(err.message))
    }
  }

  useEffect(() => setIsNameError(false), [name])
  useEffect(() => setIsSecondNameError(false), [secondName])
  useEffect(() => setIsDocumentError(false), [document])
  useEffect(() => setIsDocumentNumberError(false), [docNumber])
  useEffect(() => setIsBirthDateError(false), [birthDate])
  useEffect(() => setIsCountryError(false), [country])
  useEffect(() => setIsSelfieError(false), [selfie])
  useEffect(() => setIsDocPhotoError(false), [docPhoto])

  return (
    <Flex vertical gap={30} style={{width: '100%', maxWidth: 1500, padding: displayWidth  > 800 ? '30px 50px' : '20px 20px'}}>
      {errorContext}
      <Title level={2}>{t('title')}</Title>
      <Flex wrap='wrap' gap={30}>
        <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
          <Title level={5}>{t('firstname.title')}</Title>
          <Popover
            open={isNameError}
            content={t('firstname.error')}
          >
            <Input 
              size='large' 
              placeholder={t('firstname.placeholder')}
              value={name}
              onChange={({target}) => setName(target.value)}
              status={isNameError ? 'error' : ''}
            />
          </Popover>
        </Flex>
        <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
          <Title level={5}>{t('lastname.title')}</Title>
          <Popover
            open={isSecondNameError}
            content={t('lastname.error')}
          >
            <Input
              size='large'
              placeholder={t('lastname.placeholder')}
              value={secondName}
              onChange={({target}) => setSecondName(target.value)}
              status={isSecondNameError ? 'error' : ''}
            />
          </Popover>
        </Flex>
      </Flex>
      <Flex wrap='wrap' gap={30}>
        <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
          <Title level={5}>{t('birth_date.title')}</Title>
          <Popover
            open={isBirthDateError}
            content={t('birth_date.error')}
          >
            <DatePicker 
              size='large' 
              placeholder={t('birth_date.placeholder')}
              onChange={(_, dateStr) => setBirthDate(dateStr)}
              status={isBirthDateError ? 'error' : ''}
            />
          </Popover>
        </Flex>
        <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
          <Title level={5}>{t('country.title')}</Title>
          <Popover
            open={isCountryError}
            content={t('country.error')}
          >
            <Select
              size='large' 
              placeholder={t('country.placeholder')}
              showSearch
              onChange={setCountry}
              options={countryOptions}
              status={isCountryError ? 'error' : ''}
            />
          </Popover>
        </Flex>
      </Flex>
      <Flex wrap='wrap' gap={30}>
        <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
          <Title level={5}>{t('document.title')}</Title>
          <Popover
            open={isDocumentError}
            content={t('document.error')}
          >
            <Select 
              size='large' 
              placeholder={t('document.placeholder')}
              options={documentsOptions}
              onClick={() => setIsDocumnentSelectOpen(state => !state)}
              onChange={setDocument}
              open={isDocumnentSelectOpen}
              status={isDocumentError ? 'error' : ''}
              suffixIcon={
                <DownOutlined className={`select-icon ${isDocumnentSelectOpen && 'select-icon_turned'}`}/>
              }
              dropdownRender={menu => (
                <Flex
                  ref={documenRef}
                  vertical
                  gap={5}
                >
                  {menu}
                </Flex>
              )}
            />
          </Popover>
        </Flex>
        <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
          <Title level={5}>{t('id.title')}</Title>
          <Popover
            open={isDocumentNumberError}
            content={t('id.error')}
          >
            <Input
              size='large'
              placeholder={t('id.placeholder')}
              value={docNumber}
              onChange={({target}) => !Number.isNaN(Number(target.value)) && setDocNumber(target.value)}
              status={isDocumentNumberError ? 'error' : ''}
            />
          </Popover>
        </Flex>
      </Flex>
      <Flex wrap='wrap' gap={30}>
        <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
          <Title level={5}>{t('selfie_title')}</Title>
          <Upload
            {... props}
            onChange={uploadSelfie}
            onRemove={() => setSelfie(null)}
          >
            <Popover
              open={isSelfieError}
              content={t('photo_error')}
            >
              <Button danger={isSelfieError}><UploadOutlined /> {t('photo_btn')}</Button>
            </Popover>
          </Upload>
        </Flex>
        <Flex vertical gap={5} style={{width: '100%', maxWidth: 400}}>
          <Title level={5}>{t('document_photo_title')}</Title>
          <Upload
            {... props}
            onChange={uploadDocs}
            onRemove={() => setDocPhoto(null)}
          >
            <Popover
              open={isDocPhotoError}
              content={t('photo_error')}
            >
              <Button danger={isDocPhotoError}><UploadOutlined /> {t('photo_btn')}</Button>
            </Popover>
          </Upload>
        </Flex>
      </Flex>
      <Flex wrap='wrap' gap={30}>
        <Button
          type='primary'
          size='large'
          onClick={sendKYC}
          disabled={isLoading}
          loading={isLoading}
        >
          {t('sumbit_btn')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default KYC