import { Alert, Button, Checkbox, Flex, Input, Typography, message } from 'antd'
import { CheckboxProps } from 'antd/es/checkbox'
import Title from 'antd/es/typography/Title'
import React, { FC, useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { getStyles } from './RegisterStyles'
import { LockTwoTone, MailTwoTone, WalletTwoTone } from '@ant-design/icons'
import { purple } from '@ant-design/colors'
import { mailRegex } from 'consts/consts'
import { api } from 'utilits/api'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
const { Text } = Typography

const Register: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'sign_up'})
  const navigate = useNavigate()
  const displayWidth = useDisplayWidth()
  const styles = getStyles(displayWidth)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [mail, setMail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [invCode, setInvCode] = useState<string>('')
  const [termsCheckbox, setTermsCheckbox] = useState<boolean>(false)

  const [isMailError, setIsMailError] = useState<boolean>(false)
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false)
  const [isPasswordConfirmError, setIsPasswordConfirmError] = useState<boolean>(false)
  const [isApiError, setIsApiError] = useState<boolean>(false)
  
  const [errorApi, errorContext] = message.useMessage()

  const onError = (msg: string) => {
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }

  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    setIsPasswordError(false)
    setIsPasswordConfirmError(false)
  }
  const changePasswordConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(e.target.value)
    setIsPasswordConfirmError(false)
    password.trim() !== '' && setIsPasswordError(false)
  }
  const changeMail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value)
    setIsMailError(false)
  }
  const changeInvCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvCode(e.target.value)
  }
  const changeTermsCheckbox: CheckboxProps['onChange'] = (e) => {
    setTermsCheckbox(e.target.checked)
  }

  const handleReg = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!mailRegex.test(mail.trim())) {
      setIsMailError(true)
    } else if(password.trim() === '') {
      setIsPasswordError(true)
    } else if(passwordConfirm.trim() !== password.trim()) {
      setIsPasswordError(true)
      setIsPasswordConfirmError(true)
    } else{
      setIsLoading(true)
      api.signUp(mail, password, passwordConfirm, invCode)
        .then(() => navigate('/sign-in'))
        .catch((err: any) => {
          setIsApiError(true)
          onError(err.message)
        })
        .finally(() => setIsLoading(false))
    }
  }
  
  const handleError = () => {
    if (isMailError) {
      return t('mail_error')
    } else if (isPasswordConfirmError) {
      return t('password_confirm_error')
    } else if (isPasswordError) {
      return t('password_error')
    }else if (isApiError) {
      return t('api_error')
    }
  }

  return (
    <Flex style={styles.fullWidth}>
    {errorContext}
    <Helmet>
      <title>{t('tab_title')}</title>
    </Helmet>
    <form 
      style={styles.form}
      onSubmit={handleReg}
    >
      <Flex
        vertical
        gap={30}
        justify='center'
        style={styles.formLayout}
      >
        <Flex
          vertical
          gap={10}
          style={styles.fullWidth}
          align='flex-start'
        >
          <Title level={1}>{t('title')}</Title>
          <Flex 
            gap={5} 
            align='center'
          >
            <Trans i18nKey='sign_up.sign_in_btn'>
              <Text type='secondary'>Already have an account?</Text>
              <Button 
                type='link'
                style={{padding: 0}}
                onClick={() => navigate('/sign-in')}
              >
                Sign in here.
              </Button>
            </Trans>
          </Flex>
        </Flex>
        <Flex
          vertical
          gap={10}
          style={styles.fullWidth}
          align='flex-start'
        >
          {
            (isPasswordError || isMailError || isApiError) && <Alert style={{width: '100%'}} message={handleError()} type='error' />
          }
          <Flex
            vertical
            gap={5}
            style={styles.fullWidth}
          >
            <Title level={5}>{t('title')}</Title>
            <Input 
              size='large'
              prefix={<MailTwoTone twoToneColor={purple[2]} />}
              status={isMailError ? 'error' : undefined}
              value={mail}
              onChange={changeMail}
            />
          </Flex>
          <Flex
            vertical
            gap={5}
            style={styles.fullWidth}
          >
            <Title level={5}>{t('password')}</Title>
            <Input.Password 
              size='large'
              prefix={<LockTwoTone twoToneColor={purple[2]} />}
              status={isPasswordError ? 'error' : undefined}
              value={password}
              onChange={changePassword}
            />
          </Flex>
          <Flex
            vertical
            gap={5}
            style={styles.fullWidth}
          >
            <Title level={5}>{t('password_confirm')}</Title>
            <Input.Password 
              size='large'
              prefix={<LockTwoTone twoToneColor={purple[2]} />}
              status={isPasswordConfirmError ? 'error' : undefined}
              value={passwordConfirm}
              onChange={changePasswordConfirm}
            />
          </Flex>
          <Flex
            vertical
            gap={5}
            style={styles.fullWidth}
          >
            <Title level={5}>{t('inv_code')}</Title>
            <Input
              size='large'
              prefix={<WalletTwoTone twoToneColor={purple[2]} />}
              value={invCode}
              onChange={changeInvCode}
            />
          </Flex>
        </Flex>
        <Flex
            align='center'
          >
            <Trans i18nKey='sign_up.terms_and_conditions'>
              <Checkbox 
                style={styles.checkbox}
                onChange={changeTermsCheckbox}
              >
                <Text type='secondary'>I agree to </Text>
              </Checkbox>
              <Link to='/terms_and_conditions' target='_blanc'>
                <Button 
                  type='link'
                  style={styles.checkboxButton}
                >
                  Terms & Conditions
                </Button>
              </Link>
            </Trans>
          </Flex>
        <Flex
          vertical
          gap={5}
        >
          <Button 
            size='large'
            type='primary'
            htmlType='submit'
            disabled={!termsCheckbox || isLoading}
            loading={isLoading}
          >
            {t('button')}
          </Button>
        </Flex>
      </Flex>
    </form>
    <div style={styles.image}/>
    <Outlet />
    </Flex>
  )
}

export default Register