import { Alert, Table, message } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { FC, useEffect, useState } from 'react'
import { userForAdminI } from 'types/adminTypes'
import AdminWrapper from '../AdminWrapper/AdminWrapper'
import { api } from 'utilits/api'
import AddBalancePopup from './AddBalancePopup/AddBalancePopup'
import { getUsersColumns } from './UsersServe'
import LevelPopup from './LevelPopup/LevelPopup'
import { useUserContext } from 'Context/UserContext'
import KYCPopup from './KYCPopup/KYCPopup'
import TransactionsPopup from './TransactionsPopup/TransactionsPopup'

const Users: FC = () => {
  const { changeUser, user } = useUserContext()
  const [users, setUsers] = useState<userForAdminI[]>([])

  const [selectedUserId, setSelectedUserId] = useState<number>(0)
  const [isAddBalancePopupOpen, setIsAddBalancePopupOpen] = useState<boolean>(false)
  const [selectedUserLevel, setSelectedUserLevel] = useState<number>(1)
  const [isLevelPopupOpen, setIsLevelPopupOpen] = useState<boolean>(false)
  const [selecredUserKYC, setSelecredUserKYC] = useState<string>('')
  const [isKYCPopupOpen, setIsKYCPopupOpen] = useState<boolean>(false)
  const [isTransactionsPopupOpen, setIsTransactionsPopupOpen] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  const [errorApi, errorContext] = message.useMessage()
  const onError = (msg: string) => {
    setIsLoading(false)
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }

  const switchUser = (id: number, state: boolean) => {
    setIsLoading(true)
    api.switchUser(id, !state)
      .then(getUsers)
      .catch(err => onError(err.message))
  }
  const switchAdmin = (id: number, state: boolean) => {
    setIsLoading(true)
    api.switchAdmin(id, !state)
      .then(getUsers)
      .catch(err => {
        onError(err.message)
        setIsLoading(false)
      })
  }
  const switchPremium = (id: number, state: boolean) => {
    setIsLoading(true)
    api.switchPremium(id, !state)
      .then(() => {
        getUsers()
        id === user?.id && refreshUser()
      })
      .catch(err => onError(err.message))
  }
  
  const getUsers = () => {
    setIsLoading(true)
    api.getUsers()
      .then(setUsers)
      .then(() => setIsLoading(false))
      .catch(err => onError(err.message))
  }

  const changeBalance = (assetId: number, state: string, type: string, amount: number, userId: number, comment?: string) => {
    setIsLoading(true)
    api.createTransaction(assetId, state, type, amount, userId, comment)
      .then(() => {
        getUsers()
        setIsAddBalancePopupOpen(false)
      })
      .catch(err => onError(err.message))
  }

  const openAddBalancePopup = (id: number) => {
    setSelectedUserId(id)
    setIsAddBalancePopupOpen(true)
  }
  const openLevelPopup = (userId: number, level: number) => {
    setSelectedUserId(userId)
    setSelectedUserLevel(level)
    setIsLevelPopupOpen(true)
  }
  const openKYCPopup = (userId: number, KYC: string) => {
    setSelectedUserId(userId)
    setSelecredUserKYC(KYC)
    setIsKYCPopupOpen(true)
  }
  const openTransactionsPopup = (userId: number) => {
    setSelectedUserId(userId)
    setIsTransactionsPopupOpen(true)
  }

  const changeLevel = (userId: number, level: number) => {
    setIsLoading(true)
    api.changeUserLevel(userId, level)
      .then(() => {
        getUsers()
        setIsLevelPopupOpen(false)
        userId === user?.id && refreshUser()
      })
      .catch(err => onError(err.message))
  }
  const changeKYC = (userId: number, KYC: string, comment: string) => {
    setIsLoading(true)
    api.changeUserKYC(userId, KYC, comment)
      .then(() => {
        getUsers()
        setIsKYCPopupOpen(false)
        userId === user?.id && refreshUser()
      })
      .catch(err => onError(err.message))
  }

  const refreshUser = () => {
    setIsLoading(true)
    api.getUserInfo()
      .then(userInfo => {
        user && changeUser && changeUser({
          ...user,
          ...userInfo
        })
        setIsLoading(false)
      })
      .catch(err => onError(err.message))
  }

  const usersColumns = getUsersColumns(switchUser, switchAdmin, openAddBalancePopup, openLevelPopup, openKYCPopup, openTransactionsPopup, switchPremium)

  useEffect(() => {
    getUsers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <AdminWrapper>
      {errorContext}
      <Title level={3}>Users</Title>
      <Table
        size='small'
        loading={isLoading}
        columns={usersColumns}
        pagination={false}
        dataSource={users}
        scroll={{x: 1400}}
        rowKey={r => r.id}
      />
      <AddBalancePopup
        isOpen={isAddBalancePopupOpen}
        userId={selectedUserId}
        onClose={() => setIsAddBalancePopupOpen(false)}
        onAccrue={changeBalance}
        loading={isLoading}
      />
      <LevelPopup
        isOpen={isLevelPopupOpen}
        userId={selectedUserId}
        userLevel={selectedUserLevel}
        onClose={() => setIsLevelPopupOpen(false)}
        onChangeLevel={changeLevel}
        loading={isLoading}
      />
      <KYCPopup
        isOpen={isKYCPopupOpen}
        userId={selectedUserId}
        userKYCStatus={selecredUserKYC}
        onClose={() => setIsKYCPopupOpen(false)}
        onChangeKYCStatus={changeKYC}
        loading={isLoading}
      />
      <TransactionsPopup
        isOpen={isTransactionsPopupOpen}
        userId={selectedUserId}
        onClose={() => setIsTransactionsPopupOpen(false)}
      />
    </AdminWrapper>
  )
}

export default Users