import React, { FC } from 'react'
import './Step.scss'
import { blue } from '@ant-design/colors'
import { Typography, Flex } from 'antd'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { useTranslation } from 'react-i18next'
const { Text, Title } = Typography

const Step: FC<{step: number}> = ({step}) => {
  const {t} = useTranslation('translation', {keyPrefix: 'landing.tutorial.steps'})
  const displayWidth = useDisplayWidth()
  return (
    <Flex
      align='center'
      vertical={displayWidth > 800}
      gap={10}
      style={{width: '100%', overflow: 'hidden', padding: 20}}
    >
      <div className={`step ${step === 4 && 'step_last'} ${step === 1 && 'step_first'}`}>
        <div className="step-right-line"/>
        <div className="step-left-line"/>
        <div className="step-icon" style={{background: blue[1]}}/>
        <Title level={3} className='step-number'>{step}</Title>
      </div>
      <Flex
        align={displayWidth > 800 ? 'center' : 'flex-start'}
        vertical
        gap={10}
      >
        <Title level={3} style={displayWidth > 800 ? {textAlign: 'center'} : undefined}>{t(`${step}.title`)}</Title>
        <Text type='secondary' style={displayWidth > 800 ? {textAlign: 'center', maxWidth: 300} : undefined}>{t(`${step}.text`)}</Text>
      </Flex>
    </Flex>
  )
}

export default Step