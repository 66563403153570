import { Button, Flex, Typography } from 'antd'
import React, { FC } from 'react'
import bg from 'images/cta-bg.jpg'
import { CheckOutlined } from '@ant-design/icons'
import { purple } from '@ant-design/colors'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const { Title } = Typography

const CTA: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'landing.CTA'})
  const displayWidth = useDisplayWidth()
  const isLogged = Boolean(localStorage.getItem('token'))
  return (
    <Flex
      align='center'
      justify={displayWidth > 1200 ? 'space-evenly' : 'center'}
      vertical={displayWidth < 900}
      gap={displayWidth > 1200 ? 50 : 20}
      style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', width: '90%', height: 600, margin: '10px auto', padding: 20, borderRadius: 10, maxWidth: 1400 }}
    >
      <Flex
        vertical
        gap={displayWidth > 1200 ? 40 : 10}
        align={displayWidth > 900 ? 'flex-start' : 'center'}
      >
        <Title
          level={2}
          style={{ color: '#fff', width: displayWidth > 900 ? 450 : '100%', textAlign: displayWidth > 900 ? 'start' : 'center' }}
        >
          {t('title')}
        </Title>
        <Link to={isLogged ? '/earn' : '/sign-up'}>
          <Button
            size='large'
            type='primary'
            style={{ padding: '10px 35px', boxSizing: 'unset' }}
          >
            {t(isLogged ? 'earn_btn' : 'reg_btn')}
          </Button>
        </Link>
      </Flex>
      <Flex
        vertical
        gap={displayWidth > 1200 ? 50 : 20}
      >
        <Flex gap={10}>
          <CheckOutlined style={{ color: purple[4], fontWeight: 100 }} />
          <Title level={4} style={{ margin: 0, color: '#ffffffb3' }}>
            {t('adv_1')}
          </Title>
        </Flex>
        <Flex gap={10}>
          <CheckOutlined style={{ color: purple[4], fontWeight: 100 }} />
          <Title level={4} style={{ margin: 0, color: '#ffffffb3' }}>
            {t('adv_2')}
          </Title>
        </Flex>
        <Flex gap={10}>
          <CheckOutlined style={{ color: purple[4], fontWeight: 100 }} />
          <Title level={4} style={{ margin: 0, color: '#ffffffb3' }}>
            {t('adv_3')}
          </Title>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CTA