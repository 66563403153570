const defaultStyle = {
  transition: `300ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0, transform: 'translateY(300px)' },
  exited:  { opacity: 0, transform: 'translateY(300px)' },
}

export {
  defaultStyle,
  transitionStyles
}