import { DownOutlined, SwapOutlined } from '@ant-design/icons'
import { useOutsideClick } from '@chakra-ui/react'
import { Alert, Button, Col, Collapse, ConfigProvider, Flex, Input, Row, Select, Typography, message } from 'antd'
import React, { FC, useEffect, useRef, useState } from 'react'
import Footer from 'components/Reusable/Footer/Footer'
import ConfirmPopup from './ConfirmPopup/ConfirmPopup'
import { Outlet, useNavigate } from 'react-router-dom'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { useUserContext } from 'Context/UserContext'
import { transactionI } from 'types/types'
import { api } from 'utilits/api'
import { useDebouncedCallback } from 'use-debounce'
import { validate } from 'crypto-address-validator-ts'
import TransactionsTable from 'components/Reusable/TransactionsTable/TransactionsTable'
import { FAQText } from 'consts/consts'
import { proccessCurrencyOptions } from 'utilits/useCurrencyOptions'
import { Helmet } from 'react-helmet'
import PageHeading from 'components/Reusable/PageHeading/PageHeading'
import { useTranslation } from 'react-i18next'
const { Title, Text } = Typography

const Withdraw: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'withdraw'})
  const navigate = useNavigate()
  const displayWidth = useDisplayWidth()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { user } = useUserContext()
  
  const [address, setAddress] = useState<string>('')
  const [amount, setAmount] = useState<string>('')
  const [receiceAmount, setReceiceAmount] = useState<string>('')

  const [isAddressError, setIsAddressError] = useState<boolean>(true)

  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false)
  
  const [transactions, setTransactions] = useState<transactionI[]>([])

  // currency states
  const currencyRef = useRef<any>()
  const [currency, setCurrency] = useState<number>(0)
  const [ticker, setTicker] = useState<string>('')
  const [isCurrencySelectOpen, setIsCurrencySelectOpen] = useState<boolean>(false)
  const [currencyOption, setCurrencyOptions] = useState<{value: number, ticker: string, label: JSX.Element}[]>([])

  const [withdrawInfo, setWithdrawInfo] = useState<{balance: number, network_fee: number, withdrawal_min: number, remaining_limit: number}|null>(null)
  const availbleBalance = (user?.assets?.find(a => a.ticker === ticker)?.balance || 0) - (user?.assets?.find(a => a.ticker === ticker)?.balance_earn || 0)

  const [errorApi, errorContext] = message.useMessage()
  const onError = (msg: string) => {
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }

  //change handlers
  const handleCurrencyChange = (value: number, option: any) => {
    setCurrency(value)
    setTicker(option.ticker)
    address && checkWallet(address, option.ticker)
  }
  const handleAddressChange = ((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setAddress(value)
    ticker && checkWallet(value, ticker)
  })
  const handleAmountChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value
    const valueAsNumber = Number(value)
    if(
        (!Number.isNaN(valueAsNumber) || value === ',') &&
        valueAsNumber <= (withdrawInfo?.remaining_limit || 1) &&
        valueAsNumber >= 0 &&
        valueAsNumber < availbleBalance
      ) {
      setAmount(value)
      setReceiceAmount((valueAsNumber - (withdrawInfo?.network_fee || 0)).toFixed(7))
    }
  }
  const setMaxAmount = () => {
    if (availbleBalance > (withdrawInfo?.network_fee || 0)) {
      setAmount(availbleBalance > (withdrawInfo?.remaining_limit || 0) ? String(withdrawInfo?.remaining_limit || 0) : String(availbleBalance))
      setReceiceAmount(String((availbleBalance > (withdrawInfo?.remaining_limit || 0) ? (withdrawInfo?.remaining_limit || 0) : availbleBalance) - (withdrawInfo?.network_fee || 0)))
    }
  }
  const switchAmounts = () => {
    setAmount((Number(amount) + (withdrawInfo?.network_fee || 0)).toFixed(7))
    setReceiceAmount(amount)
  }

  const checkWallet = useDebouncedCallback((address, ticker) => {
    try {
      setIsAddressError(!validate(address, ticker, { networkType: '' , chainType: ''}))
    } catch {
      setIsAddressError(true)
    }
  }, 300)

  const getTransitions = () => {
    setIsLoading(true)
    api.getTransactionsHistory()
      .then(res => setTransactions(res.transactions))
      .catch(err => onError(err.message))
      .finally(() => setIsLoading(false))
  }

  useOutsideClick({
    ref: currencyRef,
    handler: () => setIsCurrencySelectOpen(false)
  })

  const getWithdrawInfo = () => {
    api.getWithdawInfo(currency)
      .then((res: any) => setWithdrawInfo(res))
      .catch(err => onError(err.message))
  }
  const createWithdraw = () => {
    if(user?.kyc_state === 'Verified') {
      const wihtdrawAmount = Number(amount)
      if(
        !isAddressError && 
        withdrawInfo && 
        currency !== 0 &&
        wihtdrawAmount > 0 && 
        wihtdrawAmount + withdrawInfo.network_fee <= withdrawInfo.remaining_limit
        ) {
        setIsConfirmOpen(false)
        setIsLoading(true)
        api.createWithdraw(currency, wihtdrawAmount, address)
          .then(() => navigate('/assets'))
          .catch(err => onError(err.message))
          .finally(() => setIsLoading(false))
      }
    } else {
      navigate('/account')
    }
  }

  useEffect(() => {
    setIsCurrencySelectOpen(false)
  }, [currency])

  useEffect(() => {
    user?.assets && setCurrencyOptions(proccessCurrencyOptions(user.assets))
  }, [user])

  useEffect(() => {
    getWithdrawInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency])

  useEffect(() => {
    getTransitions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ConfigProvider
        theme={{
          components: {
            Button: {
              primaryShadow: 'none'
            },
          }
        }}
      >
      {errorContext}
      <Helmet>
        <title>{t('tab_title')}</title>
      </Helmet>
      <PageHeading title={t('title')} />
      <Flex 
        vertical
        style={{padding: displayWidth > 800 ? 50 : '30px 10px', maxWidth: 1500, width: '100%'}}
      >
        <Flex wrap={displayWidth > 1000 ? 'nowrap' : 'wrap'} gap={30}>
          <Flex 
            vertical 
              gap={displayWidth > 600 ? 30 : 20} 
              style={{maxWidth: 900, width: '100%'}}
            >
            <Flex vertical={displayWidth < 600}>
              <Title level={5} style={{minWidth: 150}}>{t('select_coin')}</Title>
                <Flex vertical style={{width: '100%'}}>
                  <Text type='secondary'>{t('coin')}</Text>
                  <Select
                    size='large'
                    style={{width: '100%', maxWidth: 500}}
                    placeholder={t('select_placeholder')}
                    value={currency === 0 ? undefined : currency}
                    onChange={handleCurrencyChange}
                    options={currencyOption}
                    onClick={() => setIsCurrencySelectOpen(state => !state)}
                    open={isCurrencySelectOpen}
                    suffixIcon={
                      <DownOutlined className={`select-icon ${isCurrencySelectOpen && 'select-icon_turned'}`}/>
                    }
                    dropdownRender={menu => (
                      <Flex
                        ref={currencyRef}
                        vertical
                        gap={5}
                      >
                        {menu}
                      </Flex>
                    )}
                  />
                </Flex>
            </Flex>
            <Flex vertical={displayWidth < 600}>
              <Title level={5} style={{minWidth: 150}}>{t('sent_to')}</Title>
              <Flex 
                vertical
                style={{width: '100%'}}
                gap={10}
              >
                <Flex
                  vertical
                  style={{width: '100%', maxWidth: 500}}
                >
                  <Text type='secondary'>{t('address')}</Text>
                  <Input
                    status={address && isAddressError ? 'error' : undefined}
                    size='large'
                    placeholder={t('address_placeholder')}
                    value={address}
                    onChange={handleAddressChange}
                  />
                </Flex>
                {
                  currency !== 0 && withdrawInfo &&
                  <>
                  <Row>
                  <Col span={12}>
                    <Flex 
                      vertical 
                      style={{width: '100%'}}
                    >
                      <Text type='secondary' style={{fontSize: 12}}>
                        {ticker} {t('balance')}
                      </Text>
                      <Text strong>
                        {availbleBalance} {ticker}
                      </Text>
                    </Flex>
                  </Col>
                  <Col span={12}>
                    <Flex 
                      vertical 
                      style={{width: '100%'}}
                    >
                      <Text type='secondary' style={{fontSize: 12}}>{t('minimum_withdraw')}</Text>
                      <Text strong>
                        {withdrawInfo.withdrawal_min} {ticker}
                      </Text>
                    </Flex>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Flex 
                      vertical 
                      style={{width: '100%'}}
                    >
                      <Text type='secondary' style={{fontSize: 12}}>{t('network_fee')}</Text>
                      <Text strong>
                        {withdrawInfo.network_fee} {ticker}
                      </Text>
                    </Flex>
                  </Col>
                  <Col span={12}>
                    <Flex 
                      vertical 
                      style={{width: '100%'}}
                    >
                      <Text type='secondary' style={{fontSize: 12}}>{t('limit')}</Text>
                      <Text strong>
                        {withdrawInfo.remaining_limit} {ticker}
                      </Text>
                    </Flex>
                  </Col>
                </Row>
                  </>
                }
                
              </Flex>
            </Flex>
            {
              !isAddressError &&
              <>
              <Flex vertical={displayWidth < 600}>
                <Title level={5} style={{minWidth: 150, maxWidth: 150}}>{t('withdraw_amount')}</Title>
                  <Flex 
                    vertical 
                    style={{width: '100%', maxWidth: 500}}
                  >
                    <Flex gap={5}>
                    <Text type='secondary'>{t('amount')}</Text>
                    <Text style={{marginLeft: 'auto'}}>
                      {withdrawInfo?.remaining_limit} {ticker}
                    </Text>
                    <Text type='secondary'>{t('limit')}</Text>
                    </Flex>
                    <Input 
                      style={{width: '100%'}} size='large'
                      value={amount}
                      onChange={handleAmountChange}suffix={
                        <Button 
                          type='link' 
                          style={{color: '#6663f1'}}
                          onClick={setMaxAmount}
                        >
                          {t('max')}
                        </Button>
                      }
                    />
                  </Flex>
              </Flex>
              <Flex vertical={displayWidth < 600}>
                <Title level={5} style={{minWidth: 150}}>{t('network_fee')}</Title>
                  <Text strong>
                    {withdrawInfo?.network_fee} {ticker}
                  </Text>
              </Flex>
              <Flex vertical={displayWidth < 600}>
                <Title level={5} style={{minWidth: 150}}>{t('receive_amount')}</Title>
                  <Flex
                    justify='space-between'
                    style={{width: '100%'}}
                  >
                    <Flex vertical>
                      <Title level={3}>{receiceAmount} {ticker}</Title>
                      <Button
                        style={{padding: 0}} 
                        type='link'
                        onClick={switchAmounts}
                      >
                        <Flex align='center' gap={10}>
                        <SwapOutlined />
                        <Text type='secondary' strong style={{fontSize: 10, textWrap: 'wrap', textAlign: 'left'}}>{t('switch_amount')}</Text>
                        </Flex>
                      </Button>
                    </Flex >
                    <Button 
                      type='primary'
                      size='large'
                      onClick={() => setIsConfirmOpen(true)}
                      disabled={amount === '' || isLoading}
                      loading={isLoading}
                    >
                      {t('withdraw_btn')}
                    </Button>
                  </Flex>
              </Flex>
              </>
            }
          </Flex>
          <Flex
            vertical
            gap={20}
            style={displayWidth > 1000 ? {maxWidth: 450, width: '50%'} : {width: '100%'}}
          >
            <Title level={3}>{t('FAQ')}</Title>
            <Collapse 
              items={FAQText}
              expandIconPosition='end'
              ghost
              expandIcon={(props) => 
                <div 
                  style={{
                    padding: 8, borderRadius: '50%',
                    background: props.isActive ? '#6663f1' : '#f2f0ff', 
                    transform: props.isActive ? 'rotate(180deg)' : undefined,
                    color: props.isActive ? '#f2f0ff' : '#6663f1',
                    boxShadow: props.isActive ? '0 .5rem 1.125rem -0.5rem rgba(99,102,241,.9)' : undefined,
                    margin: 'auto',
                  }}
                >
                  <DownOutlined />
                </div>
              }
            />
          </Flex>
        </Flex>
        <Title style={{marginTop: 50, marginBottom: 20}} level={3}>{t('recent_transactions')}</Title> 
        <TransactionsTable
          data={transactions}
          loading={isLoading}
        />
      </Flex>
      <Footer/>
      <ConfirmPopup
        amount={receiceAmount}
        fee={withdrawInfo?.network_fee || 0}
        address={address}
        ticker={ticker}
        isOpen={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        onOk={createWithdraw}
      />
      <Outlet />
    </ConfigProvider>
  )
}

export default Withdraw