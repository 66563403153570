import { Flex } from 'antd'
import React, { FC  } from 'react'
import Footer from 'components/Reusable/Footer/Footer'
import { Outlet } from 'react-router-dom'
import Equity from './Equity/Equity'
import Balance from 'components/Reusable/Balance/Balance'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const Assets: FC = () => {
  const {t} = useTranslation('translation', {keyPrefix: 'assets'})
  
  return (
    <Flex
      vertical
      style={{width: '100%'}}
    >
      <Helmet>
        <title>{t('tab_title')}</title>
      </Helmet>
      <Balance />
      <Equity />
      <Footer />
      <Outlet />
    </Flex>
  )
}

export default Assets