import { Alert, Button, Flex, Table, message } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { FC, useEffect, useState } from 'react'
import { invCodesI } from 'types/adminTypes'
import { api } from 'utilits/api'
import NewCodePopup from './NewCodePopup/NewCodePopup'
import { PlusOutlined } from '@ant-design/icons'
import AdminWrapper from '../AdminWrapper/AdminWrapper'
import { codesCoulmns } from './InvCodesServe'

const InvCodes: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [codes, setCodes] = useState<invCodesI[]>([])

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  
  const [errorApi, errorContext] = message.useMessage()
  const onError = (msg: string) => {
    errorApi.open({
      type: undefined,
      content: <Alert message={msg} type='error' />
    })
  }

  const createCode = (code?: string) => {
    setIsLoading(true)
    api.createInviteCode(code)
      .then(getCodes)
      .then(() => setIsPopupOpen(false))
      .catch(err => onError(err.message))
  }

  const getCodes = () => {
    setIsLoading(true)
    api.getInviteCodes()
      .then(res => setCodes(res.invites))
      .catch(err => onError(err.message))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getCodes()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <AdminWrapper>
      {errorContext}
      <Flex align='center' justify='space-between' style={{width: '100%'}}>
        <Title level={3}>Invitation Codes</Title>
        <Button 
          type='primary'
          onClick={() => setIsPopupOpen(true)}
        >
          <PlusOutlined /> Create code
        </Button>
      </Flex>
      <Table
        size='small'
        dataSource={codes}
        columns={codesCoulmns}
        loading={isLoading}
        pagination={false}
        scroll={{x: 800}}
        rowKey={r => r.id}
      />
      <NewCodePopup
        isOpen={isPopupOpen}
        loading={isLoading}
        onClose={() => setIsPopupOpen(false)}
        onCreate={createCode}
      />
    </AdminWrapper>
  )
}

export default InvCodes