import btcIcon from 'images/cryptoIcons/bitcoin.svg'
import ethIcon from 'images/cryptoIcons/ethereum.svg'
import bnbIcon from 'images/cryptoIcons/bnb.svg'
import dogeIcon from 'images/cryptoIcons/dogecoin.svg'
import adaIcon from 'images/cryptoIcons/ada.svg'
import avaxIcon from 'images/cryptoIcons/avax.svg'
import dotIcon from 'images/cryptoIcons/dot.svg'
import linkIcon from 'images/cryptoIcons/link.svg'
import shibIcon from 'images/cryptoIcons/shib.svg'
import solIcon from 'images/cryptoIcons/sol.svg'
import tonIcon from 'images/cryptoIcons/ton.svg'
import xrpIcon from 'images/cryptoIcons/xrp.svg'

const getCryptoIcon = (ticker: string) => {
  switch (ticker) {
    case 'BTC':
      return btcIcon
    case 'ETH': 
      return ethIcon
    case 'BNB':
      return bnbIcon
    case 'DOGE':
      return dogeIcon
    case 'ADA':
      return adaIcon
    case 'AVAX':
      return avaxIcon
    case 'DOT':
      return dotIcon
    case 'LINK':
      return linkIcon
    case 'SHIB':
      return shibIcon
    case 'SOL':
      return solIcon
    case 'TON':
      return tonIcon
    case 'XRP':
      return xrpIcon
  }
}

export  {getCryptoIcon }