import { Avatar, Button, ConfigProvider, Flex, Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { FC, useEffect, useState } from 'react'
import './Banner.scss'
import fUser from 'images/user1.jpeg'
import sUser from 'images/user2.jpeg'
import tUser from 'images/user3.jpeg'
import card from 'images/card.png'
import rate from 'images/rate.png'
import price from 'images/price.png'
import { getStyles } from './BannerStyles'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const { Text } = Typography

const Banner: FC = () => {
  const { t } = useTranslation('translation', {keyPrefix: 'landing.banner'})
  const displayWidth = useDisplayWidth()
  const isLogged = Boolean(localStorage.getItem('token'))
  

  const [x, setX] = useState<number>(0)
  const [y, setY] = useState<number>(0)

  const styles = getStyles(displayWidth)

  function parallax(event?: { pageX: number; pageY: number; }) {
    if (event) {
      setX((window.innerWidth - event.pageX) / 90)
      setY((window.innerHeight - event.pageY) / 90)
    } else {
      setY((window.innerHeight - window.scrollY) / 90)
    }
  }

  useEffect(() => {
    document.addEventListener("mousemove", parallax)
    document.addEventListener("scroll", () => parallax())
    return () => {
      document.removeEventListener("scroll", () => parallax())
      document.removeEventListener("mousemove", parallax)
    }
  }, [])
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorBgContainer: 'transparent',
            colorText: '#ffffffd9',
            defaultHoverBg: '#ffffffd9',
            defaultHoverColor: '#000',
            defaultHoverBorderColor: 'transparent',
            colorBorder: '#ffffff80',
            contentFontSize: 20,
            contentFontSizeLG: 24
          },
        }
      }}
    >
      <Flex style={styles.section} justify='center'>
        <Flex
          style={styles.content}
          align='center'
          justify='space-between'
          vertical={displayWidth < 900}
        >
          <Flex
            vertical
            gap={displayWidth > 1200 ? 40 : 20}
            style={styles.discribtion}
            align={displayWidth > 900 ? undefined : 'center'}
          >
            <Title level={displayWidth > 900 ? 1 : 2} style={{ ...styles.title, ...displayWidth < 900 && { textAlign: 'center' } }}>
              {t('hero')}
            </Title>
            {
              displayWidth > 1200 ?
                <Text style={styles.caption}>{t('hero')}</Text> :
                (
                  displayWidth < 900 &&
                  <Text style={{ ...styles.caption, ...displayWidth < 900 && { textAlign: 'center' } }}>{t('hero')}
                  </Text>
                )
            }
            <Flex gap={20} align='center'>
              <Link to={isLogged ? '/earn' : '/sign-up'}>
                <Button
                  size={displayWidth > 900 ? 'large' : 'middle'} type='primary'
                >
                  {t(isLogged ? 'earn_btn' : 'reg_btn')}
                </Button>
              </Link>
              {
                !isLogged && 
                  <Link to={window.env.APP_CONTACT_US_LINK} target='_blank'>
                    <Button size={displayWidth > 900 ? 'large' : 'middle'}>{t('contact_btn')}</Button>
                  </Link>
                
              }
            </Flex>
            <Flex
              align='center'
              gap={10}
            >
              <Avatar.Group size='large'>
                <Avatar src={fUser} />
                <Avatar src={sUser} />
                <Avatar src={tUser} />
              </Avatar.Group>
              <Text style={styles.users.text}><strong style={styles.users.strong}>400k+</strong> {t('users')}</Text>
            </Flex>
          </Flex>
          <div className="parallax-wrap">
            <img src={card} alt="" className='img' style={{ transform: `translateX(${x}px) translateY(${y}px)`, zIndex: 20 }} />
            <img src={price} alt="" className='img' style={{ transform: ` translateY(${y * -0.5}px)`, zIndex: 10 }} />
            <img src={rate} alt="" className='img' style={{ transform: `translateX(${x * -0.5}px) translateY(${y * 0.5}px)`, zIndex: 30 }} />
          </div>
        </Flex>
      </Flex>
    </ConfigProvider>
  )
}

export default Banner