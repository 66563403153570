import React, { FC, ReactNode } from 'react'
import Footer from 'components/Reusable/Footer/Footer'
import { Button, ConfigProvider, Flex } from 'antd'
import Title from 'antd/es/typography/Title'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { useDisplayWidth } from 'utilits/useDisplayWidth'
import { Helmet } from 'react-helmet'

const AdminWrapper: FC<{children: ReactNode}> = ({children}) => {
  const displayWidth = useDisplayWidth()
  const location = useLocation()
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            rowExpandedBg: 'transparent',
            colorBgContainer: 'transparent',
            headerBg: 'transparent',
            borderColor: 'transparent',
            headerSplitColor: 'transparent',
            bodySortBg: 'transparent',
            headerSortActiveBg: 'trasparent',
            headerSortHoverBg: 'transparent'
          },
          Button: {
            primaryShadow: 'none'
          }
        }
      }}
    >
    <Helmet>
      <title>Admin Panel</title>
    </Helmet>
    <Flex
      vertical
      style={{width: '100%', maxWidth: 1500, padding: displayWidth > 800 ? 50 : '30px 20px'}}
      gap={30}
    >
      <Flex
        align='baseline' 
        justify='space-between'
        vertical={displayWidth < 600}
      >
        <Title level={1}>Admin Panel</Title>
        <Flex gap={10} wrap='wrap'>
          <Link to='/admin/assets'>
            <Button 
              size='large'
              type='link'
              style={{
                ...(location.pathname === '/admin/assets' && { color: '#6663f1' })
              }}
            >
              Assets
            </Button>
          </Link>
          <Link to='/admin/users'>
            <Button 
              size='large'
              type='link'
              style={{
                ...(location.pathname === '/admin/users' && { color: '#6663f1' })
              }}
            >
              Users
            </Button>
          </Link>
          <Link to='/admin/invitation-codes'>
            <Button 
              size='large'
              type='link'
              style={{
                ...(location.pathname === '/admin/invitation-codes' && { color: '#6663f1' })
              }}
            >
              Codes
            </Button>
          </Link>
          <Link to='/admin/settings'>
            <Button 
              size='large'
              type='link'
              style={{
                ...(location.pathname === '/admin/settings' && { color: '#6663f1' })
              }}
            >
              Settings
            </Button>
          </Link>
          <Link to='/admin/Earnings'>
            <Button 
              size='large'
              type='link'
              style={{
                ...(location.pathname === '/admin/Earnings' && { color: '#6663f1' })
              }}
            >
              Earnings
            </Button>
          </Link>
          <Link to='/admin/withdrawals'>
            <Button 
              size='large'
              type='link'
              style={{
                ...(location.pathname === '/admin/withdrawals' && { color: '#6663f1' })
              }}
            >
              Withdrawals
            </Button> 
          </Link>
        </Flex>
      </Flex>
      {children}
    </Flex>
    <Footer/>
    <Outlet />
    </ConfigProvider>
  )
}

export default AdminWrapper