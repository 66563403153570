import React, { FC, useEffect, useState } from 'react'
import StatusPopupProps from './StatusPopupProps'
import { Flex, Input, Modal, Radio } from 'antd'
import Title from 'antd/es/typography/Title'
import { useDisplayWidth } from 'utilits/useDisplayWidth'

const StatusPopup: FC<StatusPopupProps> = ({isOpen, transactionId, transactionStatus, loading, onClose, onChangeStatus}) => {
  const displayWidth = useDisplayWidth()
  const [status, setStatus] = useState<string>('')
  const [comment, setComment] = useState<string>('')
  const buttonStyles = {width: displayWidth < 550 ? '100%' : '20%', fontSize: 14}

  useEffect(() => {
    setStatus(transactionStatus)
  }, [transactionStatus, transactionId, isOpen])
  return (
    <Modal
      open={isOpen}
      title={
        <Flex gap={10} align='baseline'>
          <Title style={{margin: 0}} level={3}>Change Transaction Status</Title>
          <Title style={{margin: 0}} level={5} type='secondary'>{transactionId}</Title>
        </Flex>
      }
      onCancel={onClose}
      okButtonProps={{
        disabled: status === transactionStatus || loading,
        loading: loading
      }}
      onOk={() => onChangeStatus(transactionId, status, comment)}
    >
      <Radio.Group size='small' style={{width: '100%'}} value={status} onChange={({target}) => setStatus(target.value)}>
        <Flex vertical={displayWidth < 550}>
          <Radio.Button style={{...buttonStyles, textAlign: 'center'}} value={'Pending'}>Pending</Radio.Button>
          <Radio.Button style={{...buttonStyles, textAlign: 'center'}} value={'Success'}>Success</Radio.Button>
          <Radio.Button style={{...buttonStyles, textAlign: 'center'}} value={'Cancelled'}>Cancelled</Radio.Button>
          <Radio.Button style={{...buttonStyles, textAlign: 'center'}} value={'Frozen'}>Frozen</Radio.Button>
          <Radio.Button style={{...buttonStyles, textAlign: 'center'}} value={'Failed'}>Failed</Radio.Button>
        </Flex>
      </Radio.Group>
      <Input
        size={displayWidth < 450 ? 'small' : 'middle'}
        style={{marginTop: 10}}
        placeholder='Comment'
        value={comment}
        onChange={({target}) => setComment(target.value)}
      />
    </Modal>
  )
}

export default StatusPopup