import { CloseOutlined } from '@ant-design/icons'
import { Card, ConfigProvider, Flex, Modal, Typography } from 'antd'
import React, { FC } from 'react'
import ConfirmPopupProps from './ConfirmPopupProps'
import { getCryptoIcon } from 'utilits/getCryptoIcon'
import { useTranslation } from 'react-i18next'
const { Text } = Typography

const ConfirmPopup: FC<ConfirmPopupProps> = ({isOpen, address, fee, amount, ticker, onClose, onOk}) => {
  const {t} = useTranslation('translation', {keyPrefix: 'withdraw.confirm_popup'})
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: '#001529',
            headerBg: '#001529',
            titleColor: '#fff',
          },
          Card: {
            colorBgContainer: '#ffffff1c',
            colorBorderSecondary: 'transparent',
            paddingLG: 16
          },
          Typography: {
            colorText: '#fff',
            colorTextSecondary: '#ffffffbf',
          }
        }
      }}
    >
      <Modal
        centered
        open={isOpen}
        title={t('title')}
        cancelButtonProps={{ghost: true}}
        closeIcon={<CloseOutlined style={{color: '#fff'}} />}
        onOk={onOk}
        onCancel={onClose}
        okText={t('confirm')}
        cancelText={t('cancel')}
        width={600}
      >
        <Flex vertical gap={8}>
          <Card>
            <Flex vertical gap={8}>
              <Flex>
                <Text style={{opacity: .5, minWidth: 80}}>{t('amount')}:</Text>
                <Flex align='center' gap={5}>
                  <img src={getCryptoIcon(ticker)} alt={ticker} width={20}/>
                  <Text>{t('receive')} {amount} {ticker}</Text>
                  <Text style={{opacity: .8}}>({t('network_fee')} {fee} {ticker})</Text>
                </Flex>
              </Flex>
              <Flex>
                <Text style={{opacity: .5, minWidth: 80}}>{t('address')}:</Text><Text>{address}</Text>
              </Flex>
            </Flex>
          </Card>
          <Text>&#8226; {t('note_1')}</Text>
          <Text>&#8226; {t('note_2')}</Text>
        </Flex>
      </Modal>
    </ConfigProvider>
  )
}

export default ConfirmPopup