const convertSToTime = (s: number) => {
  let seconds = Math.floor(s % 60)
  let minutes = Math.floor((s / 60) % 60)
  let hours = Math.floor((s / (60 * 60)) % 24)
  let days = Math.floor((s / (60 * 60)) / 24)

  hours = Number(hours < 10 ? "0" + hours : hours);
  minutes = Number((minutes < 10) ? "0" + minutes : minutes);

  return `${days > 0 ? days + 'd' : ''} ${hours > 0 ? hours + 'h' : ''} ${minutes > 0 ? minutes + 'min' : ''} ${seconds > 0 ? seconds + 's' : ''}`
}

export { convertSToTime }