import { TableColumnsType, Tag, Typography } from "antd"
import { invCodesI } from "types/adminTypes"
const { Text } = Typography

const codesCoulmns: TableColumnsType<invCodesI> = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
    width: 50,
  },
  {
    title: 'Admin ID',
    key: 'admin_id',
    dataIndex: 'admin_id',
  },
  {
    title: 'Code',
    key: 'code',
    render: (_, {code}) => 
      <Text copyable>{code}</Text>
  },
  {
    title: 'User ID',
    key: 'user_id',
    dataIndex: 'user_id',
  },
  {
    title: 'Active',
    key: 'active',
    render: (_, {active}) => 
      <Tag color={active ? '#21c55d' : '#ef4444'}>{active ? 'Active' : 'Disabled'}</Tag>
  },
  {
    title: 'Created At',
    key: 'created_at',
    render: (_, {created_at}) => 
      <Text>
        {new Date(created_at).toLocaleString(undefined, {timeZone: 'UTC'})}
      </Text>
  },
  {
    title: 'Activated At',
    key: 'activated_at',
    render: (_, {activated_at}) => 
      <Text>
        {new Date(activated_at).toLocaleString(undefined, {timeZone: 'UTC'})}
      </Text>
  },
]

export {
  codesCoulmns
}