import { earingI, earnCoinsI, userAssetI } from "types/types"

const proccessProducts = (products: earingI[], assets: userAssetI[]) => {
  const coins: earnCoinsI[] = products.map((earning, i, arr) => {
    return {
      ticker: earning.asset_ticker,
      name: earning.asset_name,
      APR: '',
      duration: 'Fixed',
      variants: arr.filter(e => e.asset_id === earning.asset_id)
    }
  })

  const availableAssets = assets.map(a => a.ticker)
  const availableCoins = coins.filter(c => availableAssets.includes(c.ticker))
  const filteredCoins = availableCoins.filter((coin, i, arr) => i === arr.findIndex((t) => t.ticker === coin.ticker))

  const proccessedCoins = filteredCoins.map(p => {
    let smallestApr = 1000
    let biggestApr = 0
    let duration: 'Flexible/Fixed'|'Flexible'|'Fixed' = p.variants[0].duration_type
    p.variants.forEach((e) => { 
      if(e.apr_min < smallestApr) {
        smallestApr = e.apr_min
      }
      if(e.apr_max > biggestApr) {
        biggestApr = e.apr_max
      }
      if(e.duration_type !== duration) {
        duration = 'Flexible/Fixed'
      }
    })
    return {
      ...p,
      APR: `${smallestApr}-${biggestApr}%`,
      duration: duration
    }
  })

  return proccessedCoins
}

const findAsset = (ticker: string, assets?: userAssetI[]) => {
  const asset = assets?.find(a => a.ticker === ticker)
  return {
    ...asset,
    available_balance: (asset?.balance || 0) - (asset?.balance_earn || 0),
    available_balance_usdt: ((asset?.balance || 0) - (asset?.balance_earn || 0)) * (asset?.price_usdt || 0)
  }
}

export { proccessProducts, findAsset }